import React from "react";
import { Skeleton } from "@mui/material";

const InstallerPortfolioSkeleton = () => {
  return (
    <div className="p-6">
      <div className="flex flex-col gap-y-4">
        {/* Profile Header Section */}
        <div className="px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <div className="flex items-center gap-x-4">
            <Skeleton variant="circular" width={64} height={64} />
            <Skeleton variant="text" width={300} height={40} />
          </div>

          <div className="flex flex-col gap-y-4 ml-20">
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width={100} height={40} />
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={80} height={30} />
            </div>
            <div className="flex gap-x-[10px]">
              <Skeleton variant="rounded" width={120} height={32} />
              <Skeleton variant="rounded" width={140} height={32} />
            </div>
          </div>
        </div>

        {/* Projects Section */}
        <div className="px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <Skeleton variant="text" width={120} height={40} className="mb-6" />

          <div className="grid grid-cols-4 grid-rows-3 gap-y-4 gap-x-5">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="group relative">
                <Skeleton
                  variant="rectangular"
                  className="h-64 w-full"
                  sx={{ borderRadius: "8px" }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Reviews Section */}
        <div className="px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <div className="flex gap-x-20">
            <div className="flex flex-col justify-evenly items-center gap-y-4">
              <Skeleton
                variant="text"
                width={120}
                height={40}
                className="self-start"
              />
              <div className="flex items-center gap-x-2">
                <Skeleton variant="text" width={60} height={50} />
                <Skeleton variant="circular" width={36} height={36} />
              </div>
              <Skeleton
                variant="text"
                width={100}
                height={30}
                className="self-start"
              />
            </div>

            <div className="flex flex-col gap-y-2">
              {[1, 2, 3, 4, 5].map((rating) => (
                <div key={rating} className="flex items-center">
                  <Skeleton variant="text" width={32} height={24} />
                  <Skeleton
                    variant="rectangular"
                    width={240}
                    height={4}
                    sx={{ mx: 2, borderRadius: "4px" }}
                  />
                  <Skeleton variant="text" width={40} height={24} />
                </div>
              ))}
            </div>
          </div>

          <Skeleton
            variant="rectangular"
            className="my-6"
            height={2}
            sx={{ bgcolor: "#ECECEE" }}
          />

          <div className="flex flex-col gap-y-4 w-1/2">
            <Skeleton variant="text" width={200} height={40} />
            {/* Sample review skeletons */}
            {[1, 2].map((review) => (
              <div key={review} className="flex flex-col gap-y-2 mt-8">
                <div className="flex items-center gap-x-3">
                  <Skeleton variant="circular" width={40} height={40} />
                  <div className="flex gap-x-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Skeleton
                        key={star}
                        variant="circular"
                        width={24}
                        height={24}
                      />
                    ))}
                  </div>
                </div>
                <div className="ml-14">
                  <Skeleton variant="text" width="90%" height={24} />
                  <Skeleton variant="text" width="70%" height={24} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerPortfolioSkeleton;
