export const handleKeyDown = (e) => {
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

  // Special handling for Ctrl+A
  if (e.ctrlKey && e.key.toLowerCase() === "a") {
    return; // Allow Ctrl+A for selection
  }

  const isNumberKey = /^\d$/.test(e.key);
  const selectedTextLength = e.target.selectionEnd - e.target.selectionStart;
  const currentLength = e.target.value.length;

  // Block non-numeric keys unless they are allowed control keys
  if (!isNumberKey && !allowedKeys.includes(e.key)) {
    e.preventDefault();
    return;
  }

  // Only prevent input if:
  // 1. It's a number key AND
  // 2. No text is selected AND
  // 3. Current length is already at max
  if (isNumberKey && selectedTextLength === 0 && currentLength >= 10) {
    e.preventDefault();
  }
};
