import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const isAuth = useAuth();

  return isAuth ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div className="flex items-end justify-end md:items-center flex-col md:flex-row bg-white h-screen">
      <div
        className="w-full h-full hidden md:flex"
        style={{
          backgroundImage: "url(/login.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        className="w-full h-full md:hidden"
        style={{
          backgroundImage: "url(/mobile-login.png)",
          backgroundSize: "contain",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <div className="w-full ">{children}</div>
    </div>
  );
};

export default AuthLayout;
