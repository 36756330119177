import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomizedTables from "components/installer/CustomizedTables";
import dayjs from "dayjs";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import {
  fetchAllDeals,
  fetchHomeownerDealSummary,
} from "services/installerServices";
import { dealStatus } from "utils/constant";
import { formatPrice } from "utils/formatNumber";

const rows = Array.from({ length: 41 }, (_, i) => ({
  id: i + 1,
  User: {
    first_name: `User`,
    last_name: `${i + 1}`,
    location: `City ${i + 1}`,
  },
  homeOwner_contract_signed: Math.random() > 0.5,
  status: Math.floor(Math.random() * 3),
  system_type: ["Premium", "Standard", "Basic"][Math.floor(Math.random() * 3)],
  createdAt: "2024-03-09T00:00:00.000Z",
  systemPrice: Math.floor(Math.random() * 50000 + 10000),
}));

const columns = [
  {
    field: "id",
    header: "ID",
    width: "100px",
    align: "left",
  },
  {
    field: "User.first_name",
    header: "Name",
    width: "150px",
    // Custom renderer to combine first and last name
    customRenderer: (row) => `${row.User.first_name} ${row.User.last_name}`,
  },
  {
    field: "homeOwner_contract_signed",
    header: "Deal Type",
    width: "150px",
    // Custom renderer to convert boolean to text
    customRenderer: (row) =>
      row.homeOwner_contract_signed ? "Contract" : "Lead",
  },
  {
    field: "status",
    header: "Deal Status",
    width: "150px",
    // Custom renderer to convert status number to text
    customRenderer: (row) => dealStatus(row.status),
  },
  {
    field: "system_type",
    header: "Tiers",
    width: "150px",
  },
  {
    field: "User.location",
    header: "Location",
    width: "200px",
    // Custom renderer to render nested values
    customRenderer: (row) => row.User.location,
  },
  {
    field: "createdAt",
    header: "Date",
    width: "150px",
    // Custom renderer to format date
    customRenderer: (row) => dayjs(row.createdAt).format("YYYY-MM-DD"),
  },
  {
    field: "systemPrice",
    header: "Price",
    width: "150px",
    align: "right",
    // Custom renderer to format price
    customRenderer: (row) => `$${formatPrice(row.systemPrice)}`,
  },
];

const filterLabels = {
  deal_type: {
    contract: "Contract",
    lead: "Lead",
  },
  status: {
    0: "Active",
    1: "Closed Won",
    2: "Closed Lost",
  },
  system_type: {
    Basic: "Basic",
    Standard: "Standard",
    Premium: "Premium",
  },
};

const AllHomeownersTable = () => {
  const [sortedRows, setSortedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({
    used: false,
    system_type: [],
    status: [],
    date: "",
    from_date: "",
    to_date: "",
    deal_type: [],
    search: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [dateRadio, setDateRadio] = useState();
  const [data, setdata] = useState();
  const [isLoading, setIsLoading] = useState();
  const [state, setState] = useState({
    right: false,
  });

  // Functions
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const searchDebounce = useDebounce(searchData, 1500);

  const updateFilters = (newFilterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilterValues,
      used: true,
    }));
  };

  const handleCheckboxChange = (e, filterKey, value) => {
    const { checked } = e.target;
    updateFilters({
      [filterKey]: checked
        ? [...filters[filterKey], value]
        : filters[filterKey].filter((item) => item !== value),
    });
  };

  const handleSearchChange = (e) => {
    searchDebounce(e);
  };

  function searchData(e) {
    sessionStorage.removeItem("myDealsPageNumber");
    updateFilters({
      search: e.target.value === "" ? undefined : e.target.value,
    });
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await fetchAllDeals(filters);
      const dealSummary = await fetchHomeownerDealSummary();
      setdata({ res, dealSummary });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("myDealsFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setFilters({ ...parsedFilters, search: "" });
      setAppliedFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("myDealsFilters");
    if (appliedFilters.used || filters.search || !savedFilters) {
      fetchData();
    }
  }, [appliedFilters, filters.search]);

  const getActiveFilters = () => {
    const activeFilters = [];

    if (appliedFilters.deal_type.length > 0) {
      appliedFilters.deal_type.forEach((type) => {
        activeFilters.push(filterLabels.deal_type[type] || type);
      });
    }

    if (appliedFilters.status.length > 0) {
      appliedFilters.status.forEach((status) => {
        activeFilters.push(filterLabels.status[status] || status);
      });
    }

    if (appliedFilters.system_type.length > 0) {
      appliedFilters.system_type.forEach((systemType) => {
        activeFilters.push(filterLabels.system_type[systemType] || systemType);
      });
    }

    if (
      appliedFilters.date === "custom" &&
      appliedFilters.from_date &&
      appliedFilters.to_date
    ) {
      activeFilters.push(
        `${appliedFilters.from_date} - ${appliedFilters.to_date}`
      );
    } else if (appliedFilters.date !== "custom" && appliedFilters.date) {
      activeFilters.push(appliedFilters.date.split("_").join(" "));
    }

    return activeFilters;
  };

  const removeFilter = (filterToRemove) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
      }
      if (updatedFilters.date.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem("myDealsFilters", JSON.stringify(updatedFilters));
      return updatedFilters;
    });

    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
      }
      if (updatedFilters.date.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem("myDealsFilters", JSON.stringify(updatedFilters));
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    const emptyFilters = {
      used: false,
      system_type: [],
      status: [],
      date: "",
      from_date: "",
      to_date: "",
      deal_type: [],
      search: "",
    };
    setFilters(emptyFilters);
    setAppliedFilters(emptyFilters);
    sessionStorage.removeItem("myDealsFilters");
    sessionStorage.removeItem("myDealsPageNumber");
  };

  const list = () => (
    <Box
      sx={{
        width: { xs: "100vw", sm: "400px", md: "480px" },
        // height: { xs: "80%", md: "100%" },
      }}
      role="presentation"
    >
      <div className="p-4 md:p-10 flex flex-col gap-y-5 pb-[120px] md:pb-10">
        <div className="sticky top-0 bg-white z-10 py-3 border-b border-[#C7CACE]">
          <div className="flex justify-between items-center ">
            <h1 className="text-[23px] text-[#112532] font-semibold py-2 md:py-4">
              Apply Filters
            </h1>
            <CloseIcon
              className="cursor-pointer md:hidden"
              onClick={toggleDrawer("right", false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Type</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("contract")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "deal_type", "contract")
                  }
                />
              }
              label="Contract"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("lead")}
                  onChange={(e) => handleCheckboxChange(e, "deal_type", "lead")}
                />
              }
              label="Lead"
            />
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Status</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("0")}
                  onChange={(e) => handleCheckboxChange(e, "status", "0")}
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("1")}
                  onChange={(e) => handleCheckboxChange(e, "status", "1")}
                />
              }
              label="Closed Won"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("2")}
                  onChange={(e) => handleCheckboxChange(e, "status", "2")}
                />
              }
              label="Closed Lost"
            />
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Tier</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Basic")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Basic")
                  }
                />
              }
              label="Basic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Standard")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Standard")
                  }
                />
              }
              label="Standard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Premium")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Premium")
                  }
                />
              }
              label="Premium"
            />
          </FormGroup>

          <FormControl>
            <h1 className="mb-1 text-[#112532] font-semibold">Date</h1>
            <RadioGroup
              onChange={(e) => {
                setDateRadio(e.target.value);
                updateFilters({
                  date: e.target.value,
                  from_date: "",
                  to_date: "",
                });
              }}
              value={filters.date || null}
            >
              <FormControlLabel
                value="last_7_days"
                control={<Radio />}
                label="Last 7 Days"
              />
              <FormControlLabel
                value="last_30_days"
                control={<Radio />}
                label="Last Month"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>

          <div
            className={`flex flex-col md:flex-row gap-3 ${dateRadio === "custom" ? "" : "opacity-50"}`}
          >
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">From</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.from_date ? dayjs(filters.from_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    from_date: newValue ? newValue.format("YYYY-MM-DD") : "",
                  });
                }}
                sx={{ width: "100%" }}
                maxDate={filters.to_date ? dayjs(filters.to_date) : dayjs()}
              />
            </div>
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">To</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.to_date ? dayjs(filters.to_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    to_date: newValue ? newValue.format("YYYY-MM-DD") : "",
                  });
                }}
                sx={{ width: "100%" }}
                minDate={filters.from_date ? dayjs(filters.from_date) : null}
                maxDate={dayjs()}
              />
            </div>
          </div>

          <div className="fixed md:relative bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:border-0">
            <div className="flex justify-center md:justify-end gap-3 p-6 md:p-0 md:mt-4">
              <button
                onClick={(event) => {
                  toggleDrawer("right", false)(event);
                  clearAllFilters();
                }}
                className="py-3 px-10 border border-[#112532] rounded text-sm md:text-base w-full lg:w-auto"
              >
                Cancel
              </button>
              <button
                onClick={(event) => {
                  setAppliedFilters(filters);
                  sessionStorage.removeItem("myDealsPageNumber");
                  sessionStorage.setItem(
                    "myDealsFilters",
                    JSON.stringify(filters)
                  );
                  toggleDrawer("right", false)(event);
                }}
                className="py-3 px-10 text-white bg-[#112532] rounded text-sm md:text-base w-full lg:w-auto"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="flex flex-col gap-y-4 p-6">
      <div className="flex justify-between">
        <h1 className="text-black text-[28px] font-semibold">
          All Home Owners
        </h1>
        <p>Export</p>
      </div>
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-col gap-y-3 justify-between gap-x-6 mb-3 bg-white border border-[#d9d3cf]">
          <TextField
            sx={{
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    src={filters.search ? "/search-active.svg" : "/search.svg"}
                    alt="search icon"
                    className="w-5 md:w-auto"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    loading="lazy"
                    onClick={toggleDrawer("right", true)}
                    className="cursor-pointer w-auto"
                    src={
                      getActiveFilters().length > 0
                        ? "/Filter-active.svg"
                        : "/Filter.svg"
                    }
                    alt="filter icon"
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Search ID, name or location..."
          />

          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            sx={{
              "& .MuiBackdrop-root": {
                background: "rgba(17, 37, 50, 0.5)",
              },
            }}
            PaperProps={{
              sx: {
                width: { xs: "100%", sm: "400px", md: 500 },
                height: { xs: "85%", md: "100%" },
                bottom: 0,
                top: "auto",
                "& > div:first-of-type": {
                  height: "100%",
                },
              },
            }}
          >
            {list()}
          </Drawer>

          {getActiveFilters().length > 0 && (
            <div className="flex justify-between pb-4 px-2 md:px-4">
              <ul className="flex flex-wrap gap-2 mb-2 md:mb-0">
                {getActiveFilters().map((filter, index) => (
                  <li
                    key={index}
                    className="px-2 md:px-3 py-1 bg-[#FFF8F1] border border-[#FBB667] rounded text-xs md:text-sm flex items-center font-semibold"
                  >
                    {filter}
                    <CloseIcon
                      sx={{ fontSize: { xs: "16px", md: "20px" } }}
                      className="ml-1 md:ml-2 cursor-pointer"
                      onClick={() => removeFilter(filter)}
                    />
                  </li>
                ))}
              </ul>
              <button
                className="min-w-[100px] flex items-center justify-end gap-x-1 rounded text-[#244255] font-semibold text-sm md:text-base mt-2 md:mt-0"
                onClick={clearAllFilters}
              >
                <CloseIcon sx={{ fontSize: { xs: "16px", md: "20px" } }} />
                Clear All
              </button>
            </div>
          )}
        </div>

        <CustomizedTables
          recordsArray={rows}
          setSortedRows={setSortedRows}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default AllHomeownersTable;
