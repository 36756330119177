import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { createRoot } from "react-dom/client";

const ExportDealOverview = ({ dealData, setExporting }) => {
  const [exportProgress, setExportProgress] = useState(0);
  const [exportStatus, setExportStatus] = useState("");

  const homeOwnerFullName = `${dealData?.HomeOwner?.first_name} ${dealData?.HomeOwner?.last_name}`;
  const estimatedAnnualProductionFixed =
    dealData?.Solar_design?.Estimated_Annual_production.toFixed(3);

  const indoorPhotos = [
    { key: "subPanel", label: "Sub Panel" },
    { key: "subPanel_Label", label: "Sub Panel Label" },
    { key: "subPanel_Location", label: "Sub Panel Location" },
    { key: "attic", label: "Attic" },
  ];

  const outdoorPhotos = [
    { key: "mainService_panel", label: "Main Service Panel" },
    { key: "mainService_panel_label", label: "Main Service Panel Label" },
    { key: "main_Breaker", label: "Main Breaker" },
    { key: "area_surrounding_panel", label: "Area Surrounding Panel" },
    { key: "utlity_meter", label: "Utility Meter" },
    { key: "meter_location", label: "Meter Location" },
    { key: "roof_location", label: "Roof Location" },
  ];

  const options = [
    { value: "0", label: "Active" },
    { value: "1", label: "Closed Won" },
    { value: "2", label: "Closed Lost" },
  ];

  const PdfContent = () => (
    <div className="w-[595px] bg-white p-4 pt-[10px]">
      {/* Header Section - More Compact */}
      <div className="rounded-lg shadow-sm">
        <div className="p-4">
          <div className="flex items-center gap-3 mb-2">
            <img
              className="w-12 h-12 rounded-full mt-2"
              src="/installerimage.png"
              alt=""
            />
            <div className="w-full flex justify-between items-center">
              <div>
                <h1 className="text-2xl font-semibold text-[#112532] mb-2">
                  {homeOwnerFullName}
                </h1>
                <p className="text-[9px] text-[#112532]">
                  <span className="text-[#244255]">Deal ID: </span>
                  {dealData.Deal_details.id} /{" "}
                  {dealData?.Deal_details?.homeOwner_contract_signed
                    ? "Contract"
                    : "Lead"}
                </p>
              </div>

              <div
                className={`p-2 rounded font-semibold ${
                  dealData.Deal_details.status === 2
                    ? "bg-red-50 text-red-600"
                    : "bg-emerald-50 text-emerald-800"
                }`}
              >
                <p className="mt-[-14px] text-sm">
                  {options.find(
                    (opt) =>
                      opt.value === dealData.Deal_details.status.toString()
                  )?.label || "Active"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-2 text-xs">
            <p className="flex items-center gap-1 text-[#265D78]">
              <img src="/Email.svg" alt="mail icon" className="w-4 h-4 mt-3" />
              {dealData.HomeOwner.email}
            </p>
            <p className="flex items-center gap-1">
              <img src="/Call.svg" alt="phone icon" className="w-4 h-4 mt-3" />
              {dealData.HomeOwner.mobile}
            </p>
            <p className="flex items-center gap-1">
              <img
                src="/Location.svg"
                alt="location icon"
                className="w-4 h-4 mt-3"
              />
              {dealData.HomeOwner.location}
            </p>
          </div>
        </div>
      </div>
      <hr />
      {/* Solar Design Section */}
      <div className="my-2 rounded-lg shadow-sm">
        <div className="p-2 pt-0">
          <h2 className="text-lg font-semibold text-[#112532] p-2 pt-0">
            Solar Design
          </h2>
        </div>
        <div className="p-4 pt-0">
          <div className="p-3 pl-0 mb-3 rounded text-sm">
            <div className="flex gap-2">
              <p className="text-xs">
                Based on the location
                <span className="font-semibold">
                  {" "}
                  {dealData.HomeOwner.location}{" "}
                </span>
                we recommended a base of
                <span className="font-semibold">
                  {" "}
                  {dealData.Solar_design.system_size} kW{" "}
                </span>
                to cover your current bill of
                <span className="font-semibold">
                  {" "}
                  ${dealData.HomeOwner.avg_bill}{" "}
                </span>
              </p>
            </div>
          </div>

          {/* Overview Image */}
          <div className="mb-3">
            <img
              src="/overveiw.png"
              alt="Solar Design Overview"
              className="w-full h-auto"
            />
          </div>

          <div className="flex items-center gap-x-2 mb-1">
            <img src="/step3.png" alt="step3" width={20} className="mt-4" />
            <span className="text-sm">Solar design</span>
          </div>

          <div className="grid grid-cols-3 gap-3">
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                {dealData.Solar_design.Max_panels_number}
              </h2>
              <p className="text-xs text-gray-600">solar panels</p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                {dealData.Solar_design.system_size} <sub>kW</sub>
              </h2>
              <p className="text-xs text-gray-600">System size</p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                {estimatedAnnualProductionFixed} <sub>kWh</sub>
              </h2>
              <p className="text-xs text-gray-600">
                Estimate annual production
              </p>
            </div>
          </div>
          <div className="py-4"></div>
          <hr />
          <div className="flex items-center gap-x-2 mb-1">
            <img src="/bill.png" alt="bill" width={20} className="mt-4" />
            <span className="text-sm">Bills Coverage</span>
          </div>

          <div className="grid grid-cols-3 gap-3">
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                {dealData.Solar_design.estimated_offset.toFixed(3)}%
              </h2>
              <p className="text-xs text-gray-600">Estimated offset</p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                ${dealData.HomeOwner.avg_bill}
              </h2>
              <p className="text-xs text-gray-600">Old bill</p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-[#112532] leading-tight">
                ${dealData.Solar_design.left_over_elec_bill.toFixed(3)}
              </h2>
              <p className="text-xs text-gray-600">
                Leftover electricity bill amount
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8"></div>

      {/* System Tier Section */}
      <div className="mt-2 rounded-lg shadow-sm">
        <div className="p-2 pt-0">
          <h2 className="text-lg font-semibold text-[#112532] p-2 pt-0">
            System Tier
          </h2>
        </div>
        <div className="p-4 pt-0">
          <div className="flex gap-3">
            <img src="/Stars.svg" alt="" className="w-10 h-10 mt-2" />
            <div>
              <h2 className="mb-1 text-[#FFA500] text-lg font-semibold">
                {dealData.Deal_details.system_type}
              </h2>
              <p className="text-xs text-gray-500 mb-2">
                Opt for maximum efficiency and long-term savings
              </p>
              <div className="flex gap-4 text-xs">
                <span className="flex items-center gap-1">
                  <img src="/step3.png" alt="" className="w-4 h-4 mt-3" /> REC
                  panels or similar
                </span>
                <span className="flex items-center gap-1">
                  <img src="/shield.svg" alt="" className="w-4 h-4 mt-3" /> 25
                  years warranty
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2">
        <hr />
      </div>

      {/* Additional Information Section */}
      <div className="rounded-lg shadow-sm">
        <div className="p-2 pt-0">
          <h2 className="text-lg font-semibold text-[#112532] p-2 pt-0">
            Additional Information
          </h2>
        </div>
        <div className="p-4 pt-0">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <p className="text-xs text-gray-500">Roof Type</p>
              <div className="flex items-center gap-2">
                <img src="/metal.png" alt="metal" className="w-6 h-6 mt-2" />
                <p className="font-semibold text-xs">
                  {dealData.HomeOwner.roof_type}
                </p>
              </div>
            </div>
            <div>
              <p className="text-xs text-gray-500">Roof Condition</p>
              <p className="font-semibold text-xs mt-2">
                {dealData.HomeOwner.roof_condition}
              </p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Estimated Credit Score</p>
              <p className="font-semibold text-xs mt-2">
                {dealData.HomeOwner.credit_score}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const updateProgress = (status, progress) => {
    setExporting({ status, progress });
    setExportStatus(status);
    setExportProgress(progress);
    console.log(`Export Progress - ${status}: ${progress}%`);
  };

  const handleExport = async () => {
    try {
      updateProgress("Initializing export", 0);

      const container = document.createElement("div");
      Object.assign(container.style, {
        position: "absolute",
        left: "-9999px",
        top: "-9999px",
        width: "595px",
        height: "auto",
        overflow: "hidden",
        opacity: "0",
        pointerEvents: "none",
      });

      updateProgress("Creating temporary container", 10);
      document.body.appendChild(container);

      updateProgress("Rendering content", 20);
      const root = createRoot(container);
      await new Promise((resolve) => {
        root.render(<PdfContent />);
        setTimeout(resolve, 500);
      });

      updateProgress("Generating main canvas", 30);
      const mainCanvas = await html2canvas(container.firstChild, {
        scale: 2,
        useCORS: true,
        logging: false,
        allowTaint: true,
        imageTimeout: 3000,
      });

      updateProgress("Cleaning up temporary elements", 40);
      root.unmount();
      document.body.removeChild(container);

      updateProgress("Initializing PDF document", 50);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const aspectRatio = mainCanvas.height / mainCanvas.width;
      const imgHeight = pdfWidth * aspectRatio;

      updateProgress("Adding main content to PDF", 60);
      pdf.addImage(
        mainCanvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        0,
        0,
        pdfWidth,
        imgHeight
      );

      if (imgHeight > pdfHeight) {
        pdf.addPage();
        pdf.addImage(
          mainCanvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0,
          -pdfHeight,
          pdfWidth,
          imgHeight
        );
      }

      const allPhotos = [...indoorPhotos, ...outdoorPhotos];
      const totalPhotos = allPhotos.length;
      let processedPhotos = 0;

      updateProgress("Processing site survey images", 70);

      for (const photo of allPhotos) {
        const imageUrl = dealData?.Survey_Details?.survey?.[photo.key];
        if (imageUrl) {
          try {
            processedPhotos++;
            const photoProgress = 70 + (processedPhotos / totalPhotos) * 20;
            updateProgress(`Processing image: ${photo.label}`, photoProgress);

            pdf.addPage();

            pdf.setFontSize(16);
            pdf.setTextColor(17, 37, 50);
            pdf.text(photo.label, 40, 40);

            const img = new Image();
            await new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
              img.crossOrigin = "Anonymous";
              img.src = imageUrl;
            });

            const imageAspectRatio = img.height / img.width;
            const maxWidth = pdfWidth - 80;
            const maxHeight = pdfHeight - 100;

            let finalWidth = maxWidth;
            let finalHeight = maxWidth * imageAspectRatio;

            if (finalHeight > maxHeight) {
              finalHeight = maxHeight;
              finalWidth = maxHeight / imageAspectRatio;
            }

            const xOffset = (pdfWidth - finalWidth) / 2;

            const imageCanvas = document.createElement("canvas");
            imageCanvas.width = img.width;
            imageCanvas.height = img.height;
            const ctx = imageCanvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            pdf.addImage(
              imageCanvas.toDataURL("image/jpeg", 1.0),
              "JPEG",
              xOffset,
              60,
              finalWidth,
              finalHeight
            );
          } catch (imgError) {
            console.error(`Error loading image ${photo.label}:`, imgError);
            pdf.setFontSize(12);
            pdf.setTextColor(255, 0, 0);
            pdf.text(`Error loading ${photo.label} image`, 40, 80);
          }
        }
      }

      updateProgress("Finalizing and saving PDF", 95);
      pdf.save(`deal-overview-${dealData.Deal_details.id}.pdf`);

      updateProgress("Export completed", 100);
      setTimeout(() => {
        setExportProgress(0);
        setExportStatus("");
      }, 2000);
    } catch (error) {
      console.error("Error generating PDF:", error);
      updateProgress("Export failed", 0);
    }
  };

  return (
    <div>
      <button
        onClick={handleExport}
        className="px-4 py-2 rounded border border-[#112532] text-[#112532] font-bold"
        disabled={exportProgress > 0 && exportProgress < 100}
      >
        {exportProgress > 0 ? `Exporting...` : "Export"}
      </button>
    </div>
  );
};

export default ExportDealOverview;
