import React from "react";
import ExcelJS from "exceljs";
import { format } from "date-fns";
import { formatPrice } from "utils/formatNumber";

const ExcelExport = ({ data, activeFilters }) => {
  const formatCurrency = (value) => {
    if (!value) return 0;
    return formatPrice(value);
  };

  const getDealType = (homeOwner_contract_signed) => {
    return homeOwner_contract_signed ? "Contract" : "Lead";
  };

  const getDealStatus = (status) => {
    switch (status) {
      case 0:
        return "Active";
      case 1:
        return "Closed Won";
      case 2:
        return "Closed Lost";
      default:
        return "";
    }
  };

  const exportToExcel = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Deals");

    // Transform data to match table structure
    const transformedData = data.map((row) => ({
      ID: row.id,
      Name: `${row.User?.first_name || ""} ${row.User?.last_name || ""}`,
      "Deal Type": getDealType(row.homeOwner_contract_signed),
      "Deal Status": getDealStatus(row.status),
      Tier: row.system_type,
      Location: row.User?.location || "",
      Date: format(new Date(row.createdAt), "yyyy-MM-dd"),
      Price: formatCurrency(row.systemPrice),
    }));

    // Set up columns with specific widths and alignments
    worksheet.columns = [
      {
        header: "ID",
        key: "ID",
        width: 10,
        style: { alignment: { horizontal: "left" } },
      },
      { header: "Name", key: "Name", width: 20 },
      { header: "Deal Type", key: "Deal Type", width: 15 },
      { header: "Deal Status", key: "Deal Status", width: 15 },
      { header: "Tier", key: "Tier", width: 15 },
      { header: "Location", key: "Location", width: 40 },
      { header: "Date", key: "Date", width: 15 },
      {
        header: "Price",
        key: "Price",
        width: 15,
        style: { numFmt: '"$"#,##0.00' },
      },
    ];

    // Clear any default rows
    worksheet.spliceRows(0, worksheet.rowCount);

    // Add filters row at position 1
    const filterRow = worksheet.getRow(1);
    filterRow.getCell(1).value = "Filters:";
    filterRow.getCell(1).font = { bold: true };
    filterRow.getCell(2).value =
      activeFilters.length > 0
        ? activeFilters.join(", ")
        : "No filters applied";
    filterRow.getCell(2).alignment = { horizontal: "left" };
    worksheet.mergeCells("B1:H1");

    // Add empty row
    worksheet.addRow([]);

    // Add headers
    const headerRow = worksheet.addRow(
      worksheet.columns.map((col) => col.header)
    );
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: "left" };

    // Add data rows
    transformedData.forEach((row) => {
      const dataRow = worksheet.addRow(Object.values(row));
      // Ensure ID column stays left-aligned
      dataRow.getCell(1).alignment = { horizontal: "left" };
      // Ensure Price column is formatted as currency
      dataRow.getCell(8).numFmt = '"$"#,##0.00';
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `deals_export_${format(new Date(), "yyyy-MM-dd")}.xlsx`;
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={exportToExcel}
      className="flex items-center gap-x-2 pt-3 px-2"
    >
      <img loading="lazy" className="w-5 h-5" src="/XLS.svg" alt="" />
      Excel
    </button>
  );
};

export default ExcelExport;
