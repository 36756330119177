import { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "components/shared/BreadCrumb";
import AuthenticatedHeader from "components/shared/AuthenticatedHeader";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import ReactDropZone from "components/shared/ReactDropZone";
import { updateGeneralInformation } from "../../redux/actions";
import {
  DELETEhomeownerProfilePic,
  PATCHhomeOwnerUpdateProfilePic,
} from "services/homeOwnerServices";
import {
  DELETEinstallerProfilePic,
  PATCHinstallerUpdateProfilePic,
} from "services/installerServices";
import useResponsive from "hooks/useResponsive";
import {
  DELETEadminProfilePic,
  PATCHadminProfileImageUpdate,
} from "services/AdminServices";

const Profile = () => {
  const { isSm } = useResponsive();
  const pathName = useLocation().pathname.split("/");
  const navigate = useNavigate();
  const isAuth = useAuth();
  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const dispatch = useDispatch();
  const generalData = useSelector((state) => state.generalInformation);
  const userRole = localStorage.getItem("USER");

  const [dialogData, setDialogData] = useState({
    isDialogOpen: false,
    subTitle: "",
    title: "",
    onClose: () => {},
    onConfirm: () => {},
  });
  const [imagesArray, setImagesArray] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsDropDownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const uploadImage = async () => {
      if (imagesArray[0]?.file) {
        setDialogData({
          isDialogOpen: true,
          confirmText: "Update",
          subTitle: "Are you sure you want to update the profile image?",
          title: "Update Profile Image?",
          onConfirm: async () => {
            const formData = new FormData();
            formData.append("img", imagesArray[0].file);
            try {
              if (userRole === "installer") {
                const res = await PATCHinstallerUpdateProfilePic(formData);
                dispatch(updateGeneralInformation(res.user));
                toast.success(res.message);
              } else if (userRole === "homeOwner") {
                const res = await PATCHhomeOwnerUpdateProfilePic(formData);
                dispatch(updateGeneralInformation(res.user));
                toast.success(res.message);
              } else if (userRole === "admin") {
                const res = await PATCHadminProfileImageUpdate(formData);
                dispatch(updateGeneralInformation(res.admin));
                toast.success(res.message);
              }
            } catch (err) {
              toast.error(err.response?.data?.message || "An error occurred");
            }
          },
        });
      }
    };
    uploadImage();
  }, [imagesArray]);

  const deleteProfileImage = async () => {
    if (generalData.profile_img) {
      setDialogData({
        isDialogOpen: true,
        confirmText: "Delete",
        subTitle: "Are you sure you want to delete the profile image?",
        title: "Delete Profile Image?",
        onConfirm: async () => {
          try {
            if (userRole === "installer") {
              const res = await DELETEinstallerProfilePic();
              toast.success(res.message);
            } else if (userRole === "homeOwner") {
              const res = await DELETEhomeownerProfilePic();
              toast.success(res.message);
            } else if (userRole === "admin") {
              const res = await DELETEadminProfilePic();
              toast.success(res.message);
            }
            dispatch(updateGeneralInformation({ profile_img: null }));
            setImagesArray([]);
          } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred");
          }
        },
      });
    }
  };

  const handleClickToScroll = (first) => {
    if (scrollContainerRef.current) {
      if (first) {
        scrollContainerRef.current.scrollLeft = 0;
      } else {
        scrollContainerRef.current.scrollLeft =
          scrollContainerRef.current.scrollWidth;
      }
    }
  };

  return (
    <div className="bg-[#F9F8F8] h-screen">
      {isAuth && <AuthenticatedHeader />}
      <div className="px-4 md:px-6 bg-white md:bg-inherit">
        <ConfirmationDialog
          open={dialogData.isDialogOpen}
          onClose={() => {
            setDialogData({ ...dialogData, isDialogOpen: false });
            setImagesArray([]);
          }}
          onConfirm={() => {
            dialogData.onConfirm();
            setDialogData({ ...dialogData, isDialogOpen: false });
          }}
          subTitle={dialogData.subTitle}
          title={dialogData.title}
          confirmText={dialogData.confirmText}
        />
        <BreadCrumb
          paths={[
            {
              name: "Dashboard",
              pathLink:
                userRole === "installer"
                  ? "/dashboard-installer"
                  : userRole === "homeOwner"
                    ? "/dashboard"
                    : "/admin-dashboard",
            },
            {
              name: "My Profile",
              pathLink: `/personal-details`,
            },
          ]}
          isShowConfirmationDialog={isBackDialogOpen}
          title="Discard Changes?"
          subTitle="Changes will not be saved, are you sure you want to continue?"
          onConfirm={() => {
            navigate(
              userRole === "installer"
                ? "/dashboard-installer"
                : userRole === "homeOwner"
                  ? "/dashboard"
                  : "/admin-dashboard"
            );
          }}
        />

        <div className="flex flex-col gap-y-4 ">
          <h1 className="hidden lg:block text-[23px] font-semibold text-[#112532]">
            My Profile
          </h1>

          {/* Mobile Profile Header */}
          <div className="md:hidden flex flex-col bg-white p-4">
            <div className="flex flex-col items-center gap-y-4 mb-4 p-4 rounded-[10px] border border-[#D9D3CF] shadow">
              <div className="relative w-16 h-16">
                <div className="group">
                  <img
                    loading="lazy"
                    className="w-16 h-16 rounded-full object-cover"
                    src={imagesArray[0]?.preview || generalData.profile_img}
                    alt="Profile"
                  />
                  <div className="hidden">
                    <ReactDropZone
                      isInputOpen={isInputOpen}
                      setImagesArray={setImagesArray}
                      setIsInputOpen={setIsInputOpen}
                      variant="profile"
                    />
                  </div>
                  <div
                    onClick={() => setIsDropDownOpen((state) => !state)}
                    className="absolute inset-0 bg-[#1125324D] bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full cursor-pointer"
                  >
                    <img src="/camera.svg" alt="" />
                  </div>
                </div>
                {isDropDownOpen && (
                  <div
                    ref={isSm ? containerRef : null}
                    className="absolute w-[300%] left-[-60px] md:w-[200%] shadow z-20"
                  >
                    <div
                      className="flex gap-x-2 px-5 py-4 bg-white border-l border-r border-b border-[#ececee] text-[#272a2e] text-sm cursor-pointer"
                      onClick={() => setIsInputOpen(true)}
                    >
                      <img src="/camera-icon.svg" alt="" />
                      Upload Photo
                    </div>
                    {generalData.profile_img !== "/default-profile.svg" && (
                      <div
                        className="flex gap-x-2 px-4 py-3 bg-white border-l border-r border-b border-[#ececee] text-red-700 text-sm cursor-pointer"
                        onClick={deleteProfileImage}
                      >
                        <img src="/trash.svg" alt="" />
                        Delete Photo
                      </div>
                    )}
                  </div>
                )}
              </div>
              <h1 className="text-xl md:text-[33px] text-[#112532] font-semibold">
                {generalData.fullName}
              </h1>
            </div>

            {/* Horizontal scrollable tabs for mobile */}
            <div
              ref={scrollContainerRef}
              className="flex overflow-x-auto scrollbar-hide border-b border-[#ECECEE]"
            >
              <NavLink
                onClick={() => handleClickToScroll(true)}
                to="/personal-details"
                className={({ isActive }) =>
                  `whitespace-nowrap px-4 py-2 text-sm font-medium ${
                    pathName[pathName.length - 1] === "personal-details" &&
                    "bg-[#FFFBEB] border-b-4 border-[#F59E0B] rounded font-semibold"
                  }`
                }
              >
                Personal Details
              </NavLink>
              {userRole === "installer" && (
                <NavLink
                  to="/personal-details/business-details"
                  className={({ isActive }) =>
                    `whitespace-nowrap px-4 py-2 text-sm font-medium ${
                      isActive &&
                      "bg-[#FFFBEB] border-b-4 border-[#F59E0B] rounded font-semibold"
                    }`
                  }
                >
                  Business Details
                </NavLink>
              )}
              <NavLink
                onClick={() => handleClickToScroll(false)}
                to="/personal-details/change-password"
                className={({ isActive }) =>
                  `whitespace-nowrap px-4 py-2 text-sm font-medium ${
                    isActive &&
                    "bg-[#FFFBEB] border-b-4 border-[#F59E0B] rounded font-semibold"
                  }`
                }
              >
                Change Password
              </NavLink>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex gap-x-5">
            <div className="w-1/3 flex flex-col p-8 gap-y-4 bg-white h-fit rounded-[10px] border-1 border-[#D9D3CF]">
              <div className="flex items-center gap-x-4">
                <div className="relative w-24 h-24">
                  <div className="group">
                    <img
                      loading="lazy"
                      className="w-24 h-24 rounded-full object-cover"
                      src={imagesArray[0]?.preview || generalData.profile_img}
                      alt="Profile"
                    />
                    <div className="hidden">
                      <ReactDropZone
                        isInputOpen={isInputOpen}
                        setImagesArray={setImagesArray}
                        setIsInputOpen={setIsInputOpen}
                        variant="profile"
                      />
                    </div>
                    <div
                      onClick={() => setIsDropDownOpen((state) => !state)}
                      className="absolute inset-0 bg-[#1125324D] bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full cursor-pointer"
                    >
                      <img src="/camera.svg" alt="" />
                    </div>
                  </div>
                  {isDropDownOpen && (
                    <div
                      ref={isSm ? null : containerRef}
                      className="absolute w-[200%] shadow z-10"
                    >
                      <div
                        className="flex gap-x-2 px-4 py-3 bg-white border-l border-r border-b border-[#ececee] text-[#272a2e] text-sm cursor-pointer"
                        onClick={() => setIsInputOpen(true)}
                      >
                        <img src="/camera-icon.svg" alt="" />
                        Upload Photo
                      </div>
                      {generalData.profile_img !== "/default-profile.svg" && (
                        <div
                          className="flex gap-x-2 px-4 py-3 bg-white border-l border-r border-b border-[#ececee] text-red-700 text-sm cursor-pointer"
                          onClick={deleteProfileImage}
                        >
                          <img src="/trash.svg" alt="" />
                          Delete Photo
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <h1 className="text-[33px] text-[#112532] font-semibold">
                  {generalData.fullName}
                </h1>
              </div>
              <hr className="border-[#ECECEE]" />
              <div className="flex flex-col gap-y-4">
                <NavLink
                  to="/personal-details"
                  className={({ isActive }) =>
                    pathName[pathName.length - 1] === "personal-details"
                      ? "p-3 text-black font-semibold rounded bg-[#FFFBEB] border-l-4 border-[#F59E0B]"
                      : "p-3 text-black font-semibold rounded"
                  }
                >
                  Personal Details
                </NavLink>
                {userRole === "installer" && (
                  <NavLink
                    to="/personal-details/business-details"
                    className={({ isActive }) =>
                      isActive
                        ? "p-3 text-black font-semibold rounded bg-[#FFFBEB] border-l-4 border-[#F59E0B]"
                        : "p-3 text-black font-semibold rounded"
                    }
                  >
                    Business Details
                  </NavLink>
                )}
                <NavLink
                  to="/personal-details/change-password"
                  className={({ isActive }) =>
                    isActive
                      ? "p-3 text-black font-semibold rounded bg-[#FFFBEB] border-l-4 border-[#F59E0B]"
                      : "p-3 text-black font-semibold rounded"
                  }
                >
                  Change Password
                </NavLink>
              </div>
            </div>
            <div className="flex-1">
              <Outlet context={{ setDialogData, setIsBackDialogOpen }} />
            </div>
          </div>

          {/* Mobile Content */}
          <div className="md:hidden">
            <Outlet context={{ setDialogData, setIsBackDialogOpen }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
