export const ACCESS_TOKEN = "token";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const TOKEN_TYPE = "Bearer ";

export const PASSWORD_PATTERN = /^().{8,}$/;

export const indoorSurveySteps = [
  {
    step: 0,
    images: {
      src: "/subPanel.jpg",
      alt: "Subpanel",
      title: "Subpanel",
      description: "Look for...",
      DB_Name: "subPanel",
    },
  },
  {
    step: 1,
    images: {
      src: "/subpanelLabel.jpg",
      alt: "Subpanel-Label",
      title: "Subpanel Label",
      description: "Look for...",
      DB_Name: "subPanel_Label",
    },
  },
  {
    step: 2,
    images: {
      src: "/subpanelLocation.jpg",
      alt: "Subpanel Location ",
      title: "Subpanel Location",
      description: "Look for...",
      DB_Name: "subPanel_Location",
    },
  },
  {
    step: 3,
    images: {
      src: "/attic.jpg",
      alt: "Subpanel",
      title: "Attic",
      description: "Look for above your ceiling",
      DB_Name: "attic",
    },
  },
  {
    step: 4,
    images: {
      src: "/attic.jpg",
      alt: "Subpanel",
      title: "Attic",
      description: "Look for above your ceiling",
      DB_Name: "attic",
    },
  },
];

export const outdoorSurveySteps = [
  {
    step: 0,
    images: {
      src: "/mainPanel.png",
      alt: "Subpanel",
      title: "Main Service Panel",
      description: "Look for above your ceiling",
      DB_Name: "mainService_panel",
    },
  },
  {
    step: 1,
    images: {
      src: "/subpanelLabel.jpg",
      alt: "Panel Label",
      title: "Main Service Panel Label",
      description: "Look for above your ceiling",
      DB_Name: "mainService_panel_label",
    },
  },
  {
    step: 2,
    images: {
      src: "/Main Breaker.png",
      alt: "Main Breaker",
      title: "Main Breaker",
      description: "Look for above your ceiling",
      DB_Name: "main_Breaker",
    },
  },
  {
    step: 3,
    images: {
      src: "/Area Surrounding Panel.png",
      alt: "Area Surrounding Panel",
      title: "Area Surrounding Panel",
      description: "Look for above your ceiling",
      DB_Name: "area_surrounding_panel",
    },
  },
  {
    step: 4,
    images: {
      src: "/Utility Meter.png",
      alt: "Subpanel",
      title: "Utility Meter",
      description: "Look for above your ceiling",
      DB_Name: "utlity_meter",
    },
  },
  {
    step: 5,
    images: {
      src: "/Meter Location.png",
      alt: "Meter Location",
      title: "Meter Location",
      description: "Look for above your ceiling",
      DB_Name: "meter_location",
    },
  },
  {
    step: 6,
    images: {
      src: "/Roof Location.png",
      alt: "Roof Location",
      title: "Roof Location",
      description: "Look for above your ceiling",
      DB_Name: "roof_location",
    },
  },
  {
    step: 7,
    images: {
      src: "/Roof Location.png",
      alt: "Roof Location",
      title: "Roof Location",
      description: "Look for above your ceiling",
      DB_Name: "roof_location",
    },
  },
];

export const classOptions = [
  {
    value: "Silver",
    label: "Silver",
    icon: <img className="h-7 w-7" src="/Diamond.svg" alt="" />,
  },
  {
    value: "Gold",
    label: "Gold",
    icon: <img className="h-7 w-7" src="/Gold.svg" alt="" />,
  },
  {
    value: "Platinum",
    label: "Platinum",
    icon: <img className="h-7 w-7" src="/Platinum.svg" alt="" />,
  },
];

export const tierImage = (system_type) => {
  switch (system_type) {
    case "Basic":
      return "/Stars1.svg";
    case "Standard":
      return "/Stars2.svg";
    case "Premium":
      return "/Stars3.svg";
    default:
      break;
  }
};

export const getBadgeIcon = (badge) => {
  switch (badge.toLowerCase()) {
    case "platinum":
      return "/Platinum.svg";
    case "gold":
      return "/Gold.svg";
    default:
      return "/Diamond.svg";
  }
};

export const dealStatus = (status) => {
  switch (status) {
    case 0:
      return "Active";
    case 1:
      return "Closed Won";
    case 2:
      return "Closed Lost";
    default:
      return "";
  }
};
