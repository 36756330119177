import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useAuth = () => {
  const token = localStorage.getItem("token");

  return token ? true : false;
};

export const logout = () => {
  localStorage.clear();
  toast.success("logged out successfully");
  window.location = "/";
};
