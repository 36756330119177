import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import NextComponent from "./NextComponent";
import useResponsive from "hooks/useResponsive";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(1);
  const navigate = useNavigate();

  const { isSm } = useResponsive();
  const imagesPath = isSm ? "intro-animation-mobile" : "intro-animation";

  const { scrollYProgress, scrollY } = useScroll({
    target: containerRef,
  });

  // Calculate the scroll range for fading in NextComponent
  const fadeStartProgress = 140 / 200; // Frame 150 relative to total frames
  const fadeEndProgress = 1; // End of scroll

  // Transform scroll progress to opacity value only after frame 150
  const opacity = useTransform(
    scrollYProgress,
    [fadeStartProgress, fadeEndProgress],
    [0, 1]
  );

  useMotionValueEvent(scrollY, "change", (latest) => {
    setIsScrolled(latest > 100);
  });

  const images = useMemo(() => {
    const loadedImages = [];
    for (let i = 32; i < 170; i++) {
      const img = new Image();
      img.src = `/assets/${imagesPath}/${i}.webp`;
      loadedImages.push(img);
    }
    return loadedImages;
  }, [isSm]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const setCanvasSize = () => {
        if (images[0] && images[0].complete) {
          canvas.width = images[0].width;
          canvas.height = images[0].height;
          render(1);
        }
      };

      setCanvasSize();
      images[0].onload = setCanvasSize;
    }
  }, [images]);

  const render = useCallback(
    (index) => {
      if (images[index - 1] && canvasRef.current) {
        const context = canvasRef.current.getContext("2d");
        context?.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        context?.drawImage(images[index - 1], 0, 0);
      }
    },
    [images]
  );

  useEffect(() => {
    // Force scroll to top
    window.scrollTo(0, 0);

    // Prevent default scroll restoration
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, [isSm]);
  const currentIndex = useTransform(scrollYProgress, [0, 1], [1, 170]);

  useMotionValueEvent(currentIndex, "change", (latest) => {
    const frame = +latest.toFixed();
    setCurrentFrame(frame);
    render(frame);
    if (frame >= 170) {
      setIsAnimationComplete(true);
    } else {
      setIsAnimationComplete(false);
    }
  });

  return (
    <div className="z-10">
      <motion.div className="fixed left-0 top-0 w-screen flex justify-center items-center">
        <canvas ref={canvasRef} className="w-screen h-screen object-cover" />
      </motion.div>
      <div ref={containerRef} className="h-[6000px] md:pt-[94px]" />

      <motion.div style={{ opacity }} className="relative">
        <NextComponent />
      </motion.div>
    </div>
  );
};

export default Home;
