import styled from "@emotion/styled";
import { Slider } from "@mui/material";

export const CustomSlider = styled(Slider)({
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "rgb(251 191 36)",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "rgba(251, 191, 36, 0.3)",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "rgb(251 191 36)",
  },
  "& .MuiSlider-valueLabel": {
    background: "none",
    color: "rgb(251 191 36)",
    fontSize: "1rem",
    fontWeight: "bold",
    top: "calc(-100% + 20px)",
  },
});
