import ApiService from "./api";

export const POSTcontactUs = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `/contact-us`,
    method: "POST",
    data,
  });
  return response;
};

export const POSTpanelsWithoutObs = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `/solar/check-panels-obstructions`,
    method: "POST",
    data,
  });
  return response;
};
