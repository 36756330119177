import "@fortawesome/fontawesome-free/css/all.min.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App.js";
import "./index.css";
import store from "./redux/store.js";
import reportWebVitals from "./reportWebVitals.js";
import { router } from "./routes.js"; // Import the router configuration from routes
import api from "./services/api";
import * as serviceWorker from "./serviceWorker";

const theme = createTheme({
  typography: {
    fontFamily: `"Plus Jakarta Sans", sans-serif`,
  },
});

// Initialize the API service
api.init();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    {/* Wrap the entire application */}
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* Use RouterProvider to manage the routes */}
          <ToastContainer />
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>
);

// Register the service worker for PWA functionality (optional)
serviceWorker.register(); // Change from unregister() to register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
