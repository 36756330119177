import { PASSWORD_PATTERN } from "utils/constant";
import { number, object, ref, string } from "yup";

export const loginSchema = object({
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  password: string()
    .required("password is required!")
    .matches(PASSWORD_PATTERN, "the password at least should be 8 characters"),
}).required();

export const adminPersonalDetailsSchema = object({
  first_name: string()
    .required("First name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  last_name: string()
    .required("Last name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  mobile: string()
    .required("Phone Number is required!")
    .min(10, "The phone number must be at least 10 digits.")
    .max(10, "more than 10 digits are not allowed."),
}).required();
