import React from "react";
import {
  Select,
  MenuItem,
  styled,
  FormControl,
  InputLabel,
} from "@mui/material";

// Custom styled select with a height of 26px
const StyledSelect = styled(Select)(({ theme, value }) => ({
  "& .MuiSelect-select": {
    height: "26px", // Custom height for the select input
    display: "flex",
    alignItems: "center", // Vertically center the text
    paddingTop: 0, // Ensure no extra padding at the top
    paddingBottom: 0, // Ensure no extra padding at the bottom
    boxSizing: "border-box", // Make sure height includes padding and border
    backgroundColor: value === "2" ? "#FEE2E2" : "#ECFDF5",
    color: value === "2" ? "#DC2626" : "#064E3B",
    fontWeight: "600",
  },
  "& .MuiSelect-icon": {
    color: "#064E3B",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
    border: 0,
  },
}));

const SelectComponent = ({
  options,
  value,
  onChange,
  label = "",
  iconComponent: IconComponent, // Adding iconComponent prop
  ...otherProps
}) => {
  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <StyledSelect
        sx={{ width: "auto" }}
        value={value}
        onChange={onChange}
        IconComponent={IconComponent} // Set the custom icon
        {...otherProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectComponent;
