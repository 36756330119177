import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const ReactDropZone = ({
  setImagesArray, // Function to update the parent with the updated images
  setFilesArray,
  variant = "default", // Image upload variant: 'default' for single, 'portfolio' for multiple
  currentImagesLength,
  isInputOpen,
  setIsInputOpen,
  trigger,
}) => {
  const [error, setError] = useState("");

  const handleDrop = (acceptedFiles, fileRejections) => {
    if (trigger) trigger();
    setError("");

    // Handle file rejections for unsupported file types
    if (fileRejections.length > 0) {
      setError(
        variant === "chat"
          ? "Some files were rejected."
          : "Only PNG, JPG, and JPEG formats are supported."
      );
      return;
    }

    // Handle file upload for the "chat" variant (all file types)
    if (variant === "chat") {
      setFilesArray((state) => [...state, ...acceptedFiles]); // Update parent with the new files
      return;
    }

    // Validate for single image upload limit in default variant
    if (
      variant === "default" &&
      currentImagesLength + acceptedFiles.length > 1
    ) {
      setError("You can only upload one image at a time.");
      return;
    }

    if (variant === "portfolio") {
      // Portfolio variant allows multiple images, but limit to 5 images
      if (currentImagesLength + acceptedFiles.length > 5) {
        setError("You can upload up to 5 images.");
        return;
      }

      const newImages = acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      setImagesArray((state) => [...state, ...newImages]); // Update parent with new images
    } else if (variant === "default" || "profile") {
      // Default variant: handle single image upload
      const newImage = acceptedFiles[0];

      setImagesArray([
        {
          file: newImage,
          preview: URL.createObjectURL(newImage),
        },
      ]);
    }
  };

  const accept =
    variant === "chat"
      ? {
          "application/pdf": [],
          // Accept Videos
          "video/mp4": [],
          "video/mpeg": [],
          "video/quicktime": [],
          "video/x-msvideo": [], // .avi
          // Accept Images
          "image/png": [],
          "image/jpeg": [],
          "image/jpg": [],
          "image/gif": [],
          // Accept Word Files
          "application/msword": [], // .doc
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
        }
      : {
          "image/png": [],
          "image/jpeg": [],
          "image/jpg": [],
        };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop: handleDrop,
    multiple: variant !== "default", // don't allow  multiple uploads in default variant
  });

  useEffect(() => {
    if (isInputOpen) {
      open();
      setIsInputOpen(false);
    }
  }, [isInputOpen]);

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className="h-full flex items-center border-2 border-[#ecf0f4] border-dashed"
    >
      <input {...getInputProps()} />
      <div className="w-full p-5 text-center">
        <div className="flex justify-center ">
          <img
            loading="lazy"
            className={variant === "profile" ? "w-10" : ""}
            src="/dropZone.svg"
            alt="Drop Zone"
          />
        </div>
        <p className={variant === "profile" ? "text-[12px]" : "pb-2"}>
          Drop your image{variant === "portfolio" ? "s" : ""} here, or
          <span className="text-[#1F4690]"> browse</span>
        </p>
        <p className="text-[8px] text-center">Supports: PNG, JPG, JPEG</p>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default ReactDropZone;
