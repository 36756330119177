import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "components/shared/Header";
import SiteSurveySteps from "components/home-owner/SiteSurveySteps";
import {
  GETgeneralInformationHomeowner,
  GEThomeOwnerPersonalDetails,
  getUploadedSurvey,
} from "services/homeOwnerServices";
import {
  setIndoorStep,
  setOutdoorStep,
  setSurveyCompleted,
  updateGeneralInformation,
} from "../../redux/actions";
import SurveyGuide from "../../components/home-owner/SurveyGuide";
import SurveyQrCode from "../../components/home-owner/SurveyQrCode";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { indoorSurveySteps, outdoorSurveySteps } from "utils/constant";
import { toast } from "react-toastify";

const SiteSurvey = () => {
  const dispatch = useDispatch();
  const indoorActiveStep = useSelector(
    (state) => state.siteSurvey.indoorActiveStep
  );
  const outdoorActiveStep = useSelector(
    (state) => state.siteSurvey.outdoorActiveStep
  );
  const currentSurveyType = useSelector(
    (state) => state.siteSurvey.currentSurveyType
  );

  const location = useLocation();
  const [tokenSet, setTokenSet] = useState(false);
  const [shouldRenderGuide, setShouldRenderGuide] = useState(true);
  const [shouldRenderSteps, setShouldRenderSteps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const setToken = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("temp_token");

      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("activeStep", "6");
        localStorage.setItem("USER", "homeOwner");
        try {
          const res = await GETgeneralInformationHomeowner();
          dispatch(updateGeneralInformation(res));
        } catch (error) {
          toast.error(error.data.message);
        } finally {
        }
      }

      // Mark that token has been set
      setTokenSet(true);
    };

    setToken();
  }, [location]);

  useEffect(() => {
    const surveyStatus = async () => {
      try {
        setIsLoading(true);
        const res = await getUploadedSurvey();
        if (res?.indoorStep?.length + res?.outdoorStep.length >= 11) {
          setShouldRenderGuide(true);
        } else {
          setShouldRenderGuide(false);
        }
        if (res?.isSiteSurveyCompleted) {
          // if completed and wants to edit he can start from scratch
          setShouldRenderGuide(true);
          dispatch(setIndoorStep(0));
          dispatch(setOutdoorStep(0));
          dispatch(setSurveyCompleted("indoor", false));
          dispatch(setSurveyCompleted("outdoor", false));
          return;
        }
        if (res?.survey) {
          setShouldRenderGuide(false);
          dispatch(setIndoorStep(4 - res?.indoorStep?.length));
          dispatch(setOutdoorStep(7 - res?.outdoorStep?.length));
          dispatch(setSurveyCompleted("indoor", res?.isIndoorCompleted));
          dispatch(setSurveyCompleted("outdoor", res?.isOutdoorCompleted));
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    surveyStatus();
  }, []);

  const renderIndoorSurveySteps = () => {
    return indoorSurveySteps.map(({ step, images }) => {
      if (currentSurveyType === 0 && indoorActiveStep === step) {
        return (
          <SiteSurveySteps
            setShouldRenderSteps={setShouldRenderSteps}
            key={step}
            steps={["", "", "", ""]}
            images={images}
            showSubmittedDialog={step === 4}
          />
        );
      }
      return null;
    });
  };

  const renderOutdoorSurveySteps = () => {
    return outdoorSurveySteps.map(({ step, images }) => {
      if (currentSurveyType === 1 && outdoorActiveStep === step) {
        return (
          <SiteSurveySteps
            setShouldRenderSteps={setShouldRenderSteps}
            key={step}
            steps={["", "", "", "", "", "", ""]}
            activeStep={step}
            images={images}
            showSubmittedDialog={step === 7}
          />
        );
      }
      return null;
    });
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  if (shouldRenderGuide)
    return (
      <>
        <Header />
        <div className="p-6">
          <BreadCrumb
            paths={[
              { name: "Dashboard", pathLink: "/dashboard" },
              {
                name: "Site Survey",
                pathLink: `/dashboard/site-survey`,
              },
            ]}
            onConfirm={() => {
              navigate("/dashboard");
            }}
          />
          <SurveyGuide setShouldRenderGuide={setShouldRenderGuide} />
        </div>
      </>
    );

  if (!shouldRenderSteps)
    return (
      <>
        <Header />
        <div className="p-6">
          <BreadCrumb
            paths={[
              { name: "Dashboard", pathLink: "/dashboard" },
              {
                name: "Site Survey",
                pathLink: `/dashboard/site-survey`,
              },
            ]}
            onConfirm={() => {
              navigate("/dashboard");
            }}
          />
          <SurveyQrCode setShouldRenderSteps={setShouldRenderSteps} />
        </div>
      </>
    );

  return (
    <div>
      <Header />
      {renderIndoorSurveySteps()}
      {renderOutdoorSurveySteps()}
    </div>
  );
};

export default SiteSurvey;
