import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const MainAuthGuard = ({ children }) => {
  const location = useLocation();
  const isAuth = useAuth();
  useEffect(() => {}, [location.pathname]);

  if (isAuth) {
    return children;
  } else {
    toast.error("Please login and continue!");
    return <Navigate to="/" replace={true} />;
  }
};

export default MainAuthGuard;
