import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import CustomStepper from "components/MUI/CustomStepper";
import { useState } from "react";
import { Controller } from "react-hook-form";
import HomeOwnerPrivacyPolicy from "../HomeOwnerPrivacyPolicy";
import HomeOwnerTermsAndConditions from "../HomeOwnerTermsAndConditions";
import { handleKeyDown } from "utils/formHelpers";
import AreaIsUnsupported from "../AreaIsUnsupported";

const PersonalDetailsRegistration = ({
  control,
  errors,
  onSubmit,
  onBack,
  currentStep,
  renderMap,
  isValid,
  isLoading,
  setShowModal,
  showModal,
}) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);

  return (
    <div className="flex-1">
      <HomeOwnerTermsAndConditions
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onAgree={() => setIsTermsAgreed(true)}
      />
      <HomeOwnerPrivacyPolicy
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
        onAgree={() => setIsPrivacyAgreed(true)}
      />

      <AreaIsUnsupported showModal={showModal} setShowModal={setShowModal} />

      <h1 className="text-[#112532] text-[28px] font-semibold md:text-[55px] mb-[48px]">
        Solar panel system customized just for you
      </h1>

      <div className="flex flex-col gap-y-6 md:gap-y-8">
        <div className="-ml-6 md:-ml-[85px]">
          <CustomStepper
            width="calc(100% + 20px)"
            steps={["Home Details", "Personal Details", "System Tiers"]}
            activeStepSolar={currentStep}
            orientation="horizontal"
          />
        </div>

        <div className="flex flex-col gap-y-2">
          <h2 className="text-[#112532] text-[19px] font-semibold">
            Personal Details
          </h2>
          <p className="flex flex-col md:gap-y-8 text-[#112532] text-xs">
            Personalize your project with additional configurations
          </p>
        </div>

        <div className="w-full h-[208px] rounded-lg shadow overflow-hidden md:hidden">
          {renderMap(false)}
        </div>

        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="w-full"
              label="First Name*"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="w-full"
              label="Last Name*"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          )}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="w-full"
              label="Phone Number*"
              error={!!errors.mobile}
              helperText={errors.mobile?.message}
              type="text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                    +1
                  </div>
                ),
              }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="w-full"
              label="Email*"
              error={!!errors.email}
              helperText={errors.email?.message}
              type="email"
            />
          )}
        />

        <FormGroup>
          <div className="flex items-center gap-x-1">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onChange={() => setIsTermsModalOpen(true)}
                  checked={isTermsAgreed}
                />
              }
              label="I Agree to"
            />
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsTermsModalOpen(true)}
            >
              Terms and Conditions
            </p>
          </div>
          <div className="flex items-center gap-x-1">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onChange={() => setIsPrivacyModalOpen(true)}
                  checked={isPrivacyAgreed}
                />
              }
              label="I Agree to"
            />
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsPrivacyModalOpen(true)}
            >
              Privacy Policy
            </p>
          </div>
        </FormGroup>
        <div className="flex flex-col md:flex-row-reverse justify-end gap-x-2 gap-y-4">
          <button
            onClick={onSubmit}
            disabled={
              !isTermsAgreed || !isPrivacyAgreed || !isValid || isLoading
            }
            className="px-10 py-3 bg-[#112532] rounded-[5px] text-[#fffefb] font-bold disabled:bg-opacity-50 min-w-[117px]"
          >
            {isLoading ? (
              <CircularProgress size="20px" sx={{ color: "#fffefb" }} />
            ) : (
              "Next"
            )}
          </button>
          <button
            type="button"
            onClick={onBack}
            className="px-10 py-3 bg-[#fffefb] rounded-[5px] border border-[#112532] font-bold"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsRegistration;
