import React, { useState, useRef, useLayoutEffect } from "react";

const HomeOwnerPrivacyPolicy = ({ isOpen, onClose, onAgree }) => {
  const [canAgree, setCanAgree] = useState(false);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    const checkScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setCanAgree(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    const content = contentRef.current;

    const setupScrollListener = () => {
      if (content) {
        content.addEventListener("scroll", checkScroll);
      }
    };

    const timeoutId = setTimeout(setupScrollListener, 50);

    return () => {
      if (content) {
        content.removeEventListener("scroll", checkScroll);
      }
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleAgree = () => {
    onAgree();
    onClose();
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      />
      <div className="fixed inset-0 flex items-center justify-center z-50 md:p-4">
        <div className="bg-white w-full md:max-w-4xl h-full md:max-h-[90vh] rounded-[10px] shadow-lg overflow-hidden flex flex-col">
          <h1 className="p-8 pb-6 text-[#112532] text-[24px] lg:text-[33px] font-semibold">
            Privacy Policy
          </h1>
          <hr />
          <div ref={contentRef} className="overflow-y-auto flex-grow p-8">
            <div className="max-w-4xl mx-auto">
              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
                <p className="text-gray-700 leading-relaxed">
                  At Spark Solar, we are committed to protecting your privacy.
                  This Privacy Policy outlines how we collect, use, and share
                  your personal information when you use our Site and Services.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  2. Information We Collect
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We collect personal information you provide when using our
                  Services, including:
                </p>
                <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
                  <li>Full Name</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Home address</li>
                  <li>Electricity bill information</li>
                  <li>Other details relevant to solar installation</li>
                </ul>
                <p className="text-gray-700 leading-relaxed mt-4">
                  We also collect non-personally identifiable information about
                  how users interact with our Site, such as browsing data and
                  device information.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  3. How We Use Your Information
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We use the personal information we collect for the following
                  purposes:
                </p>
                <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
                  <li>
                    To connect you with solar installers who can provide quotes
                    and offers for solar installation.
                  </li>
                  <li>To improve our platform and services.</li>
                  <li>
                    To contact you with relevant information about our services
                    and updates.
                  </li>
                </ul>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  4. Sharing Your Information
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We sell your personal information to third-party solar
                  installers in order to facilitate their ability to provide you
                  with offers for solar installations. This includes sharing
                  your name, contact details, home address, electricity bill
                  information, and other relevant details. We do not sell or
                  share your information with any party outside of our network
                  of solar installers without your explicit consent, except
                  where required by law.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">5. Data Security</h2>
                <p className="text-gray-700 leading-relaxed">
                  We use commercially reasonable security measures to protect
                  your personal information. However, no method of transmission
                  over the internet is completely secure, and we cannot
                  guarantee the absolute security of your data.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">6. Your Rights</h2>
                <p className="text-gray-700 leading-relaxed">
                  You have the following rights regarding your personal data:
                </p>
                <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
                  <li>
                    <strong>Access:</strong> You can request access to the
                    information we hold about you.
                  </li>
                  <li>
                    <strong>Rectification:</strong> You can request corrections
                    to any incorrect or incomplete information.
                  </li>
                  <li>
                    <strong>Deletion:</strong> You can request the deletion of
                    your data at any time, though this may affect your ability
                    to use our Services.
                  </li>
                </ul>
                <p className="text-gray-700 leading-relaxed mt-4">
                  To exercise these rights, please contact us at{" "}
                  <a
                    href="mailto:info@sparksolar.io"
                    className="text-blue-600 hover:underline"
                  >
                    info@sparksolar.io
                  </a>
                  .
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">7. Cookies</h2>
                <p className="text-gray-700 leading-relaxed">
                  We use cookies and other tracking technologies to enhance your
                  experience on our Site and analyze Site traffic. You can
                  manage your cookie preferences through your browser settings.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  8. Third-Party Links and Services
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  The Spark Solar website may contain links to third-party
                  websites or services. We are not responsible for the privacy
                  practices or the content of such websites. Users are
                  encouraged to review the privacy policies of any third-party
                  websites they visit.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  9. Data Retention
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We retain personal data for as long as necessary to fulfill
                  the purposes outlined in this Privacy Policy, unless a longer
                  retention period is required or permitted by law. Upon
                  termination of our relationship with the user, we may retain
                  limited information to comply with legal obligations or
                  resolve disputes.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">10. Do Not Track</h2>
                <p className="text-gray-700 leading-relaxed">
                  Spark Solar does not currently respond to "Do Not Track"
                  signals sent by web browsers.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  11. Children's Privacy
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  Our services are not intended for children under the age of
                  18, and we do not knowingly collect personal information from
                  minors.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                  12. Changes to This Policy
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We may update this Privacy Policy from time to time. We will
                  notify users of any significant changes, and your continued
                  use of the Site after such updates constitutes acceptance of
                  the revised policy.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">13. Contact Us</h2>
                <p className="text-gray-700 leading-relaxed">
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at:
                </p>
                <p className="text-gray-700 leading-relaxed">
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:info@sparksolar.io"
                    className="text-blue-600 hover:underline"
                  >
                    info@sparksolar.io
                  </a>
                  <br />
                  <strong>Address:</strong> 500 East McBee Ave, Suite 100 #1251,
                  Greenville, SC 29601, United States
                </p>
              </section>
            </div>
          </div>
          <hr />
          <section className="py-6 px-8 flex justify-end gap-x-3">
            <button
              onClick={onClose}
              className="px-10 py-3 rounded-[5px] border border-[#112532] font-bold hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleAgree}
              disabled={!canAgree}
              className={`px-10 py-3 rounded-[5px] text-[#fffefb] font-bold transition-colors ${
                canAgree
                  ? "bg-[#112532] hover:bg-[#1f3d4e]"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              I Agree
            </button>
          </section>
        </div>
      </div>
    </>
  );
};

export default HomeOwnerPrivacyPolicy;
