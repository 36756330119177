import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EquipmentList from "components/installer/EquipmentList";
import { IosSwitch } from "components/MUI/IosSwitch";
import BreadCrumb from "components/shared/BreadCrumb";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { classOptions } from "utils/constant";
const dummyData = {
  id: 1,
  first_name: "Fawzi",
  last_name: "Alazraq",
  mobile: "4654",
  email: "fawdzvis@fasw3z.co2mmss6s",
  emailVerified: null,
  password: "$2a$10$8uisGsGpLIIo3K09l9QaKuESZ.ayfSAgglaY4vQOKmYxHTr6P/Mka",
  status: null,
  business_address: "New Jersey",
  business_name: "Spark",
  city: "Amman",
  post_code: "11623",
  state: "South Carolina",
  country: "Amman",
  business_operation: "Local",
  logo: "",
  profile_img: null,
  twoFactorSecret: "",
  spark_fees: null,
  activation: true,
  class: null,
  createdAt: "2024-07-13T17:09:25.000Z",
  updatedAt: "2024-07-13T17:09:25.000Z",
};

const InstallerConfig = () => {
  const params = useParams();
  const [installerFrameType, setInstallerFrameType] = useState("offers");

  return (
    <div className="p-6 pt-0">
      <BreadCrumb
        paths={[
          { name: "All Installers", pathLink: "/admin-dashboard/installers" },
          {
            name: "Installer Details",
            pathLink: `/admin-dashboard/installers/${params.userId}`,
          },
        ]}
      />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-4 px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <div className="flex justify-between">
            <div className="flex items-center gap-x-2">
              <img
                src="/defaultLogo.svg"
                alt=""
                className="w-16 h-16 rounded-full"
              />
              <h2 className="text-[#112532] text-[33px] font-semibold">
                Blue Raven
              </h2>
            </div>
            <div className="flex items-center gap-x-3">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="flex items-center gap-x-2"
                  // shrink={true}
                >
                  <img className="h-7 w-7" src="/none-class.svg" alt="" />
                  Select
                </InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Select"
                  value={"Platinum"}
                  renderValue={(selected) => {
                    const selectedItem = classOptions.find(
                      (item) => item.value === selected
                    );
                    return (
                      <div className="flex items-center gap-x-2">
                        {selectedItem?.icon}
                        <span className="text-[13px] font-medium">
                          {selectedItem?.label}
                        </span>
                      </div>
                    );
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  {classOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                label="Activation"
                labelPlacement="start"
                control={<IosSwitch sx={{ m: 1 }} checked={true} />}
              />
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#112532] text-xl md:text-[28px] font-bold">
                4.6
              </h1>
              <img loading="lazy" src="/star.svg" alt="star icon" />
            </div>
            <span className="text-[#5e514e] text-sm md:text-base">
              (2 Reviews)
            </span>
            <span className="px-3 md:px-4 py-1 bg-[#F0FAFB] border-1 border-[#D8F0F5] font-semibold text-sm md:text-base">
              Fast installation
            </span>
            <span className="px-3 md:px-4 py-1 bg-[#F0FAFB] border-1 border-[#D8F0F5] font-semibold text-sm md:text-base">
              Excellent Warranty
            </span>
          </div>
          <hr className="border-[#D9D3CF]" />
          <div className="space-y-2">
            <p className="flex gap-2 py-2 text-[#265D78] underline">
              <img loading="lazy" src="/Email.svg" alt="mail icon" />
              Jadekelsi@gmail.com
            </p>
            <p className="flex gap-2 py-2">
              <img loading="lazy" src="/Call.svg" alt="phone icon" />
              +15284988891
            </p>
            <p className="flex gap-2 py-2">
              <img loading="lazy" src="/Location.svg" alt="location icon" />
              127 Kay Dr, Simpsonville, SC 29681
            </p>
          </div>
        </div>
        <div className="">
          <div className="flex gap-x-5">
            <div
              className={`text-[#112532] cursor-pointer ${installerFrameType === "offers" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
              onClick={() => setInstallerFrameType("offers")}
            >
              Offers
            </div>
            <div
              className={`text-[#112532] cursor-pointer ${installerFrameType === "Deals" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
              onClick={() => setInstallerFrameType("Deals")}
            >
              Deals
            </div>
            <div
              className={`text-[#112532] cursor-pointer ${installerFrameType === "Portfolio" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
              onClick={() => setInstallerFrameType("Portfolio")}
            >
              Portfolio
            </div>
          </div>
          <hr className="border-[#D9D3CF]" />
        </div>
        <div className="flex flex-col gap-y-5 p-8 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <h1 className="text-[#112532] text-[23px] font-semibold">Offers</h1>
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[#272a2e] text-[15px] font-medium">
              Spark Fees ($/Watt)
            </h2>
            <div className="flex justify-start gap-x-3 w-1/2">
              <TextField
                fullWidth
                size="large"
                // sx={{
                //   backgroundColor: "white",
                //   "& .MuiOutlinedInput-root": {
                //     border: "none",
                //     "& fieldset": {
                //       border: "none",
                //     },
                //   },
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        loading="lazy"
                        className="cursor-pointer w-auto"
                        src={"/bi_currency-dollar.svg"}
                        alt="filter icon"
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder="Price/Watt"
              />
              <button className=" px-6 py-3 bg-[#112532] rounded text-white font-semibold">
                Apply
              </button>
            </div>
          </div>
          <EquipmentList />
        </div>
      </div>
    </div>
  );
};

export default InstallerConfig;
