import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadPortfolioImages } from "services/installerServices";
import BreadCrumb from "../shared/BreadCrumb";
import ReactDropZone from "../shared/ReactDropZone";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";

const AddImagePortfolio = () => {
  const [imagesArray, setImagesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleImageDelete = (index) => {
    const updatedImages = imagesArray.filter((_, i) => i !== index);
    setImagesArray(updatedImages);
  };

  const fetchData = async () => {
    const formData = new FormData();
    imagesArray.forEach((image) => {
      formData.append("img", image.file);
    });
    try {
      setIsLoading(true);
      const res = await uploadPortfolioImages(formData);
      setImagesArray([]);
      toast.success(res.message);
      navigate("/dashboard-installer/portfolio");
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="py-6 px-4 md:pl-8 flex flex-col gap-y-4 flex-1">
      <BreadCrumb
        paths={[
          { name: "My Portfolio", pathLink: "/dashboard-installer/portfolio" },
          {
            name: "Add Image",
            pathLink: "/dashboard-installer/portfolio/add-image",
          },
        ]}
        isShowConfirmationDialog={imagesArray.length > 0}
        title="Discard Changes?"
        subTitle="The image has not been saved. Returning will result in losing the changes."
      />
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => navigate("/dashboard-installer/portfolio")}
        title="Discard Changes?"
        subTitle="The image has not been saved. Returning will result in losing the changes."
      />

      <div className="flex flex-col lg:flex-row">
        <div className="py-6 px-4 md:px-8 flex flex-col flex-1 gap-y-8 border-1 border-[#E2E8F0]">
          <div className="flex flex-col gap-y-2">
            <h1 className="text-[24px] md:text-[28px] text-[#112532] font-semibold">
              Add New Images
            </h1>
            <p className="text-[#112532] text-[13px] md:text-[14px]">
              Add images from your projects to be viewed by the homeowner
            </p>
          </div>

          <p>Add Images of your project</p>

          <ReactDropZone
            variant="portfolio"
            setImagesArray={setImagesArray}
            currentImagesLength={imagesArray.length}
          />

          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {imagesArray.map((image, index) => (
              <div key={index} className="relative aspect-square">
                <img
                  loading="lazy"
                  src={image.preview}
                  alt={`preview-${index}`}
                  className="object-cover w-full h-full rounded"
                />
                <div className="absolute top-2 right-2 flex justify-center gap-x-3 p-2 bg-[#1C1B1F] rounded">
                  <button
                    onClick={() => handleImageDelete(index)}
                    className="hover:opacity-80 transition-opacity"
                  >
                    <img loading="lazy" src="/close.svg" alt="Delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Spacer div - hidden on mobile */}
        <div className="hidden lg:block w-1/4 h-1/4"></div>
      </div>

      <div className="py-6 flex flex-col sm:flex-row gap-4 sm:gap-x-2">
        <button
          disabled={imagesArray.length === 0}
          onClick={fetchData}
          className={`px-[18px] py-3 bg-[#112532] text-white rounded transition-opacity
            ${imagesArray.length === 0 ? "opacity-50 cursor-not-allowed" : "hover:opacity-90"}`}
        >
          {isLoading ? "Adding..." : "Add Images"}
        </button>
        <button
          onClick={() => {
            if (imagesArray.length > 0) {
              setIsDialogOpen(true);
            } else {
              navigate("/dashboard-installer/portfolio");
            }
          }}
          className="px-[18px] py-3 border-1 border-[#112532] text-[#112532] rounded hover:bg-gray-50 transition-colors"
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default AddImagePortfolio;
