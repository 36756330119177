import { createPortal } from "react-dom";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  subTitle,
  cancelText = "Cancel",
  confirmText = "Discard",
}) => {
  const dialogElement = document.getElementById("dialog");
  return createPortal(
    <>
      {open && (
        <>
          {/* Background Overlay */}
          <div className="w-full h-full bg-[#00000033] fixed top-0 left-0 z-[99999999999]"></div>

          {/* Dialog Box */}
          <div className="flex flex-col gap-y-4 w-[90%] sm:w-[400px] md:w-[600px] px-6 sm:px-12 md:px-16 pt-8 pb-6 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-[#F8FAFC] z-[99999999999] font-semibold rounded">
            {/* Title */}
            <h1 className="text-[#B91C1C] text-[16px] sm:text-[19px] text-center">
              {title}
            </h1>
            {/* Subtitle */}
            <p className="text-[#112532] text-[12px] sm:text-[14px] text-center">
              {subTitle}
            </p>
            {/* Buttons */}
            <div className="flex flex-col-reverse sm:flex-row justify-center gap-y-3 sm:gap-x-3 mt-2">
              <button
                onClick={onClose}
                className="px-8 sm:px-10 py-3 rounded border-1 border-[#112532]"
              >
                {cancelText}
              </button>
              <button
                onClick={onConfirm}
                className="text-[#F8FAFC] bg-[#B91C1C] px-8 sm:px-10 py-3 rounded"
              >
                {confirmText}
              </button>
            </div>
          </div>
        </>
      )}
    </>,
    dialogElement
  );
};

export default ConfirmationDialog;
