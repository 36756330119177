// import { ACCESS_TOKEN } from "utils/constant";
import ApiService from "./api";

const baseURL = "/installer";

export const installerRegister = async (data: object) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/register`,
    method: "POST",
    data,
  });
  return response;
};
export const installerLogIn = async (email: string, password: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/login`,
    method: "POST",
    data: {
      email,
      password,
    },
  });
  return response;
};

export const installerResetPassword = async (email: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/forgot-password`,
    method: "POST",
    data: {
      email,
    },
  });
  return response;
};

export const installerChangePassword = async (
  resetToken: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/reset-password`,
    method: "POST",
    data: {
      resetToken,
      newPassword,
    },
  });
  return response;
};

export const PATCHinstallerContractSign = async (id: string, data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/contract-sign/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GETinstallerContractInfo = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/contract-info/${id}`,
    method: "GET",
  });
  return response;
};

export const fetchHomeownerDealSummary = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/deals-summary`,
    method: "GET",
  });
  return response;
};

export const fetchAllDeals = async (filters: {
  system_type?: string;
  from_date?: string;
  to_date?: string;
  type?: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ ...filters }),
  });
  return response;
};

export const fetchHomeownerDealByID = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/overview/${id}`,
    method: "GET",
  });
  return response;
};

export const patchDealStatus = async (id: string, status: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/deals/update-deal-status/${id}`,
    method: "PATCH",
    data: { status },
  });
  return response;
};

export const getPortfolioList = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/portfolio/list`,
    method: "GET",
  });
  return response;
};

export const uploadPortfolioImages = async (formData: FormData) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/portfolio/multi-upload`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
  return response;
};

export const DELETEportfolioImageById = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/portfolio/image/${id}`,
    method: "DELETE",
  });
  return response;
};

export const getOffersList = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/list`,
    method: "GET",
  });
  return response;
};

export const GETavailableSystemTypes = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/available-system-types`,
    method: "GET",
  });
  return response;
};

export const putOfferUpdate = async (
  updatedOffer: {
    pricing_method: string;
    system_type: string;
    solar_panels: string;
    inverter: string;
    racking: string;
    battery: string;
    solar_panel_price: string;
    inverter_price: string;
    racking_price: string;
    battery_price: string;
    watt_price: string;
  },
  offerId: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/update/${offerId}`,
    method: "PUT",
    data: updatedOffer,
  });
  return response;
};

export const POSTnewOffer = async (updatedOffer: {
  pricing_method: string;
  system_type: string;
  solar_panels: string;
  inverter: string;
  racking: string;
  battery: string;
  solar_panel_price: string;
  inverter_price: string;
  racking_price: string;
  battery_price: string;
  watt_price: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/new`,
    method: "POST",
    data: updatedOffer,
  });
  return response;
};

export const GETgeneralInformationInstaller = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/general-information`,
    method: "GET",
  });
  return response;
};

export const DELETEofferById = async (offerId: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/delete/${offerId}`,
    method: "DELETE",
  });
  return response;
};

export const PUTinstallerPersonalDetailsUpdate = async (formData: {
  first_name: string;
  last_name: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/personal-details`,
    method: "PUT",
    data: formData,
  });
  return response;
};

export const GETinstallerPersonalDetails = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/personal-details`,
    method: "GET",
  });
  return response;
};

export const PUTbusinessDetailsUpdate = async (formData: FormData) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/business-details`,
    method: "PUT",
    data: formData,
  });
  return response;
};

export const GETbusinessDetails = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/business-details`,
    method: "GET",
  });
  return response;
};

export const GETavailableStates = async () => {
  const response = await ApiService.fetchData({
    url: `/home_owner/states`,
    method: "GET",
  });
  return response;
};

export const POSTchangeAuthenticatedPassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/change-password`,
    method: "POST",
    data: { currentPassword, newPassword },
  });
  return response;
};

export const PATCHinstallerUpdateProfilePic = async (formData: FormData) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/update-profile-img`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const DELETEinstallerProfilePic = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/delete-profile-img/profile`,
    method: "DELETE",
  });
  return response;
};

export const GETwinRateStatistics = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/statistics/win-rate-2`,
    method: "GET",
  });
  return response;
};

export const GETcontractLeadsStatistics = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/statistics/contract-leads`,
    method: "GET",
  });
  return response;
};

export const GETaverageSystemSizeStatistics = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/statistics/average-system-size`,
    method: "GET",
  });
  return response;
};

export const PUTreadAllNotificationsInstaller = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/notifications/update/all`,
    method: "PUT",
  });
  return response;
};

export const GETinstallerChatList = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/chat/all`,
    method: "GET",
  });
  return response;
};
