import { object, string } from "yup";

export const contactUsSchema = object({
  first_name: string()
    .required("First name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  last_name: string()
    .required("Last name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  mobile: string()
    .required("Phone Number is required!")
    .min(10, "The phone number must be at least 10 digits.")
    .max(10, "more than 10 digits are not allowed."),
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  subject: string()
    .required("Subject is required!")
    .matches(
      /^(?![^\w\s]+$)[^\d]+$/,
      "Special characters only are not allowed and numbers are not allowed."
    ),

  message: string().required("Message is required!"),
}).required();
