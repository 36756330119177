import { useDispatch } from "react-redux";
import { updateHomeownerForm } from "../../redux/actions";
import { formatPrice } from "utils/formatNumber";
import { getBadgeIcon } from "utils/constant";

const Offer = ({ offer, onSubmit, setValue }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-y-4 p-4 md:p-8 bg-white rounded-md border border-[#c7cace] min-w-[350px]">
      <div className="flex justify-between">
        <h1 className="text-[23px] font-semibold">
          {offer?.businessOperation}
        </h1>
        <div className="flex items-center gap-x-1">
          <img src={getBadgeIcon(offer?.badge)} alt={offer?.badge} />
          <span className="text-[13px]">{offer?.badge}</span>
        </div>
      </div>

      <div className="flex flex-col gap-y-3 md:gap-y-4">
        <div className="flex items-center gap-x-3">
          <h1 className="flex gap-x-2 items-center text-[#112532] text-[23px] font-bold">
            {offer?.stars} <img src="/star.svg" alt="rating" />
          </h1>
          <p className="text-[#287192] text-sm font-semibold underline">
            Installer Reviews
          </p>
        </div>

        <div className="flex gap-x-2 flex-wrap">
          {offer?.tags.map((tag, index) => (
            <div
              key={index}
              className="px-2 py-1 bg-[#f0fafb] rounded border border-[#d8f0f5] text-[13px] font-semibold"
            >
              {tag}
            </div>
          ))}
        </div>

        <div className="text-sm">
          {/* <p className="flex gap-x-2 items-center py-1">
            <img loading="lazy" src="/location_on.svg" alt="location" />
            {address}
          </p> */}
          <p className="flex gap-x-2 items-center">
            <img loading="lazy" src="/home_work.svg" alt="coverage" />
            Covers {offer?.state} area
          </p>
        </div>

        <p className="text-[#112532] text-[32px] font-bold">
          ${formatPrice(offer?.systemPrice)}
        </p>
        {/* <p className="text-[#244255] text-xs font-semibold">
          ${totalSavings.toLocaleString()} Total Savings (over {savingsYears}{" "}
          years)
        </p> */}
        <p className="text-[#244255] text-xs font-semibold">
          {offer?.description}
        </p>
      </div>

      <button
        onClick={() => {
          setValue("offer_id", offer?.offerId, { shouldValidate: true });
          onSubmit();
        }}
        className="text-center px-[18px] py-3 bg-[#112532] rounded-[5px] text-[#fffefb] font-bold"
      >
        Lock in This Deal
      </button>
    </div>
  );
};

export default Offer;
