import ApiService from "./api";

const baseURL = "/home_owner";

export const POSThomeOwnerTempUser = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/tempUser/new`,
    method: "POST",
    data,
  });
  return response;
};
export const PUTtempUserUpdate = async (id: string, data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/tempUser/update/${id}`,
    method: "PUT",
    data,
  });
  return response;
};

export const POSTpanelsWithoutObs = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/solar/check-panels-obstructions`,
    method: "POST",
    data,
  });
  return response;
};

export const POSTsolarInsights = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/solar-insights-v2`,
    method: "POST",
    data,
  });
  return response;
};

export const POSTavailableTiers = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/all-tiers-2`,
    method: "POST",
    data,
  });
  return response;
};

export const POSTavailableOffers = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/offers/all`,
    method: "POST",
    data,
  });
  return response;
};

export const POSThomeOwnerRegister = async (id: string, data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/tempUser/register/${id}`,
    method: "POST",
    data,
  });
  return response;
};

export const homeOwnerLogIn = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/login`,
    method: "POST",
    data: {
      email,
      password,
      rememberMe,
    },
  });
  return response;
};

export const homeOwnerResetPassword = async (email: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/forgot-password`,
    method: "POST",
    data: {
      email,
    },
  });
  return response;
};

export const GEThomeOwnerContractInfo = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/contract-info`,
    method: "GET",
  });
  return response;
};

export const PATCHhomeOwnerContractSign = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/contract-sign`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GETdashboardInfo = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/info`,
    method: "GET",
  });
  return response;
};

export const GETinstallerProfile = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/installer-profile`,
    method: "GET",
  });
  return response;
};

export const POSTcomment = async (review: {
  comment: string;
  stars: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/homeOwner-review`,
    method: "POST",
    data: review,
  });
  return response;
};

export const PUTcomment = async (review: {
  comment: string;
  stars: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/homeOwner-review`,
    method: "PUT",
    data: review,
  });
  return response;
};

export const DELETEcomment = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/delete-review`,
    method: "DELETE",
  });
  return response;
};

export const homeOwnerChangePassword = async (
  resetToken: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/reset-password`,
    method: "POST",
    data: {
      resetToken,
      newPassword,
    },
  });
  return response;
};

export const uploadSurveyImage = async (formData: FormData) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/survey/upload`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
  return response;
};

export const getSurveyQrCode = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/survey/generate-qr`,
    method: "GET",
  });
  return response;
};

export const getUploadedSurvey = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/survey/list`,
    method: "GET",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const GETgeneralInformationHomeowner = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/general-information`,
    method: "GET",
  });
  return response;
};

export const GEThomeOwnerPersonalDetails = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/info`,
    method: "GET",
  });
  return response;
};

export const PATCHhomeOwnerUpdateProfilePic = async (formData: FormData) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/update-profile-img`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const DELETEhomeownerProfilePic = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/delete-profile-img`,
    method: "DELETE",
  });
  return response;
};

export const PUThomeOwnerPersonalDetailsUpdate = async (formData: {
  first_name: string;
  last_name: string;
  mobile: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/info`,
    method: "PUT",
    data: formData,
  });
  return response;
};

export const POSTchangeHomeOwnerAuthenticatedPassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/change-password`,
    method: "POST",
    data: { currentPassword, newPassword },
  });
  return response;
};

export const GEThomeOwnerChatList = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/chat/all`,
    method: "GET",
  });
  return response;
};

export const PUTreadAllNotificationsHomeOwner = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/notifications/update/all`,
    method: "PUT",
  });
  return response;
};

export const POSTreferAfriend = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `/referral/invite`,
    method: "POST",
    data,
  });
  return response;
};

export const fetchHomeownerDealOverview = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/overview`,
    method: "GET",
  });
  return response;
};
