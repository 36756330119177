import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import useResponsive from "hooks/useResponsive";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const installersData = [
  { date: "2024-03-01", installers: 65 },
  { date: "2024-03-08", installers: 72 },
  { date: "2024-03-15", installers: 68 },
  { date: "2024-03-22", installers: 75 },
];

const allDeals = [
  { date: "2024-03-01", active: 45, closedWon: 30, closedLost: 10 },
  { date: "2024-03-08", active: 52, closedWon: 35, closedLost: 15 },
  { date: "2024-03-15", active: 48, closedWon: 32, closedLost: 5 },
  { date: "2024-03-22", active: 55, closedWon: 38, closedLost: 25 },
];

const topLocationsData = [
  { name: "San Francisco", value: 80 },
  { name: "Philadelphia", value: 70 },
  { name: "South Carolina", value: 60 },
  { name: "New York", value: 35 },
  { name: "New Jersey", value: 18 },
  { name: "Other", value: 18 },
];

const averageSystemSize = [
  { date: "2024-03-01", average_system_size: 0 },
  { date: "2024-03-08", average_system_size: 10 },
  { date: "2024-03-15", average_system_size: 20 },
  { date: "2024-03-22", average_system_size: 27 },
];

const commonXAxisConfig = {
  interval: 0,
  tickLine: false,
  axisLine: false,
  tick: {
    fill: "#1C1C1C66",
    fontSize: 12,
    textAnchor: "middle",
    angle: 0,
    dx: 0,
    dy: 5,
  },
  tickFormatter: (tick) => dayjs(tick).format("MMM D"),
};

const commonYAxisConfig = {
  axisLine: false,
  tickLine: false,
  tick: {
    fill: "#1C1C1C66",
    fontSize: 12,
    dy: -3,
  },
  tickMargin: 16,
};

const chartMargin = {
  left: -10,
  right: 10,
  top: 10,
  bottom: 20,
};

const InstallersCharts = () => {
  return (
    <>
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">28</span>
            <img src="/Admin-assets/tools.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Installers</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">19</span>
            <img src="/Admin-assets/closed-won.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Active Installers</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">83</span>
            <img src="/Admin-assets/shake.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Deals</h2>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        {/* Installers Line Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Installers</h3>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart data={installersData} margin={chartMargin}>
              <CartesianGrid vertical={false} />
              <CartesianGrid horizontal={false} strokeDasharray="3 3" />
              <XAxis {...commonXAxisConfig} dataKey="date" />
              <YAxis
                {...commonYAxisConfig}
                // tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="installers"
                stroke="#F59E0B"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* All Deals Bar Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">All Deals</h3>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={allDeals} margin={chartMargin}>
              <CartesianGrid vertical={false} />
              <XAxis {...commonXAxisConfig} dataKey="date" />
              <YAxis
                {...commonYAxisConfig}
                tickFormatter={(tick) => (tick !== 0 ? `${tick}K` : tick)}
              />
              <Tooltip />
              <Bar dataKey="active" fill="#F59E0B" barSize={25} />
              <Bar dataKey="closedWon" fill="#2AB065" barSize={25} />
              <Bar dataKey="closedLost" fill="#F27661" barSize={25} />
            </BarChart>
          </ResponsiveContainer>
          <div className="flex justify-center gap-x-5">
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#F59E0B]" /> Active
            </p>
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#2AB065]" /> Closed Won
            </p>
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#F27661]" /> Closed Lost
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const HomeownersCharts = () => {
  return (
    <>
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">28</span>
            <img src="/Admin-assets/homeowners.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Home Owners</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">19</span>
            <img src="/Admin-assets/contracts.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Contracts</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">83</span>
            <img src="/Admin-assets/leads.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Leads</h2>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        {/* Installers Line Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Top Locations</h3>
          <ResponsiveContainer>
            <BarChart
              data={topLocationsData}
              layout="vertical"
              margin={{ top: 20, right: 50, left: 0, bottom: 20 }}
            >
              <XAxis type="number" {...commonXAxisConfig} hide />
              <YAxis
                type="category"
                dataKey="name"
                width={130}
                {...commonYAxisConfig}
                tick={{
                  fill: "#112532",
                  fontSize: 14,
                  dy: -3,
                  fontWeight: 500,
                }}
              />
              <Tooltip />
              <Bar dataKey="value" fill="#FFA500" barSize={20}>
                <LabelList
                  dataKey="value"
                  position="right"
                  fill="#112532"
                  fontSize={14}
                  fontWeight={500}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* All Deals Bar Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Average System Size</h3>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={averageSystemSize} margin={chartMargin}>
              <CartesianGrid vertical={false} />
              <XAxis {...commonXAxisConfig} dataKey="date" />
              <YAxis
                {...commonYAxisConfig}
                tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
              />
              <Tooltip />
              <Bar dataKey="average_system_size" fill="#F59E0B" barSize={25} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

const AdminDashboard = () => {
  const [dashboardType, setDashboardType] = useState("installers");

  return (
    <div className="px-6 pb-6 flex flex-col gap-4 ">
      <div className="flex justify-between pt-4">
        <h1 className="text-black text-[28px] font-semibold ">Dashboard</h1>

        <div className="flex items-center gap-x-10">
          <div className="flex gap-x-3 items-center">
            <h1 className="mb-2 text-sm md:text-base">From</h1>
            <DatePicker
              value={null}
              onChange={(newValue) => {
                // your onChange logic
              }}
              maxDate={dayjs()}
              sx={{
                width: "200px", // or any specific width you want
                "& .MuiInputBase-root": {
                  height: "40px", // reduce height
                  fontSize: "14px", // smaller font
                },
                "& .MuiInputBase-input": {
                  padding: "8px 14px", // reduce padding
                },
              }}
            />
          </div>
          <div className="flex gap-x-3 items-center">
            <h1 className="mb-2 text-sm md:text-base">To</h1>
            <DatePicker
              // disabled={dateRadio !== "custom"}
              value={null}
              onChange={(newValue) => {
                // updateFilters({
                //   to_date: newValue ? newValue.format("YYYY-MM-DD") : "",
                // });
              }}
              sx={{
                width: "200px", // or any specific width you want
                "& .MuiInputBase-root": {
                  height: "40px", // reduce height
                  fontSize: "14px", // smaller font
                },
                "& .MuiInputBase-input": {
                  padding: "8px 14px", // reduce padding
                },
              }}
              minDate={null}
              maxDate={dayjs()}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-x-5">
        <div
          className={`text-[#112532] cursor-pointer ${dashboardType === "installers" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
          onClick={() => setDashboardType("installers")}
        >
          Installers
        </div>
        <div
          className={`text-[#112532] cursor-pointer ${dashboardType === "homeowners" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
          onClick={() => setDashboardType("homeowners")}
        >
          Homeowners
        </div>
      </div>
      {dashboardType === "installers" ? (
        <InstallersCharts />
      ) : (
        <HomeownersCharts />
      )}
    </div>
  );
};

export default AdminDashboard;
