import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import AuthenticatedHeader from "components/shared/AuthenticatedHeader";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const isAuth = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const navLinks = [
    { path: "/about", label: "About" },
    { path: "/why-go-solar", label: "Why Go Solar?" },
    { path: "/get-started", label: "Get Started" },
    { path: "/auth/login", label: "Homeowner Portal" },
    { path: "/auth/installer-login", label: "Installer Portal" },
    { path: "/contact-us", label: "Contact Us" },
  ];

  if (isAuth) {
    return <AuthenticatedHeader />;
  }

  return (
    <header className="w-full bg-white px-4 md:px-8 py-2 md:py-4 z-50 border-b border-[#f7f6f5] shadow top-0 relative">
      <div className="flex justify-between items-center">
        <Link to="/" className="z-50">
          <img
            src="/sparklogo.png"
            alt="Spark Logo"
            className="h-16 md:h-auto"
          />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden lg:block">
          <ul className="flex items-center gap-x-6">
            {navLinks.map((link, index) => (
              <li key={index}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    link.path === "/contact-us"
                      ? `px-3 py-2 hover:bg-amber-600 text-white font-semibold rounded ${
                          isActive ? "bg-amber-600" : "bg-[#112532]"
                        }`
                      : isActive
                        ? "text-amber-600 font-semibold"
                        : "text-[#112532] hover:text-amber-600"
                  }
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="lg:hidden z-50 text-[#112532]"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>

        {/* Mobile Navigation */}
        <div
          className={`fixed inset-0 bg-white transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out lg:hidden`}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <nav className="w-full">
              <ul className="flex flex-col items-center space-y-8">
                {navLinks.map((link, index) => (
                  <li key={index} className="w-full text-center">
                    <NavLink
                      to={link.path}
                      onClick={() => setIsOpen(false)}
                      className={({ isActive }) =>
                        link.path === "/contact-us"
                          ? `inline-block px-6 py-2 hover:bg-amber-600 text-white font-semibold rounded ${
                              isActive ? "bg-amber-600" : "bg-[#112532]"
                            }`
                          : isActive
                            ? "text-amber-600 font-semibold text-xl"
                            : "text-[#112532] hover:text-amber-600 text-xl"
                      }
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
