import Header from "components/shared/Header";

const SurveyGuide = ({ setShouldRenderGuide }) => {
  return (
    <>
      <div className="flex flex-col md:items-center gap-y-2 pb-5 pt-1 md:pt-10 text-[#112532]">
        <h1 className="font-semibold text-lg md:text-[28px]">Site Survey</h1>
        <p className="lg:w-2/4 md:w-3/4 md:text-center md:p-3 text-[13px]">
          Save up to $250 and speed up the process by a week when completing
          this site survey!
        </p>
      </div>
      <div className="flex-col md:flex-row items-center gap-5 flex justify-center gap-x-8 ">
        <div className="flex flex-col items-center text-center gap-y-1 w-52">
          <img loading="lazy" src="/indoorandoutdoor.svg" alt="" />
          <p className="text-[12px] pt-3 text-[#244255]">Step 1</p>
          <p className="text-[18px] font-semibold">Indoor and Outdoor</p>
          <p className="text-[12px]">
            Choose where do you want to start taking your pictures from; Indoor
            or Outdoor
          </p>
        </div>
        <div className="flex flex-col items-center text-center gap-y-1 w-52">
          <img loading="lazy" src="/takepics.svg" alt="" />
          <p className="text-[12px] pt-3 text-[#244255]">Step 2</p>
          <p className="text-[18px] font-semibold">Take the pictures</p>
          <p className="text-[12px]">
            Picture examples will help you determine the what is the tool
          </p>
        </div>
        <div className="flex flex-col items-center text-center gap-y-1 w-52">
          <img loading="lazy" src="/submitSurvey.svg" alt="" />
          <p className="text-[12px] pt-3 text-[#244255]">Step 3</p>
          <p className="text-[18px] font-semibold">Submit the survey</p>
          <p className="text-[12px]">
            After filling all the images you can submit them
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-5 px-3 py-3">
        <button
          className="py-3 px-4 bg-[#112532] text-white font-semibold rounded w-full md:w-auto"
          onClick={() => setShouldRenderGuide(false)}
        >
          Start Site Survey
        </button>
      </div>
    </>
  );
};

export default SurveyGuide;
