import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SuccessIcon from "../../assets/SuccessIcon";
import { setSurveyCompleted, setSurveyType } from "../../redux/actions";

export default function SurveyHasBeenSubmitted({
  both,
  setShowSubmitted,
  showSubmitted,
}) {
  const dispatch = useDispatch();
  const currentSurveyType = useSelector(
    (state) => state.siteSurvey.currentSurveyType
  );

  const handleClose = () => {
    setShowSubmitted(false);
    if (currentSurveyType === 0) {
      dispatch(setSurveyCompleted("indoor", false));
    } else {
      dispatch(setSurveyCompleted("outdoor", false));
    }
  };

  const modalStyle =
    "flex flex-col items-center justify-center gap-3 text-center bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-4 w-11/12 sm:w-5/6 md:w-2/6";

  if (both) {
    return (
      <div>
        <Modal
          open={showSubmitted}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiBackdrop-root": {
              background: "rgba(17, 37, 50, 0.5)",
            },
          }}
        >
          <div className={`${modalStyle} md:h-[290.86px]`}>
            <img
              loading="lazy"
              className="absolute top-0 right-0 pr-3 pt-3 cursor-pointer"
              src="/close-survey.svg"
              alt=""
              onClick={handleClose}
            />
            <div>
              <img
                loading="lazy"
                className="max-w-full"
                src="/dropZone.svg"
                alt="drop zone"
              />
            </div>
            <div className="flex flex-col gap-y-3">
              <h1 className="font-semibold text-[16px] md:text-[19px] text-[#112532]">
                Your Survey has been Submitted!
              </h1>
              <p className="text-sm text-[#112532]">
                You can still edit your site survey through the dashboard.
              </p>
            </div>
            <Link to="/dashboard" className="w-full">
              <button className="text-center text-slate-50 text-sm font-bold bg-[#112532] p-2 w-full md:w-auto">
                Go to Dashboard
              </button>
            </Link>
          </div>
        </Modal>
      </div>
    );
  } else if (!both) {
    return (
      <div>
        <Modal
          open={showSubmitted}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiBackdrop-root": {
              background: "rgba(17, 37, 50, 0.5)",
            },
          }}
        >
          <div className={`${modalStyle} md:h-[250.86px]`}>
            <img
              loading="lazy"
              className="absolute top-0 right-0 pr-3 pt-3 cursor-pointer"
              src="/close-survey.svg"
              alt=""
              onClick={handleClose}
            />
            <div>
              <SuccessIcon />
            </div>
            <div className="flex flex-col gap-y-3">
              <h1 className="font-semibold text-[16px] md:text-[19px] text-[#112532]">
                Submitted Successfully!
              </h1>
              <p className="text-sm text-[#112532]">
                Your indoor photos have been submitted successfully.
              </p>
            </div>
            <div className="flex flex-col-reverse md:flex-row justify-center gap-3 w-full">
              <Link to="/dashboard">
                <button className="text-center text-[#112532] text-sm font-bold p-2 border-1 border-[#112532] w-full md:w-auto rounded-[5px]">
                  Go back to dashboard
                </button>
              </Link>
              <button
                onClick={() => {
                  if (currentSurveyType === 0) {
                    dispatch(setSurveyType(1));
                  } else {
                    dispatch(setSurveyType(0));
                  }
                }}
                className="text-center text-slate-50 text-sm font-bold bg-[#112532] p-[9px] w-full md:w-auto rounded-[5px]"
              >
                {`Go to ${currentSurveyType === 0 ? "outdoor" : "indoor"} photos`}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  return null;
}
