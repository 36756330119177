import chatListReducer from "./chatListReducer";
import generalInformationReducer from "./generalInformationsReducer";
import homeownerRegisterReducer from "./homeownerRegisterReducer";
import installerRegisterReducer from "./installerRegisterReducer";
import siteSurveyReducer from "./siteSurveyReducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    siteSurvey: siteSurveyReducer, // key for the siteSurveyReducer
    homeownerForm: homeownerRegisterReducer,
    installerForm: installerRegisterReducer,
    generalInformation: generalInformationReducer,
    chatList: chatListReducer,
  },
});

export default store;
