import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.000976562"
        width="40"
        height="40"
        rx="20"
        fill="#D97706"
      />
      <path
        d="M18.2774 23.985L14.6185 20.3261L13.0474 21.8972L18.2774 27.1272L29.0629 16.3416L27.4918 14.7705L18.2774 23.985Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessIcon;
