import { bool, object, ref, string } from "yup";

export const authenticatedChangePasswordSchema = object({
  currentPassword: string().required("Current password is required!"),
  newPassword: string()
    .required("New password is required!")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: string()
    .required("Please confirm your password")
    .oneOf(
      [ref("newPassword")],
      "New password and Confirm New Password must match"
    ),
}).required();

export const contractFormSchema = object({
  signature: string().required("Full name is required"),
}).required();
