import { useDispatch, useSelector } from "react-redux";
import { setSurveyType } from "../../redux/actions"; // Adjust the import path as necessary

export default function IndoorAndOutDoorTabs() {
  const dispatch = useDispatch();
  const currentSurveyType = useSelector(
    (state) => state.siteSurvey.currentSurveyType
  );

  return (
    <div className="flex flex-col lg:flex-row justify-center gap-y-4 lg:gap-x-5">
      {/* Indoor Tab */}
      <div
        className={`flex items-end justify-between p-5 shadow rounded w-full lg:w-1/5 ${
          currentSurveyType === 0
            ? "border-2 border-[#F3B33E] bg-[#F9F9F9]"
            : "border border-[#D9D3CF] bg-white"
        }`}
        onClick={() => dispatch(setSurveyType(0))}
      >
        <div className="flex flex-col gap-y-2 items-start">
          <img
            loading="lazy"
            className="w-8 sm:w-10 lg:w-auto"
            src="/indoorIcon.svg"
            alt="Indoor Icon"
          />
          <h2 className="text-[14px] sm:text-[16px] lg:my-2 text-[#112532]">
            Indoor Photos
          </h2>
        </div>
        <p className="text-[#244255] text-[10px] sm:text-[12px] lg:text-[16px]">
          <span className="font-semibold">4</span> Photos
        </p>
      </div>

      {/* Outdoor Tab */}
      <div
        className={`flex items-end justify-between p-5 shadow rounded w-full lg:w-1/5 ${
          currentSurveyType === 1
            ? "border-2 border-[#F3B33E] bg-[#F9F9F9]"
            : "border border-[#D9D3CF] bg-white"
        }`}
        onClick={() => dispatch(setSurveyType(1))}
      >
        <div className="flex flex-col gap-y-2 items-start">
          <img
            loading="lazy"
            className="w-8 sm:w-10 lg:w-auto"
            src="/outdoorIcon.svg"
            alt="Outdoor Icon"
          />
          <h2 className="text-[14px] sm:text-[16px] lg:my-2 text-[#112532]">
            Outdoor Photos
          </h2>
        </div>
        <p className="text-[#244255] text-[10px] sm:text-[12px] lg:text-[16px]">
          <span className="font-semibold">7</span> Photos
        </p>
      </div>
    </div>
  );
}
