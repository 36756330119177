import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "components/shared/Header";

const HomeLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isGetStartedPage = location.pathname === "/get-started";

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate(
        localStorage.getItem("USER") === "installer"
          ? "/dashboard-installer"
          : localStorage.getItem("USER") === "homeOwner"
            ? "/dashboard"
            : localStorage.getItem("USER") === "admin"
              ? "/admin-dashboard"
              : "/"
      );
    }
  }, []);
  return (
    <>
      {!isGetStartedPage && <Header />}
      <Outlet />
    </>
  );
};

export default HomeLayout;
