import { object, ref, string } from "yup";

export const validationSchemaRegisterStep1 = object().shape({
  first_name: string()
    .required("First Name is required")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  last_name: string()
    .required("Last Name is required")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  password: string()
    .required("Password is required")
    .min(8, "")
    .matches(/[a-z]/, "")
    .matches(/[A-Z]/, "")
    .matches(/[0-9]/, "")
    .matches(/[@$!%*?&#]/, ""),
  confirmPassword: string()
    .required("Please confirm your password")
    .oneOf([ref("password"), null], "Passwords must match"),
});

export const validationSchemaRegisterStep2 = object({
  business_name: string()
    .required("Business name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  mobile: string()
    .required("Phone number is required!")
    .length(10, "Phone number must be exactly 10 digits"),
  // country: string().required("Country is required!"),
  state: string().required("State is required!"),
  city: string()
    .required("City is required!")
    .matches(/^[A-Za-z]+$/, "Symbols, numbers, are not allowed."),
  post_code: string().required("Post code is required!"),
  business_operation: string().required("Business operation is required!"),
}).required();

// Define the Yup schema with conditional validation and string-based requirements
export const systemTierSchema = object({
  system_type: string().required("system type is required"),
  pricing_method: string().required("Pricing method is required"),

  // Require watt_price only when pricing_method is "watt"
  watt_price: string().when("pricing_method", {
    is: "watt",
    then: (schema) => schema.required("Watt price is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  battery: string().required("Battery selection is required"),
  battery_price: string().required("Battery price is required"),

  // Conditionally require solar panel fields when pricing_method is "material"
  solar_panel_price: string().when("pricing_method", {
    is: "material",
    then: (schema) => schema.required("Solar panel price is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  inverter_price: string().when("pricing_method", {
    is: "material",
    then: (schema) => schema.required("Inverter price is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  racking_price: string().when("pricing_method", {
    is: "material",
    then: (schema) => schema.required("Racking price is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  solar_panels: string().required("Solar panel selection is required"),

  inverter: string().required("Inverter selection is required"),

  racking: string().required("Racking selection is required"),
}).required();

export const installerPersonalDetailsSchema = object({
  first_name: string()
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed.")
    .required("First name is required!"),
  last_name: string()
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed.")
    .required("Last name is required!"),
}).required();

export const businessDetailsSchema = object({
  business_name: string()
    .required("Business name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  mobile: string()
    .required("Phone Number is required!")
    .min(10, "The phone number must be at least 10 digits.")
    .max(10, "more than 10 digits are not allowed."),
  country: string().required("Country is required!"),
  state: string().required("State is required!"),
  city: string()
    .required("City is required!")
    .matches(/^[A-Za-z]+$/, "Symbols, numbers, are not allowed."),
  post_code: string().required("Post code is required!"),
}).required();
