import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ bgColor }) => {
  return (
    <div
      className="relative pt-24 md:pt-[196px]"
      style={{ backgroundColor: bgColor || "#f3f4f4" }}
    >
      {/* CTA Section */}
      <div className="flex justify-center w-full absolute top-[10px] md:top-[85px]">
        <div className="flex flex-col md:flex-row items-center w-[90%] md:h-[148px] bg-gradient-to-r from-white to-[#ffecc4] rounded-xl shadow border border-[#ededeb] relative overflow-hidden p-6 md:p-0">
          <img
            loading="lazy"
            className="absolute bottom-[-25px] right-[-10px]"
            src="/opacity-diamonds.svg"
            alt=""
          />

          {/* Logo */}
          <div className="w-full md:w-2/12 flex justify-center md:justify-start md:ml-10 mb-4 md:mb-0">
            <img
              loading="lazy"
              className="w-24 md:w-4/6 h-auto"
              src="/logo.svg"
              alt=""
            />
          </div>

          {/* CTA Content */}
          <div className="flex flex-col items-center w-full text-center md:absolute md:top-1/2 md:left-1/2 md:translate-x-[-50%] md:translate-y-[-50%] gap-y-4 md:gap-y-5">
            <h1 className="text-[#112532] text-xl md:text-[28px] font-semibold">
              Get started with <span className="text-amber-600">Spark!</span>
            </h1>
            <Link
              to={"/auth/register"}
              className="bg-[#112532] px-4 py-2 md:px-[18px] md:py-3 text-[#fffefb] font-bold rounded-[5px] hover:bg-amber-600"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>

      {/* Footer Navigation */}
      <div className="flex flex-col gap-y-8 px-6 md:px-16 pt-36 md:pt-20 pb-12 bg-[#001a31]">
        <ul className="flex flex-col md:flex-row justify-center items-center gap-x-6 gap-y-4 text-white">
          <li className="md:inline-block">
            <Link className="hover:text-amber-600" to={"/about"}>
              About
            </Link>
          </li>
          <li className="md:inline-block">
            <Link className="hover:text-amber-600" to={"/why-go-solar"}>
              Why Go Solar?
            </Link>
          </li>
          <li className="md:inline-block">
            <Link className="hover:text-amber-600" to={"/dashboard"}>
              Get Started
            </Link>
          </li>
          <li className="md:inline-block">
            <Link className="hover:text-amber-600" to={"/auth/login"}>
              Homeowner Portal
            </Link>
          </li>
          <li className="md:inline-block">
            <Link className="hover:text-amber-600" to={"/auth/installer-login"}>
              Installer Portal
            </Link>
          </li>
        </ul>
        <hr className="border-[#B2B3C0]" />
        <div className="flex justify-center text-white opacity-75">
          <p>© 2024 Spark Solar. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
