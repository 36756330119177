import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SuccessIcon from "assets/SuccessIcon";

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  alignItems: "center",
  "& .MuiStepLabel-label": {
    fontSize: "10px", // Base font size
    fontWeight: "normal", // Default font weight
    color: "#6B7280", // Default color
    "&.Mui-active": {
      fontWeight: "bold", // Bold for active step
      color: "black", // Custom color for active
    },
    "&.Mui-completed": {
      fontWeight: "600", // Semi-bold for completed step
      color: "black", // Custom color for completed
    },
  },
}));

const StyledStep = styled(Step)(({ orientation }) => ({
  ...(orientation === "vertical"
    ? {
        marginBottom: "80px", // Adjust vertical step spacing
      }
    : {
        // marginRight: "48px", // Adjust horizontal step spacing
      }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme, orientation }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    ...(orientation === "vertical"
      ? {
          top: -91,
          left: "-5%",
          width: 3,
        }
      : {
          top: 11,
          left: "-50%",
          width: "100%",
        }),
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#D0680F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#D0680F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: orientation === "vertical" ? 100 : 3,
    border: 0,
    backgroundColor: "#C0B8B1",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#fff",
  zIndex: 1,
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid  #C0B8B1",
  position: "relative", // To ensure the pseudo-elements are positioned properly
  "&::before": {
    content: '""',
    position: "absolute",
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "#C0B8B1", // Change this color as per your design needs
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the circle
    zIndex: 0,
  },
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        // Uncomment and edit styles if needed
        borderColor: "#D0680F",
        "&::before": {
          backgroundColor: "#D0680F", // Change this color as per your design needs
        },
        // backgroundImage:
        //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        // Uncomment and edit styles if needed
        border: "0px solid  #C0B8B1",
        "&::before": {
          content: '""',
          position: "absolute",
          width: 0,
          height: 0,
          borderRadius: "50%",
          backgroundColor: "#C0B8B1", // Change this color as per your design needs
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the circle
          zIndex: -1,
        },
        // backgroundImage:
        //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SuccessIcon />,
    // 2: <GroupAddIcon />,
    // 3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed && <SuccessIcon />}
    </ColorlibStepIconRoot>
  );
}
//icons[String(props.icon)]
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function CustomStepper({
  steps,
  width,
  activeStepSolar,
  orientation,
}) {
  return (
    <Stack
      sx={{
        width: width || "50%",
        height: orientation === "vertical" ? "210px" : "auto", // Set dynamic height
        marginX: "auto",
        marginLeft: "-24",
        marginY: orientation === "vertical" ? "0" : "24px",
      }}
      spacing={4}
    >
      <Stepper
        orientation={orientation || "horizontal"}
        alternativeLabel
        activeStep={activeStepSolar}
        connector={<ColorlibConnector orientation={orientation} />}
      >
        {steps.map((label, index) => (
          <StyledStep key={index} orientation={orientation}>
            <StyledStepLabel StepIconComponent={ColorlibStepIcon}>
              {label}
            </StyledStepLabel>
          </StyledStep>
        ))}
      </Stepper>
    </Stack>
  );
}
