import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import {
  GETaverageSystemSizeStatistics,
  GETcontractLeadsStatistics,
  GETwinRateStatistics,
} from "services/installerServices";
import { CustomizeTabs } from "components/MUI/CustomizeTabs";
import { useOutletContext } from "react-router-dom";
import useResponsive from "hooks/useResponsive";
import { formatPrice } from "utils/formatNumber";

const Dashboard = () => {
  const dashboardSidebar = useOutletContext();
  const [winRateData, setWinRateData] = useState([]);
  const [contractAndLeadData, setContractAndLeadData] = useState([]);
  const [averageSystemSizeData, setAverageSystemSizeData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [filterBy, setFilterBy] = useState({
    winRate: "oneMonth",
    contractAndLead: "currentMonth",
    averageSystemSize: "oneMonth",
  });

  const areAllObjectsEmpty = (obj) => {
    // Iterate through each key in the object
    return Object.keys(obj).every((key) => {
      // Check if the array is empty or every object in the array is empty
      return (
        obj[key].length === 0 ||
        obj[key].every((item) => Object.keys(item).length === 0)
      );
    });
  };

  const { isSm } = useResponsive();

  const currentDate = dayjs().format("MMM DD");
  const getPreviousMonthName = (num) => {
    // Subtract `num` months from the current date
    const previousMonth = dayjs().subtract(num, "month").format("MMM DD");
    return previousMonth;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async () => {
    try {
      const winRateRes = await GETwinRateStatistics();
      const contractAndLeadRes = await GETcontractLeadsStatistics();
      const averageSystemSizeRes = await GETaverageSystemSizeStatistics();
      setWinRateData(winRateRes);
      setContractAndLeadData(contractAndLeadRes);
      setAverageSystemSizeData(averageSystemSizeRes);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Common chart configurations
  const getCommonXAxisConfig = (dataKey, dateFormat) => ({
    interval: 0,
    dataKey,
    tickLine: false,
    axisLine: false,
    tick: {
      fill: "#1C1C1C66",
      fontSize: isSm ? 10 : 12,
      textAnchor: "middle",
      angle: isSm ? -45 : 0,
      dx: isSm ? -10 : 0,
      dy: isSm ? 10 : 5,
    },
    tickFormatter: (tick) =>
      dayjs(tick).format(dateFormat === "full" ? "MMM D" : "MMM"),
  });

  const commonYAxisConfig = {
    axisLine: false,
    tickLine: false,
    tick: {
      fill: "#1C1C1C66",
      fontSize: isSm ? 10 : 12,
      dy: -3,
    },
    tickMargin: 16,
  };

  const chartMargin = {
    left: 10,
    right: 10,
    top: 10,
    bottom: isSm ? 40 : 20,
  };

  const tabs = [
    {
      label: "1 Month",
      value: 0,
      sx: { color: "#fff", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "First tab",
      onClick: () =>
        setFilterBy({
          winRate: "oneMonth",
          contractAndLead: "currentMonth",
          averageSystemSize: "oneMonth",
        }),
    },
    {
      label: "3 Months",
      value: 1,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Second tab",
      onClick: () =>
        setFilterBy({
          winRate: "threeMonths",
          contractAndLead: "threeMonths",
          averageSystemSize: "threeMonths",
        }),
    },
    {
      label: "6 Month",
      value: 2,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Third tab",
      onClick: () =>
        setFilterBy({
          winRate: "sixMonths",
          contractAndLead: "sixMonths",
          averageSystemSize: "sixMonths",
        }),
    },
    {
      label: "1 Year",
      value: 3,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Fourth tab",
      onClick: () =>
        setFilterBy({
          winRate: "oneYear",
          contractAndLead: "oneYear",
          averageSystemSize: "oneYear",
        }),
    },
  ];

  return (
    <div className="flex flex-col gap-y-8 px-4 md:px-8 flex-1 pb-6">
      {/* Header Section */}
      <div className="flex items-center gap-x-2 p-6 pl-0">
        <div className="lg:hidden">{dashboardSidebar}</div>
        <h1 className="text-[23px] md:text-[28px] font-semibold">Dashboard</h1>
      </div>

      {/* Tabs Section */}
      <div className="w-full">
        <CustomizeTabs
          value={tabValue}
          onChange={handleTabChange}
          tabs={tabs}
          tabsWidth="100%"
        />
      </div>

      {/* Charts Section */}
      <div className="flex flex-col items-center gap-y-6 w-full">
        {/* Win Rate Chart */}
        <div className="p-4 md:p-6 border border-[#ECEAE8] bg-[#FFFEFB] w-full rounded-lg">
          <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
            Win Rate
          </h1>
          <div className="h-[300px] w-full">
            {areAllObjectsEmpty(averageSystemSizeData) ? (
              <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                <img src="/empty-win-rate.png" alt="" />
                <p className="text-[#112532] text-sm">No win rate</p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={winRateData[filterBy.winRate]}
                  margin={chartMargin}
                >
                  <CartesianGrid vertical={false} />
                  <CartesianGrid horizontal={false} strokeDasharray="3 3" />
                  <XAxis
                    {...getCommonXAxisConfig(
                      (s) =>
                        filterBy.winRate === "oneMonth" ||
                        filterBy.winRate === "threeMonths"
                          ? s.start_of_week
                          : s.month,
                      "full"
                    )}
                  />
                  <YAxis
                    {...commonYAxisConfig}
                    tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
                  />
                  <Tooltip
                    formatter={(value, name) => [
                      value,
                      name
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase()),
                    ]}
                  />
                  <Line
                    type="monotone"
                    dataKey="total_deals"
                    stroke="#F59E0B"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        {/* Bottom Charts Container */}
        <div className="flex flex-col lg:flex-row gap-6 w-full">
          {/* My Deals Chart */}
          <div className="border border-[#ECEAE8] bg-[#FFFEFB] w-full p-4 md:p-5 rounded-lg">
            <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
              My Deals
            </h1>
            <div className="h-[300px] w-full ">
              {areAllObjectsEmpty(averageSystemSizeData) ? (
                <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                  <img src="/empty-bar-chart.png" alt="" />
                  <p className="text-[#112532] text-sm">No deals</p>
                </div>
              ) : contractAndLeadData[filterBy.contractAndLead]?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={contractAndLeadData[filterBy.contractAndLead]}
                    margin={chartMargin}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      {...getCommonXAxisConfig(
                        filterBy.contractAndLead !== "currentMonth"
                          ? "month"
                          : "date",
                        "full"
                      )}
                    />
                    <YAxis {...commonYAxisConfig} />
                    <Tooltip />
                    <Bar
                      dataKey="leads"
                      fill="#F59E0B"
                      barSize={isSm ? 20 : 30}
                    />
                    <Bar
                      dataKey="contracts"
                      fill="#244255"
                      barSize={isSm ? 30 : 45}
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div className="h-full flex flex-col justify-between relative">
                  <div className=" flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">
                      100K
                    </span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">75K</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">50K</span>
                    <div className="w-full h-[0.5px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">25K</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">0K</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[15%]">
                    <div className="flex items-center gap-x-0.5 sm:gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                      <div className="w-8 sm:w-12 h-0.5 sm:h-1 bg-[#244255]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(3)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[35%]">
                    <div className="flex items-center gap-x-0.5 sm:gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                      <div className="w-8 sm:w-12 h-0.5 sm:h-1 bg-[#244255]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(2)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[55%]">
                    <div className="flex items-center gap-x-0.5 sm:gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                      <div className="w-8 sm:w-12 h-0.5 sm:h-1 bg-[#244255]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(1)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[75%]">
                    <div className="flex items-center gap-x-0.5 sm:gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                      <div className="w-8 sm:w-12 h-0.5 sm:h-1 bg-[#244255]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(0)}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-center gap-x-5 mt-6">
              <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
                <span className="w-[14px] h-[7px] bg-[#F59E0B]" /> Leads
              </p>
              <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
                <span className="w-[14px] h-[7px] bg-[#244255]" /> Contracts
              </p>
            </div>
          </div>

          {/* Average System Size Chart */}
          <div className="border border-[#ECEAE8] bg-[#FFFEFB] w-full p-4 md:p-5 rounded-lg">
            <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
              Average System Size
            </h1>
            <div className="h-[300px] w-full">
              {areAllObjectsEmpty(averageSystemSizeData) ? (
                <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                  <img src="/empty-bar-chart.png" alt="" />
                  <p className="text-[#112532] text-sm">
                    No average system size
                  </p>
                </div>
              ) : averageSystemSizeData[filterBy.averageSystemSize]?.length >
                0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={averageSystemSizeData[filterBy.averageSystemSize]}
                    margin={chartMargin}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      {...getCommonXAxisConfig(
                        filterBy.averageSystemSize !== "oneMonth"
                          ? "month"
                          : "date",
                        "full"
                      )}
                    />
                    <YAxis
                      {...commonYAxisConfig}
                      tickFormatter={(tick) =>
                        tick !== 0 ? `${tick}kW` : tick
                      }
                    />
                    <Tooltip
                      formatter={(value, name) => [
                        formatPrice(value) + "kW",
                        name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase()),
                      ]}
                    />
                    <Bar
                      dataKey="average_system_size"
                      fill="#F59E0B"
                      barSize={isSm ? 30 : 45}
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div className="h-full flex flex-col justify-between relative">
                  <div className=" flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">
                      100%
                    </span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">75%</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">50%</span>
                    <div className="w-full h-[0.5px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">25%</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <span className="text-[#1c1c1c]/40 text-[9.28px]">0%</span>
                    <div className="w-full h-[1px] bg-[#ECEAE8]" />
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[15%]">
                    <div className="flex items-center gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(3)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[35%]">
                    <div className="flex items-center gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(2)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[55%]">
                    <div className="flex items-center gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(1)}
                    </p>
                  </div>
                  <div className="flex flex-col absolute -bottom-[10px] md:-bottom-3 gap-y-2 left-[75%]">
                    <div className="flex items-center gap-x-1">
                      <div className="w-4 sm:w-6 h-0.5 sm:h-1 bg-[#F59E0B]" />
                    </div>
                    <p className="text-center text-[#112532] text-[6px] sm:text-[8px] whitespace-nowrap">
                      {getPreviousMonthName(0)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
