export const setIndoorStep = (step) => ({
  type: "SET_INDOOR_STEP",
  payload: step,
});

export const setOutdoorStep = (step) => ({
  type: "SET_OUTDOOR_STEP",
  payload: step,
});

export const setSurveyCompleted = (surveyType, completed = true) => ({
  type: "SET_SURVEY_COMPLETED",
  payload: { surveyType, completed },
});

export const setSurveyType = (surveyType) => ({
  type: "SET_SURVEY_TYPE",
  payload: surveyType,
});

// installer Form step actions
export const setFormStep1 = (data) => ({
  type: "SET_FORM_STEP_1",
  payload: data,
});

export const setFormStep2 = (data) => ({
  type: "SET_FORM_STEP_2",
  payload: data,
});

export const goBackStep = () => ({
  type: "GO_BACK_STEP",
});

export const resetForm = () => ({
  type: "RESET_FORM",
});

// homeowner form steps

export const updateHomeownerForm = (payload) => ({
  type: "UPDATE_HOMEOWNER_FORM",
  payload,
});

export const proceedToNextHomeownerFormStep = (formData) => ({
  type: "SET_HOMEOWNER_FORM_NEXT_STEP",
  payload: formData,
});

export const goHomeownerBackStep = () => ({
  type: "GO_HOMEOWNER_BACK_STEP",
});

export const resetHomeownerForm = () => ({
  type: "RESET_HOMEOWNER_FORM",
});

export const updateGeneralInformation = (generalInfo) => ({
  type: "UPDATE_GENERAL_INFORMATION",
  payload: generalInfo,
});

// Chat List
export const setChatList = (chatList) => ({
  type: "SET_CHAT_LIST",
  payload: chatList,
});
