import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Controller } from "react-hook-form";

const PersonalDetailsRegisterForm = ({ errors, control, watch }) => {
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });
  const password = watch("password");

  const isPasswordValid =
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /\d/.test(password) &&
    /[@$!%*?&#]/.test(password);

  return (
    <div className="flex flex-col gap-y-6 md:gap-y-8">
      {/* Name Fields Container */}
      <div className="flex flex-col md:flex-row gap-4">
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "fit-content",
              }}
              {...field}
              error={errors.first_name && true}
              helperText={errors.first_name && errors.first_name.message}
              label="First Name"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "fit-content",
              }}
              {...field}
              error={errors.last_name && true}
              helperText={errors.last_name && errors.last_name.message}
              label="Last Name"
              variant="outlined"
            />
          )}
        />
      </div>

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
            {...field}
            error={errors.email && true}
            helperText={errors.email && errors.email.message}
            label="Email"
            variant="outlined"
            fullWidth
          />
        )}
      />

      {/* Password Field with Requirements */}
      <div className="relative">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              error={errors.password && true}
              {...field}
              helperText={
                errors?.password?.type === "required" && errors.password.message
              }
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
              }}
              className="w-full"
              label="Password"
              type={showPassword.new ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword.new ? (
                      <VisibilityOffIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            new: false,
                          });
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({ ...showPassword, new: true });
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {/* Password Requirements Popup */}
        {!isPasswordValid && password !== "" && (
          <>
            <div
              className="flex flex-col gap-y-3 p-3 absolute bg-[#f1f6f6] rounded-md border border-[#ececee] text-sm
                          left-0 right-0 mt-2 md:left-auto md:right-[-320px] top-20 md:top-[-20px] md:mt-0 
                          w-full md:w-[269px] h-auto md:h-[232px] z-50"
            >
              <h1>Your password must contain:</h1>
              <p
                className={
                  password.length >= 8
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    password.length >= 8
                      ? "visible w-6 h-6"
                      : "invisible w-6 h-6"
                  }
                  src="/bx-check.svg"
                  alt=""
                />{" "}
                At least 8 characters
              </p>
              <p
                className={
                  /[a-z]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    /[a-z]/.test(password)
                      ? "visible w-6 h-6"
                      : "invisible w-6 h-6"
                  }
                  src="/bx-check.svg"
                  alt=""
                />
                At least one lowercase letter
              </p>
              <p
                className={
                  /[A-Z]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    /[A-Z]/.test(password)
                      ? "visible w-6 h-6"
                      : "invisible w-6 h-6"
                  }
                  src="/bx-check.svg"
                  alt=""
                />
                At least one uppercase letter
              </p>
              <p
                className={
                  /\d/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    /\d/.test(password)
                      ? "visible w-6 h-6"
                      : "invisible w-6 h-6"
                  }
                  src="/bx-check.svg"
                  alt=""
                />
                At least one number
              </p>
              <p
                className={
                  /[@$!%*?&#]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    /[@$!%*?&#]/.test(password)
                      ? "visible w-6 h-6"
                      : "invisible w-6 h-6"
                  }
                  src="/bx-check.svg"
                  alt=""
                />
                At least one special character
              </p>
            </div>
            {/* Arrow for password requirements */}
            <div
              className="hidden md:block w-0 h-0 absolute right-[-52px] top-[10px] 
                          border-t-[20px] border-b-[20px] border-r-[30px] 
                          border-transparent border-r-[#f1f6f6]"
            ></div>
            <div
              className="md:hidden block w-0 h-0 absolute left-0 top-14
             border-l-[44px] border-r-[44px] border-b-[35px] 
             border-transparent border-b-[#f1f6f6] z-50"
            ></div>
          </>
        )}
      </div>

      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <TextField
            error={errors.confirmPassword && true}
            {...field}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
            className="w-full"
            label="Confirm Password"
            type={showPassword.confirm ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword.confirm ? (
                    <VisibilityOffIcon
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          confirm: false,
                        });
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          confirm: true,
                        });
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default PersonalDetailsRegisterForm;
