import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GETadminPersonalDetails,
  PATCHadminPersonalDetailsUpdate,
} from "services/AdminServices";
import {
  GEThomeOwnerPersonalDetails,
  PUThomeOwnerPersonalDetailsUpdate,
} from "services/homeOwnerServices";
import {
  GETinstallerPersonalDetails,
  PUTinstallerPersonalDetailsUpdate,
} from "services/installerServices";
import { handleKeyDown } from "utils/formHelpers";
import { homeOwnerPersonalDetailsSchema } from "validation-schema/homeOwner";
import { installerPersonalDetailsSchema } from "validation-schema/installer";
import { updateGeneralInformation } from "../../redux/actions";
import { adminPersonalDetailsSchema } from "validation-schema/admin";

const InstallerPersonalDetails = () => {
  const { setDialogData, setIsBackDialogOpen } = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("USER");

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(
      userRole === "installer"
        ? installerPersonalDetailsSchema
        : userRole === "homeOwner"
          ? homeOwnerPersonalDetailsSchema
          : adminPersonalDetailsSchema
    ),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setIsBackDialogOpen(isValid);
  }, [isValid]);

  const fetchData = async () => {
    try {
      if (userRole === "homeOwner") {
        const res = await GEThomeOwnerPersonalDetails();

        setValue("first_name", res.first_name);
        setValue("last_name", res.last_name);
        setValue("email", res.email);
        setValue("mobile", res.mobile);
      } else if (userRole === "installer") {
        const res = await GETinstallerPersonalDetails();
        setValue("first_name", res.first_name);
        setValue("last_name", res.last_name);
        setValue("email", res.email);
      } else if (userRole === "admin") {
        const res = await GETadminPersonalDetails();
        setValue("first_name", res?.admin.first_name);
        setValue("last_name", res?.admin.last_name);
        setValue("email", res?.admin.email);
        setValue("mobile", res?.admin.mobile);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (userRole === "homeOwner") {
        const res = await PUThomeOwnerPersonalDetailsUpdate({
          ...data,
          first_name: data.first_name.trim(),
          last_name: data.last_name.trim(),
        });
        dispatch(updateGeneralInformation(res.user));
        toast.success(res.message);
      } else if (userRole === "installer") {
        const res = await PUTinstallerPersonalDetailsUpdate({
          ...data,
          first_name: data.first_name.trim(),
          last_name: data.last_name.trim(),
        });
        dispatch(updateGeneralInformation(res.user));
        toast.success(res.message);
      } else if (userRole === "admin") {
        const res = await PATCHadminPersonalDetailsUpdate({
          ...data,
          first_name: data.first_name.trim(),
          last_name: data.last_name.trim(),
        });
        dispatch(updateGeneralInformation(res.admin));
        toast.success(res.message);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="h-full">
      <div className="flex flex-col md:flex-row bg-white p-4 md:p-8 rounded-[10px] md:border border-[#D9D3CF]">
        <div className="flex flex-col gap-y-6 w-full md:w-1/2">
          <h1 className="text-[19px] text-[#112532] font-semibold">
            Personal Details
          </h1>

          {/* Name Fields */}
          <div className="flex flex-row gap-3">
            <div className="w-full md:w-1/2">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={true && errors.first_name}
                    helperText={errors.first_name && errors.first_name.message}
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2">
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={true && errors.last_name}
                    helperText={errors.last_name && errors.last_name.message}
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>

          {/* Email Field */}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                disabled
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#E0E4EA",
                    borderRadius: "4px",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-shrink": {
                    color: "#68717A",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C7CACE",
                    borderRadius: "4px",
                  },
                }}
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />
            )}
          />

          {/* Phone Number Field - Only for homeOwner and admin */}
          {userRole === "homeOwner" ||
            (userRole === "admin" && (
              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors.mobile}
                    helperText={errors.mobile && errors.mobile.message}
                    onKeyDown={handleKeyDown}
                    onWheel={(e) => e.target.blur()}
                    type="text"
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    InputProps={{
                      startAdornment: (
                        <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                          +1
                        </div>
                      ),
                    }}
                  />
                )}
              />
            ))}
        </div>

        {/* Spacer div for desktop layout */}
        <div className="hidden md:block w-1/2"></div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col-reverse md:flex-row justify-end gap-3 md:gap-x-3 mt-4 mb-6 md:mb-0">
        <button
          className="w-full md:w-auto py-3 px-10 border border-[#112532] rounded"
          onClick={() => {
            if (!isValid)
              navigate(
                userRole === "installer"
                  ? "/dashboard-installer"
                  : userRole === "homeOwner"
                    ? "/dashboard"
                    : null
              );
            setDialogData({
              isDialogOpen: true,
              title: "Discard Changes?",
              subTitle:
                "Changes will not be saved, are you sure you want to continue?",
              onConfirm: () =>
                navigate(
                  userRole === "installer"
                    ? "/dashboard-installer"
                    : userRole === "homeOwner"
                      ? "/dashboard"
                      : null
                ),
            });
          }}
        >
          Discard
        </button>
        <button
          className={`w-full md:w-auto py-3 px-10 text-white bg-[#112532] rounded ${
            !isValid ? "opacity-50" : ""
          }`}
          onClick={(e) => {
            handleSubmit(onSubmit)(e);
            setIsBackDialogOpen(false);
          }}
          disabled={!isValid}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default InstallerPersonalDetails;
