import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { logout } from "hooks/useAuth";

import { toast } from "react-toastify";
import {
  ACCESS_TOKEN,
  REQUEST_HEADER_AUTH_KEY,
  TOKEN_TYPE,
} from "utils/constant";

class APIService {
  baseApi!: AxiosInstance;
  init() {
    this.baseApi = axios.create({
      baseURL: process.env.REACT_APP_API_URL || "http://18.190.126.180:3060",
    });

    this.baseApi.interceptors.request.use(
      async (config) => {
        const accessToken = await localStorage.getItem(ACCESS_TOKEN);
        if (accessToken) {
          config.headers[REQUEST_HEADER_AUTH_KEY] = `${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.baseApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status) {
          const { status, data } = error.response;
          const currentPath = window.location.pathname;

          if (status === 401) {
            const isLoginPage =
              currentPath === "/auth/login" ||
              currentPath === "/auth/installer-login";

            if (data?.message === "Current password is incorrect") {
              // Do nothing, specific error already handled
              return;
            }

            if (!isLoginPage) {
              toast.error("Your session has expired. Please login again.");
              logout(); // Uncomment if logout logic should be applied here
            }
          } else if (status === 403) {
            toast.error(
              "You do not have permission to access this resource. Please login again."
            );
            logout();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  fetchData(param: AxiosRequestConfig = {}) {
    return new Promise((resolve, reject) => {
      this.baseApi(param)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  }
}

export default new APIService();
