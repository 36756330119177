import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";

const BreadCrumb = ({
  paths = [{ name: "home", pathLink: "/" }],
  pathLink,
  isShowConfirmationDialog = false,
  onConfirm,
  title,
  subTitle,
  cancelText = "Cancel",
  confirmText = "Discard",
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (link) => {
    if (isShowConfirmationDialog) {
      setIsDialogOpen(true);
    } else {
      if (typeof link === "string") {
        navigate(link);
      } else {
        console.error("Invalid pathLink:", link);
      }
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setIsDialogOpen(false);
    if (typeof pathLink === "string") {
      navigate(pathLink);
    } else {
      console.error("Invalid pathLink:", pathLink);
    }
  };

  return (
    <>
      <div
        className="flex items-center py-3 cursor-pointer text-[#71625d] text-xs w-fit"
        onClick={() => handleClick(paths[paths.length - 1].pathLink)}
      >
        <img
          loading="lazy"
          className="w-5 h-5"
          src="/chevron_left.svg"
          alt="left"
        />
        {paths.map((path, index) => (
          <div key={index}>
            {index !== 0 && "/"}
            &nbsp;
            <span
              className="hover:underline"
              onClick={(e) => {
                e.stopPropagation();
                handleClick(path.pathLink);
              }}
            >
              {path.name}
            </span>
            &nbsp;
          </div>
        ))}
      </div>
      {isShowConfirmationDialog && isDialogOpen && (
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          title={title}
          subTitle={subTitle}
          cancelText={cancelText}
          confirmText={confirmText}
        />
      )}
    </>
  );
};

export default BreadCrumb;
