import AuthLayout from "layout/AuthLayout";
import InstallerLoginForm from "components/installer/auth/InstallerLoginForm";
import Header from "components/shared/Header";

const InstallerLogin = () => {
  return (
    <>
      <Header />
      <AuthLayout>
        <InstallerLoginForm />
      </AuthLayout>
    </>
  );
};

export default InstallerLogin;
