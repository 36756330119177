import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";

// Define Table Columns and Data
const tableColumn = [
  "ID",
  "Name",
  "Type",
  "Tiers",
  "Location",
  "Date",
  "Price",
];

const ExportStyledPDF = ({ data, activeFilters }) => {
  const generalData = useSelector((state) => state.generalInformation);
  const rowsNumber = data.length;
  // Function to format date as DD-MM-YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to format price as currency with commas
  const formatCurrency = (price) => {
    return `$${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  // Map status values to new human-readable labels
  const statusLabels = {
    0: "Active",
    1: "Closed Won",
    2: "Closed Lost",
  };

  // Transform the data
  const tableRows = data.map((item) => {
    return [
      item.id.toString(), // id
      `${item.User.first_name} ${item.User.last_name}`.trim(), // full name
      statusLabels[item.status] || "Unknown", // status
      item.system_type, // system_type
      item.User.location, // location
      formatDate(item.createdAt), // createdAt
      formatCurrency(item.systemPrice), // systemPrice
    ];
  });

  const exportTableToPDF = () => {
    const doc = new jsPDF("portrait", "pt", "A4");

    const loadImageFromUrl = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        // const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
        // const imageUrlWithProxy =
        //   process.env.NODE_ENV === "development" ? corsProxyUrl + url : url;
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    };

    const generatePDF = (profileImg = null) => {
      const img = new Image();
      img.src = "/logo.png";
      doc.addImage(img, "PNG", 40, 20, 63.15, 17.8);

      // Add Title with Background Color
      doc.setFillColor("#FEF9EC");
      doc.rect(-50, 70, 900, activeFilters.length > 0 ? 100 : 50, "F");
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("My Deals", 13, 100);

      // Render Filters Below the Title in Bordered Boxes
      doc.setFontSize(10);
      let xPos = 13;
      let yPos = 115;

      const borderColor = "#C0B8B1";
      const textColor = [0, 0, 0];

      activeFilters.forEach((filter) => {
        if (!filter || filter.trim() === "") return;

        doc.setDrawColor(borderColor);
        doc.setFillColor(255, 255, 255);
        doc.setTextColor(...textColor);

        const textWidth = doc.getTextWidth(filter);
        doc.rect(xPos, yPos, textWidth + 10, 20, "FD");
        doc.text(filter, xPos + 5, yPos + 13);
        xPos += textWidth + 15;

        if (xPos > 500) {
          xPos = 13;
          yPos += 30;
        }
      });

      // Add profile image inside the circle
      if (profileImg) {
        const aspectRatio = profileImg.width / profileImg.height;
        let imgWidth = 20;
        let imgHeight = 20;
        if (aspectRatio > 1) {
          imgHeight = 20 / aspectRatio;
        } else {
          imgWidth = 20 * aspectRatio;
        }

        const xOffset = (20 - imgWidth) / 2;
        const yOffset = (20 - imgHeight) / 2;

        doc.addImage(
          profileImg,
          "PNG",
          370 + xOffset,
          20 + yOffset,
          imgWidth,
          imgHeight
        );
      }

      doc.setDrawColor("#fff");
      doc.setLineWidth(6);
      doc.circle(380, 30, 12.5);
      doc.setDrawColor("#D9D3CF");
      doc.setLineWidth(0.5);
      doc.circle(380, 30, 12.5);
      doc.setFontSize(12);
      doc.text(generalData?.business_name, 400, 35);

      // Render the table in chunks of 10 rows, adjusting the start position
      const rowsPerPage = 10;
      for (let i = 0; i < tableRows.length; i += rowsPerPage) {
        const currentRows = tableRows.slice(i, i + rowsPerPage);

        let isFirstPage = i === 0;

        if (!isFirstPage) {
          doc.addPage();
        }

        let startYPosition = isFirstPage ? yPos + 55 : 20;

        doc.autoTable({
          head: [tableColumn],
          body: currentRows,
          startY:
            activeFilters.length > 0 ? 20 + startYPosition : startYPosition,
          theme: "plain",
          margin: { left: 13 },
          showHead: "everyPage",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: "#244255",
            fontStyle: "500",
            halign: "left",
            cellPadding: 8,
          },
          bodyStyles: {
            textColor: "#000",
            halign: "left",
            valign: "middle",
          },
          alternateRowStyles: {
            fillColor: "",
          },
          columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 90, fontStyle: "bold", textColor: [208, 104, 15] },
            2: { cellWidth: 60 },
            3: { cellWidth: 70 },
            4: { cellWidth: 170 },
            5: { cellWidth: 80 },
            6: {
              cellWidth: 60,
              fontStyle: "bold",
              textColor: "#112532",
            },
          },
          didDrawCell: (data) => {
            const { doc, cell, column } = data;
            const x = cell.x;
            const y = cell.y;
            const height = cell.height;
            const width = cell.width;

            if (data.column.index === 6 && data.section === "body") {
              doc.setFillColor("#FEF9EC");
              doc.rect(x, y + 2, width, height - 4, "F");
              doc.setTextColor("#112532");
              doc.setFont("helvetica", "bold");

              const text = data.cell.text ? data.cell.text[0] : "N/A";
              if (text) {
                const textWidth = doc.getTextWidth(text);
                const textX = x + (width - textWidth) / 2;
                const textY = y + height / 2 + 3;
                doc.text(text, textX, textY);
              }
            }

            doc.setDrawColor("#D9D3CF");
            doc.setLineWidth(0.5);

            const borderGap = 4;

            if (data.section === "body" || data.section === "head") {
              doc.line(x, y + borderGap / 2, x + width, y + borderGap / 2);
              doc.line(
                x,
                y + height - borderGap / 2,
                x + width,
                y + height - borderGap / 2
              );

              if (column.index === 0) {
                doc.line(x, y + borderGap / 2, x, y + height - borderGap / 2);
              }

              if (column.index === data.table.columns.length - 1) {
                doc.line(
                  x + width,
                  y + borderGap / 2,
                  x + width,
                  y + height - borderGap / 2
                );
              }
            }
          },

          didDrawPage: (data) => {
            const today = new Date();
            const downloadDate = `${String(today.getDate()).padStart(2, "0")}-${String(today.getMonth() + 1).padStart(2, "0")}-${today.getFullYear()}`;

            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const totalPages = Math.ceil(rowsNumber / 10);
            const pageNumber = `Page ${currentPage} / ${totalPages}`;

            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            const pageWidth = pageSize.width
              ? pageSize.width
              : pageSize.getWidth();

            doc.setFontSize(10);
            doc.setFont("helvetica", "normal");
            doc.setTextColor("#5E514E");

            doc.setDrawColor("#5E514E");
            doc.line(13, pageHeight - 40, pageWidth - 13, pageHeight - 40);

            doc.text("My Deals", data.settings.margin.left, pageHeight - 15);

            doc.text(
              pageNumber,
              pageWidth / 2 - doc.getTextWidth(pageNumber) / 2,
              pageHeight - 15
            );

            doc.text(
              `Date: ${downloadDate}`,
              pageWidth -
                data.settings.margin.right -
                doc.getTextWidth(`Date: ${downloadDate}`),
              pageHeight - 15
            );
          },
        });
      }

      doc.save("MyDeals.pdf");
    };

    const profileImgUrl = generalData?.logo;

    loadImageFromUrl(profileImgUrl)
      .then((img) => {
        generatePDF(img);
      })
      .catch((error) => {
        console.error("Error loading profile image:", error);
        generatePDF();
      });
  };

  return (
    <button
      onClick={exportTableToPDF}
      className=" flex items-center gap-x-2 pb-3 px-2"
    >
      <img loading="lazy" className="w-5 h-5" src="/PDF.svg" alt="" />
      PDF
    </button>
  );
};

export default ExportStyledPDF;
