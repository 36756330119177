import { useState, useEffect } from "react";

const useResponsive = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    isSm: width <= 640,
    md: width <= 768,
    lg: width <= 1024,
    xl: width <= 1280,
    "2xl": width <= 1536,
  };
};

export default useResponsive;
