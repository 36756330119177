/* global google */

import React, { useCallback, useRef } from "react";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import useResponsive from "hooks/useResponsive";

// Container style for the map
const containerStyle = {
  width: "100%",
  height: "600px",
};

const PanelMap = ({ buildingInsights }) => {
  const { isSm } = useResponsive();
  const formData = useSelector((state) => state.homeownerForm);
  const mapRef = useRef(null);
  const panels = buildingInsights?.panelsWithCorners || [];
  const solarPotential = buildingInsights;

  // Panel dimensions in meters
  const PANEL_WIDTH_METERS = solarPotential.panelWidthMeters || 1.7; // Default width
  const PANEL_HEIGHT_METERS = solarPotential.panelHeightMeters || 1.0; // Default height

  // Find the center point for initial map position only
  const centerBuilding = buildingInsights?.centerBuilding;
  const defaultCenter = {
    lat: isSm ? centerBuilding?.latitude : centerBuilding?.latitude,
    lng: isSm ? centerBuilding?.longitude : centerBuilding?.longitude,
  };

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      // Set the initial center position
      map.setCenter(defaultCenter);
    },
    [defaultCenter, isSm]
  );

  const calculatePanelPoints = (
    center,
    orientation,
    segmentAzimuth,
    pitchDegrees
  ) => {
    // Calculate half dimensions
    let halfWidth = PANEL_WIDTH_METERS / 2;
    let halfHeight = PANEL_HEIGHT_METERS / 2;

    // Convert pitch degrees to radians
    const pitchRadians = (pitchDegrees * Math.PI) / 180;

    // Adjust dimensions based on orientation and pitch
    if (orientation === "PORTRAIT") {
      halfHeight *= Math.cos(pitchRadians); // Scale height for portrait
    } else {
      halfWidth *= Math.cos(pitchRadians); // Scale width for landscape
    }

    // Define base points
    const basePoints = [
      { x: +halfWidth, y: +halfHeight }, // top-right
      { x: +halfWidth, y: -halfHeight }, // bottom-right
      { x: -halfWidth, y: -halfHeight }, // bottom-left
      { x: -halfWidth, y: +halfHeight }, // top-left
    ];

    const orientationAngle = orientation === "PORTRAIT" ? 90 : 0;
    const totalRotation = orientationAngle + segmentAzimuth;

    // Rotate and offset points using spherical geometry
    return basePoints.map(({ x, y }) => {
      const distance = Math.sqrt(x ** 2 + y ** 2); // Distance from center
      const angle = (Math.atan2(y, x) * (180 / Math.PI) + totalRotation) % 360; // Rotation in degrees
      return google.maps.geometry.spherical.computeOffset(
        { lat: center.latitude, lng: center.longitude },
        distance,
        angle
      );
    });
  };

  if (!panels.length) {
    console.error("No panels data available.");
    return null; // Handle missing panels gracefully
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={21}
      onLoad={onLoad}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        rotateControl: false,
        mapTypeControl: false,
        mapTypeId: "satellite",
        tilt: 0,
        draggable: true,
      }}
    >
      {panels.slice(0, formData.panels_number).map((panel, index) => {
        const points = calculatePanelPoints(
          panel.center,
          panel.orientation,
          solarPotential.roofSegmentStats[panel.segmentIndex].azimuthDegrees,
          solarPotential.roofSegmentStats[panel.segmentIndex].pitchDegrees
        );

        return (
          <Polygon
            key={index}
            paths={points}
            options={{
              strokeColor: "#B0BEC5",
              strokeOpacity: 0.9,
              strokeWeight: 1,
              fillColor: "#2E1B97",
              fillOpacity: 0.9,
            }}
          />
        );
      })}
    </GoogleMap>
  );
};

export default PanelMap;
