const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirmPassword: "",
  business_name: "",
  city: "",
  business_address: "",
  post_code: "",
  state: "",
  country: "United States of America (USA)",
  business_operation: "",
  mobile: "",
  pricing_method: "watt",
  system_type: "",
  solar_panels: "",
  inverter: "",
  racking: "",
  battery: "",
  solar_panel_price: "",
  inverter_price: "",
  racking_price: "",
  battery_price: "",
  watt_price: "",
  currentStep: 0,
  business_address: "bussiness_name",
};

const installerRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FORM_STEP_1":
      return { ...state, ...action.payload, currentStep: 1 };
    case "SET_FORM_STEP_2":
      return { ...state, ...action.payload, currentStep: 2 };
    case "GO_BACK_STEP":
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
      };
    case "RESET_FORM":
      return { ...initialState, currentStep: 0 };
    default:
      return state;
  }
};

export default installerRegisterReducer;
