import React from "react";
import Footer from "pages/Footer";

const About = () => {
  return (
    <div className="w-full h-full">
      {/*HERO SECTION*/}
      <div
        className="w-full h-[420px] md:h-[420px] grid place-content-center gap-y-5 bg-cover bg-bottom bg-no-repeat"
        style={{
          backgroundImage: "url(/about-hero.png)",
        }}
      >
        <h1 className="text-white text-3xl md:text-5xl font-semibold text-center px-4">
          Solar made <span className="text-amber-500">easy.</span>
        </h1>
        <p className="text-white text-lg md:text-xl text-center px-4">
          Start your solar journey with Spark! <br /> Simple onboarding and
          seamless installment.
        </p>
      </div>

      {/*TABLET SECTION*/}
      <div className="px-6 py-10 md:p-12 flex flex-col md:flex-row justify-between items-center bg-[#f3f4f4]">
        <div className="flex flex-col gap-y-5 w-full md:w-1/2 mb-8 md:mb-0">
          <h1 className="text-[#112532] text-3xl md:text-4xl font-semibold mb-1 text-center md:text-left">
            At <span className="text-amber-600">Spark Solar,</span>
          </h1>
          <div className="bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] p-6 relative">
            <img
              loading="lazy"
              className="absolute top-[-4px] left-[14px]"
              src="/“.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-lg md:text-xl font-semibold text-center md:text-left">
              We believe that solar energy should be bought by homeowners,
              <br /> not just sold to them!
            </h2>
            <img
              loading="lazy"
              className="absolute bottom-[10px] right-[14px]"
              src="/“.svg"
              alt=""
            />
          </div>
          <p className="text-[#112532] text-base md:text-xl text-center md:text-left">
            That's why we prioritize transparency in every step of the process,
            empowering customers to make informed decisions about their solar
            journey.
          </p>
          <p className="text-[#112532] text-base md:text-xl text-center md:text-left">
            Our AI-powered platform simplifies the entire experience, from
            discovering the best solar installers to managing contracts and site
            surveys. We're committed to eliminating the high-pressure sales
            tactics traditionally associated with the solar industry. Instead,
            we provide you with the tools, data, and confidence to take control
            of your energy future on your own terms. <br />
            <span className="font-semibold">
              With our technology, you'll have full visibility into every aspect
              of the solar installation process, helping you understand the
              value and impact of your decision!
            </span>
          </p>
        </div>
        <div className="relative rounded-3xl shadow-2xl w-full md:w-auto">
          <img src="/tablet-heatmap.png" alt="" className="w-full md:w-auto" />
        </div>
      </div>

      {/*COVER SECTION*/}
      <div className="flex flex-col items-center gap-y-12 p-6 md:p-12">
        <div className="flex flex-col gap-y-2 text-center">
          <h1 className="text-[#112532] text-3xl md:text-4xl font-semibold">
            We cover all stages of the
            <span className="text-[#D97706]"> solar journey</span>
          </h1>
          <p className="text-[#112532] text-base md:text-xl">
            Allowing you to easily transition to clean, renewable energy <br />
            while saving on your energy bills.
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] flex-1">
            <img
              loading="lazy"
              className="w-12 h-12"
              src="/Step Stepper.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-xl md:text-2xl font-medium">
              Seamless Onboarding
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              5 steps away from completing your solar journey.
            </p>
          </div>
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] flex-1">
            <img
              loading="lazy"
              className="w-12 h-12"
              src="/aI-stepper.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-xl md:text-2xl font-medium">
              AI Simulation
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              High-tech AI model for solar design simulation of your roof.
            </p>
          </div>
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] flex-1">
            <img
              loading="lazy"
              className="w-12 h-12"
              src="/success-stepper.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-xl md:text-2xl font-medium">
              Easy Decision-Making
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Compare offers, installers and customize to your preference.
            </p>
          </div>
        </div>
      </div>

      {/*MISSION SECTION*/}
      <div className="flex flex-col md:flex-row items-center gap-x-12 px-6 md:px-16 py-12 bg-gradient-to-r from-[#f3f4f4] to-[#fff9eb]">
        <div className="w-full md:w-1/2 h-full relative object-contain mb-8 md:mb-0">
          <img
            loading="lazy"
            className="w-full"
            src="/about-mission.png"
            alt=""
          />
          <img
            loading="lazy"
            className="absolute bottom-[12px] right-[-32px]"
            src="/spark-element.svg"
            alt=""
          />
        </div>
        <div className="flex flex-col gap-y-5 w-full md:w-1/2">
          <h2 className="text-[#112532] text-3xl md:text-4xl font-semibold text-center md:text-left">
            Our <span className="text-amber-600">Mission</span>
          </h2>
          <p className="text-[#112532] text-base md:text-xl text-center md:text-left">
            To make solar energy{" "}
            <span className="font-semibold">
              accessible, transparent, and customer-driven
            </span>{" "}
            by simplifying the adoption process through innovative technology
            and empowering homeowners to take charge of their energy choices.
          </p>
        </div>
      </div>

      {/*Footer SECTION*/}
      <Footer />
    </div>
  );
};

export default About;
