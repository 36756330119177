import { PASSWORD_PATTERN } from "utils/constant";
import { number, object, ref, string } from "yup";

export const loginSchema = object({
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  password: string()
    .required("password is required!")
    .matches(PASSWORD_PATTERN, "the password at least should be 8 characters"),
}).required();

export const homeOwnerPersonalDetailsSchema = object({
  first_name: string()
    .required("First name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  last_name: string()
    .required("Last name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  mobile: string()
    .required("Phone Number is required!")
    .min(10, "The phone number must be at least 10 digits.")
    .max(10, "more than 10 digits are not allowed."),
}).required();

export const homeDetailsSchema = object().shape({
  location: object()
    .shape({
      address: string().required("Address is required"),
      placeId: string().required(
        "Please select a valid address from the dropdown"
      ),
    })
    .required("Location must be selected using autocomplete"),
  bill_value: string().required("Average bill is required"),
  equipment: string().required("Equipment selection is required"),
  LatLng: object().required(),
});

export const personalDetailsSchema = object().shape({
  first_name: string()
    .required("First name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  last_name: string()
    .required("Last name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  mobile: string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
});

export const yourSolarDesignSchema = object().shape({
  tier: string().required("Tier type is required"),
});

export const additionalInformationSchema = object({
  password: string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@, $, !, %, *, ?, &, or #)"
    ),
  confirmPassword: string()
    .required("Please confirm your password")
    .oneOf([ref("password"), null], "Passwords must match"),
  roof_type: string().required("Roof type is required"),
  roof_condition: string().required("Roof condition is required"),
  credit_score: number()
    .required("Credit score is required")
    .min(300, "Credit score must be at least 300")
    .max(850, "Credit score cannot exceed 850"),
}).required();

export const forgetPasswordSchema = object({
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
}).required();

export const resetChangePasswordSchema = object({
  password: string()
    .required("password is required!")
    .matches(PASSWORD_PATTERN, "the password at least should be 8 characters"),
  ConfirmNewPassword: string()
    .required("confirm the password please")
    .oneOf([ref("password")], "password does not match!")
    .matches(PASSWORD_PATTERN, "the password at least should be 8 characters"),
}).required();

export const ReferFriendSchema = object({
  firstName: string()
    .required("First name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  lastName: string()
    .required("Last name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed."),
  phoneNumber: string()
    .required("Phone Number is required!")
    .min(10, "The phone number must be at least 10 digits.")
    .max(10, "more than 10 digits are not allowed."),
  referredEmail: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
}).required();
