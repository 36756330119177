import Header from "components/shared/Header";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  const isAuth = useAuth();

  return (
    <div className="min-h-screen lg:bg-gray-50">
      <Header />

      <main className={`h-full ${isAuth ? "lg:px-6" : ""}`}>
        <div
          className={`h-[calc(100vh-150px)] flex flex-col items-center justify-center space-y-4 md:space-y-6 ${isAuth ? "px-4 md:px-8 pt-6 lg:pb-14 bg-white lg:rounded-[10px] lg:shadow lg:border border-[#d9d3cf]" : ""}`}
        >
          <div className="w-full max-w-[280px] md:max-w-[320px] mx-auto">
            <img
              src="/404.png"
              alt="404 Illustration"
              className="w-full h-auto"
            />
          </div>

          <h1 className="text-2xl md:text-4xl font-semibold text-gray-800 text-center">
            404 Page Not Found
          </h1>

          <p className="text-base md:text-xl text-gray-700 text-center max-w-md mx-auto px-4">
            We can't find what you're looking for...
          </p>

          <Link
            to="/"
            className="px-8 md:px-10 py-2.5 md:py-3 bg-gray-900 text-gray-50 rounded font-bold text-sm md:text-base hover:bg-gray-800 active:bg-gray-950 transition-colors"
          >
            Go Back Home
          </Link>
        </div>
      </main>
    </div>
  );
};

export default Page404;
