const initialState = {
  id: "",
  location: { address: "", placeId: "" },
  bill_value: "250",
  equipment: "",
  first_name: "",
  last_name: "",
  latitude: "",
  longitude: "",
  LatLng: { lat: 34.147785, lng: -118.144516 },
  mobile: "",
  email: "",
  tier: "",
  offer_id: "",
  roof_type: "",
  roof_condition: "",
  credit_score: "",
  electrical_bil: "",
  panels_number: 10,
  systemSize: "",
  password: "",
  confirmPassword: "",
  roof_type: "",
  roof_condition: "",
  credit_score: 450,
  currentStep: 0,
};

const homeownerRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_HOMEOWNER_FORM":
      return {
        ...state,
        ...action.payload,
        location: action.payload.location
          ? {
              address:
                action.payload.location.address || state.location.address,
              placeId:
                action.payload.location.placeId || state.location.placeId,
            }
          : state.location,
      };
    case "SET_HOMEOWNER_FORM_NEXT_STEP":
      return {
        ...state,
        ...action.payload,
        currentStep: state.currentStep ? state.currentStep + 1 : 1, // Increment the currentStep
      };
    case "GO_HOMEOWNER_BACK_STEP":
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
      };
    case "RESET_HOMEOWNER_FORM":
      return { ...initialState, currentStep: 0 };
    case "INCREASE_PANELS_NUMBER":
      return { ...state, panels_number: state.panels_number + 1 };
    case "DECREASE_PANELS_NUMBER":
      return {
        ...state,
        panels_number: state.panels_number > 10 ? state.panels_number - 1 : 10,
      };
    default:
      return state;
  }
};

export default homeownerRegisterReducer;
