// Define the initial state
const initialState = [];

// Reducer function
function chatListReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CHAT_LIST":
      // Replace the whole state with the new array
      return action.payload;
    default:
      return state;
  }
}

export default chatListReducer;
