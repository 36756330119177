export function formatPrice(value) {
  if (value == null || isNaN(value)) return "";

  // Round to two decimal places
  const rounded = Math.round(value * 100) / 100;

  // Format the number with commas as thousands separators
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: Number.isInteger(rounded) ? 0 : 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(rounded);
}

export function formatNumberFixed(value) {
  if (value == null || isNaN(value)) return "";

  // Convert the value to a string with at least 2 decimal places without rounding
  const fixedValue = value.toFixed(2);

  // Format the number with commas as thousands separators
  const parts = fixedValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
}
