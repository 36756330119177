import React from "react";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BreadCrumb from "components/shared/BreadCrumb";
import Header from "components/shared/Header";
import { POSTreferAfriend } from "services/homeOwnerServices";
import { ReferFriendSchema } from "validation-schema/homeOwner";
import { handleKeyDown } from "utils/formHelpers";

const ReferFriend = () => {
  const generalData = useSelector((state) => state.generalInformation);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ReferFriendSchema),
    defaultValues: {
      referredEmail: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      const res = await POSTreferAfriend({
        ...data,
        referrerEmail: generalData?.email,
      });
      toast.success(res.message);
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  return (
    <div className="min-h-screen">
      <Header />
      <div className="ml-4 md:ml-6">
        <BreadCrumb
          paths={[
            { name: "Home", pathLink: "/dashboard" },
            {
              name: "Refer a Friend",
              pathLink: `/dashboard/refer-friend`,
            },
          ]}
        />
      </div>
      <div className="w-full flex-col gap-y-4 flex md:hidden px-6">
        <h1 className="text-[#112532] text-3xl md:text-5xl font-bold">
          Give $100, Get $100
        </h1>
        <p className="text-[#112532] text-sm">
          Introduce a friend to Spark Solar and you'll both get $100 off when
          they sign the contract
        </p>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-between p-4 md:p-6 gap-4">
        {/* Left Column - Form */}
        <div className="w-full md:w-7/12 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex items-center">
          <div className="w-full px-4 md:px-20 py-6 md:py-10 flex flex-col gap-y-6">
            <div className="w-full flex-col gap-y-4 hidden md:flex">
              <h1 className="text-[#112532] text-3xl md:text-5xl font-bold text-center md:text-left">
                Give $100, Get $100
              </h1>
              <p className="text-[#112532] text-sm text-center md:text-left">
                Introduce a friend to Spark Solar and you'll both get $100 off
                when they sign the contract
              </p>
            </div>
            <div className="flex flex-col gap-y-6 w-full">
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors.firstName && errors.firstName.message}
                    label="First Name"
                    variant="outlined"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "white",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    label="Last Name"
                    variant="outlined"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "white",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber && errors.phoneNumber.message
                    }
                    label="Phone Number"
                    variant="outlined"
                    type="number"
                    onKeyDown={handleKeyDown}
                    onWheel={(e) => e.target.blur()}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "white",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                          +1
                        </div>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="referredEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.referredEmail}
                    helperText={
                      errors.referredEmail && errors.referredEmail.message
                    }
                    label="Email"
                    variant="outlined"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "white",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                  />
                )}
              />

              <button
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                className="px-10 py-3 bg-[#112532] hover:bg-[#D97706] text-[white] rounded-[5px] cursor-pointer"
              >
                Refer a Friend
              </button>
            </div>
          </div>
        </div>

        {/* Right Column - How it Works */}
        <div
          className="w-full md:w-5/12 bg-cover bg-center rounded-[10px] shadow border border-[#d9d3cf] mt-4 md:mt-0"
          style={{ backgroundImage: "url('/refer-friend.png')" }}
        >
          <div className="px-4 md:px-8 py-6 md:py-10 flex flex-col gap-y-6">
            <h1 className="text-[#112532] text-2xl md:text-[28px] font-semibold text-center">
              How it works
            </h1>
            <div className="flex flex-col gap-y-8">
              {[
                {
                  icon: "/refer-pen.svg",
                  step: "Step 1",
                  title: "Sign Contract",
                  description:
                    "Once you have signed the contract you can refer your friend",
                },
                {
                  icon: "/message-refer.svg",
                  step: "Step 2",
                  title: "Refer a Friend",
                  description:
                    "Your friend has to sign the contract within\n3 months of the referral time",
                },
                {
                  icon: "/refer-money.svg",
                  step: "Step 3",
                  title: "Give $100, Get $100",
                  description:
                    "We give your friend $100 off their deal,\nand you get $100 gift card",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center"
                >
                  <img src={item.icon} alt="" className="mb-2" />
                  <p className="text-[#244255] text-xs font-semibold">
                    {item.step}
                  </p>
                  <p className="text-[#112532] text-lg font-semibold">
                    {item.title}
                  </p>
                  <p className="text-[#112532] text-sm text-center whitespace-pre-line">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferFriend;
