import { Checkbox, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useResponsive from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(
  ({ theme, "data-frozen": frozen, index }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "500",
    },
    ...(frozen && {
      position: "sticky",
      left: index === 0 ? "-2px" : "100.08px",
      zIndex: 2,
      backgroundColor: "white",
      filter: "drop-shadow(8px 0 6px rgba(203, 197, 180, 0.21))",
      borderRight: "1px solid rgba(203, 197, 180, 0.15)",
    }),
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    "& table": {
      minWidth: "100%",
    },
    "& th, & td": {
      width: { sm: "75px", md: "150px" },
    },
    "& th:first-of-type, & td:first-of-type, & th:nth-of-type(2), & td:nth-of-type(2)":
      {
        position: "sticky",
        backgroundColor: "white",
        // zIndex: 1,
      },
  },
}));

// const rows = Array.from({ length: 100 }, (v, i) => ({
//   id: i + 1,
//   name: "Alex Petter",
//   type: "Contract",
//   tiers: "Premium",
//   location: "209 Circle Slope Dr, Simpsonville, SC 29681",
//   date: "20-05-2024",
//   price: "$26,000",
// }));

const CustomizedTables = ({
  recordsArray,
  setSelectedRows,
  selectedRows,
  setSortedRows,
  columns = [],
}) => {
  const { isSm } = useResponsive();

  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("myDealsPageNumber");
    return savedPage ? parseInt(savedPage) : 0;
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // Set initial orderBy to empty string

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(sortedRows.slice(page * 10, page * 10 + 10));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRequestSort = (event, property) => {
    let newOrder;
    if (orderBy !== property) {
      newOrder = "asc";
      setOrder("asc");
      setOrderBy(property);
    } else {
      if (order === "asc") {
        newOrder = "desc";
        setOrder("desc");
      } else if (order === "desc") {
        newOrder = null;
        setOrder(null);
      } else {
        newOrder = "asc";
        setOrder("asc");
      }
    }

    // Sort the entire array
    const newSortedRows = recordsArray
      .slice()
      .sort(getComparator(newOrder, property));

    // Set the entire sorted array as sorted rows
    setSortedRows(newSortedRows);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    const valueA = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], a)
      : a[orderBy];
    const valueB = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], b)
      : b[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    if (order === null) {
      return (a, b) => 0; // Return default, unsorted rows
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const rows = recordsArray;
  const emptyRows = Math.max(0, (page + 1) * 10 - rows.length);
  const sortedRows = rows.slice().sort(getComparator(order, orderBy));
  const paginatedRows = sortedRows.slice(page * 10, page * 10 + 10);

  useEffect(() => {
    sessionStorage.setItem("myDealsPageNumber", page.toString());
  }, [page]);

  const renderCell = (row, column) => {
    if (column.customRenderer) {
      return column.customRenderer(row);
    }
    return row[column.field];
  };

  // useEffect(() => {
  //   setPage(0);
  //   sessionStorage.setItem("myDealsPageNumber", "0");
  // }, [recordsArray]);

  return (
    <TableWrapper>
      <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <>
                  <StyledTableCell
                    data-frozen={(index < 2 && isSm) || undefined}
                    index={index}
                    sx={{
                      width: "150px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    align="left"
                  >
                    <div className="flex">
                      {index === 0 && (
                        <Checkbox
                          size="small"
                          checked={
                            sortedRows.length > 0 &&
                            selectedRows.length ===
                              sortedRows.slice(page * 10, page * 10 + 10).length
                          }
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length <
                              sortedRows.slice(page * 10, page * 10 + 10).length
                          }
                          sx={{
                            visibility:
                              selectedRows.length > 0 ? "visible" : "hidden", // Show when selected
                            "&:hover": {
                              visibility: "visible", // Show on hover
                            },
                            ".MuiTableRow-root:hover &": {
                              visibility: "visible", // Show when row is hovered
                            },
                          }}
                          onChange={handleSelectAllClick}
                        />
                      )}

                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={order !== null ? order : undefined}
                        onClick={(event) =>
                          handleRequestSort(event, column.field)
                        }
                        sx={{
                          "&:hover .MuiTableSortLabel-icon": {
                            visibility: "visible !important",
                          },
                          ".MuiTableSortLabel-icon": {
                            visibility: order !== null ? "visible" : "hidden",
                          },
                        }}
                      >
                        {column.header}
                      </TableSortLabel>
                    </div>
                  </StyledTableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{
                  bgcolor: "white",
                  border: "0px solid #F9F8F8",
                  borderWidth: "4px 2px",
                }}
              >
                {columns.map((column, index) => (
                  <StyledTableCell
                    key={column.field}
                    data-frozen={(index < 2 && isSm) || undefined}
                    index={index}
                    component="th"
                    scope="row"
                    sx={index === 7 && { bgcolor: "#FCEEC9" }}
                    align={index === 7 ? "center" : "left"}
                  >
                    {index === 0 ? (
                      <div className=" flex items-center">
                        <Checkbox
                          size="small"
                          checked={selectedRows.some(
                            (selectedRow) => selectedRow.id === row.id
                          )}
                          sx={{
                            visibility: selectedRows.some(
                              (selectedRow) => selectedRow.id === row.id
                            )
                              ? "visible"
                              : "hidden",
                            "&:hover": {
                              visibility: "visible",
                            },
                            ".MuiTableRow-root:hover &": {
                              visibility: "visible",
                            },
                          }}
                          onChange={(event) => handleSelectRow(event, row)}
                        />
                        {renderCell(row, column)}
                      </div>
                    ) : (
                      <>
                        {index === 1 ? (
                          <Link
                            to={`/dashboard-installer/Mydeals/${row.id}`}
                            className="text-[#D0680F]"
                          >
                            {renderCell(row, column)}
                          </Link>
                        ) : (
                          renderCell(row, column)
                        )}
                      </>
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        {rows.length > 10 && (
          <div className="flex justify-center mt-3 items-center">
            <img
              loading="lazy"
              className="cursor-pointer mr-2"
              src={page ? "/chevron_left.svg" : "/left.svg"}
              alt=""
              onClick={() => {
                if (page) setPage((page) => page - 1);
              }}
            />
            <div className="flex justify-between gap-x-3">
              {Array(Math.ceil(rows.length / 10))
                .fill()
                .map((el, i) => (
                  <div
                    onClick={(e) => {
                      setPage(+e.target.innerHTML - 1);
                    }}
                    key={i}
                    className={
                      page === i
                        ? "bg-[#112532] text-white px-2 py-1 rounded cursor-pointer"
                        : "px-2 py-1 cursor-pointer"
                    }
                  >
                    {i + 1}
                  </div>
                ))}
            </div>
            <img
              loading="lazy"
              className="cursor-pointer ml-2"
              src={
                Math.ceil(rows.length / 10) > page + 1
                  ? "/chevron_right.svg"
                  : "/no-right.svg"
              }
              alt=""
              onClick={() => {
                if (Math.ceil(rows.length / 10) > page + 1)
                  setPage((page) => page + 1);
              }}
            />
          </div>
        )}
      </TableContainer>
    </TableWrapper>
  );
};

export default CustomizedTables;
