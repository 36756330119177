import React, { useLayoutEffect, useRef, useState } from "react";

const InstallerTermsAndConditions = ({ isOpen, onClose, onAgree }) => {
  const [canAgree, setCanAgree] = useState(false);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    const checkScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setCanAgree(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    const content = contentRef.current;

    const setupScrollListener = () => {
      if (content) {
        content.addEventListener("scroll", checkScroll);
      }
    };

    const timeoutId = setTimeout(setupScrollListener, 50);

    return () => {
      if (content) {
        content.removeEventListener("scroll", checkScroll);
      }
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleAgree = () => {
    onAgree();
    onClose();
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      />
      <div className="fixed inset-0 flex items-center justify-center z-50 md:p-4">
        <div className="bg-white w-full md:max-w-4xl h-full md:max-h-[90vh] rounded-[10px] shadow-lg overflow-hidden flex flex-col">
          <h1 className="p-8 pb-6 text-[#112532] text-[24px] md:text-[33px] font-semibold">
            Terms and Conditions
          </h1>
          <hr />
          <div ref={contentRef} className="overflow-y-auto flex-grow p-8">
            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                1. Acceptance of Terms
              </h2>
              <p className="text-gray-700 leading-relaxed">
                By joining the Spark Solar platform as an installer, you
                ("Installer") agree to these Terms & Conditions ("Agreement")
                and our Privacy Policy. If you do not agree to these terms, you
                may not participate in the Spark Solar platform. By using the
                platform, you agree to comply with all guidelines and rules
                outlined herein.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                2. Services Provided
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar provides a platform to connect solar installers with
                homeowners seeking solar installation services. Installers can
                create offers for their systems, which will be presented to
                homeowners through the Spark Solar platform.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                3. Installer Obligations
              </h2>
              <p className="text-gray-700 leading-relaxed">
                <strong>Contract Acceptance:</strong> Installers are expected to
                accept a reasonable number of contracts that are signed and sent
                to them by homeowners through the platform. Spark Solar monitors
                rejection rates, and excessive rejections may impact the
                Installer's ranking within the platform. A lower ranking may
                reduce the visibility of the Installer's offers to homeowners.
              </p>
              <p className="text-gray-700 leading-relaxed mt-4">
                <strong>Quality and Standards:</strong> Installers agree to
                provide high-quality solar installation services in compliance
                with industry standards and applicable laws. Any failure to do
                so may result in removal from the platform.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                4. System Listing and Fees
              </h2>
              <p className="text-gray-700 leading-relaxed">
                <strong>Free Listing:</strong> Installers may list one solar
                system on the platform for free.
              </p>
              <p className="text-gray-700 leading-relaxed mt-2">
                <strong>Additional Listings:</strong> To list more than one
                system (up to a maximum of three systems), Installers must pay a
                subscription fee, the details of which will be provided upon
                request or during the listing process.
              </p>
              <p className="text-gray-700 leading-relaxed mt-2">
                <strong>Subscription Fees:</strong> These fees are
                non-refundable and must be paid in advance in accordance with
                the pricing plans outlined on the platform.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                5. Data Collection and Use
              </h2>
              <p className="text-gray-700 leading-relaxed">
                By joining the platform, Installers agree to provide accurate
                business information, including but not limited to their
                business name, address, contact information, system details, and
                installation certifications. Spark Solar may collect and use
                this data to:
              </p>
              <ul className="list-disc pl-6 mt-2 text-gray-700 leading-relaxed">
                <li>Facilitate connections with homeowners.</li>
                <li>Display installer information on the platform.</li>
                <li>
                  Communicate updates, feedback, and marketing materials to
                  installers.
                </li>
              </ul>
              <p className="text-gray-700 leading-relaxed mt-2">
                For more details on data usage, refer to our Privacy Policy.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                6. Liability and Indemnification
              </h2>
              <p className="text-gray-700 leading-relaxed">
                <strong>Installer Responsibility:</strong> The Installer
                acknowledges that they are fully responsible for all aspects of
                the solar installation services provided to homeowners. This
                includes but is not limited to the quality of workmanship,
                compliance with legal requirements, and fulfillment of
                contractual obligations with homeowners.
              </p>
              <p className="text-gray-700 leading-relaxed mt-2">
                <strong>No Liability for Spark Solar:</strong> Spark Solar is a
                neutral platform that facilitates the connection between
                homeowners and installers. Spark Solar shall not be liable for
                any disputes, damages, or claims arising from the Installer's
                work or interactions with homeowners.
              </p>
              <p className="text-gray-700 leading-relaxed mt-2">
                <strong>Indemnification:</strong> Installers agree to indemnify
                and hold Spark Solar, its affiliates, and partners harmless from
                any claims, liabilities, damages, or costs, including legal
                fees, arising from their participation in the platform or
                failure to meet contractual obligations with homeowners.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                7. Ranking and Visibility
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Installer performance, including response times and contract
                acceptance rates, may impact their ranking on the platform.
                Installers with a high rejection rate or poor service records
                may experience reduced visibility to homeowners, which could
                impact the number of contracts or opportunities presented to
                them.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">8. Termination</h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar reserves the right to terminate or suspend an
                Installer's access to the platform for violations of these Terms
                & Conditions, including failure to meet quality standards or
                contractual obligations, or any actions deemed harmful to the
                platform or its users.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">9. Governing Law</h2>
              <p className="text-gray-700 leading-relaxed">
                This Agreement is governed by the laws of the State of South
                Carolina, without regard to its conflict of laws principles. Any
                disputes arising from these terms will be subject to the
                jurisdiction of the courts located in Greenville, South
                Carolina.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">
                10. Changes to Terms
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar reserves the right to update or modify these Terms &
                Conditions at any time. Installers will be notified of
                significant changes, and continued use of the platform
                constitutes acceptance of the updated terms.
              </p>
            </section>
          </div>
          <hr />
          <section className="py-6 px-8 flex justify-end gap-x-3">
            <button
              onClick={onClose}
              className="px-10 py-3 rounded-[5px] border border-[#112532] font-bold hover:bg-gray-100 transition-colors flex-grow md:flex-grow-0"
            >
              Cancel
            </button>
            <button
              onClick={handleAgree}
              disabled={!canAgree}
              className={`px-10 py-3 rounded-[5px] text-[#fffefb] font-bold transition-colors flex-grow md:flex-grow-0 ${
                canAgree
                  ? "bg-[#112532] hover:bg-[#1f3d4e]"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              I Agree
            </button>
          </section>
        </div>
      </div>
    </>
  );
};

export default InstallerTermsAndConditions;
