import React, { useState, useRef, useLayoutEffect } from "react";

const HomeOwnerTermsAndConditions = ({ isOpen, onClose, onAgree }) => {
  const [canAgree, setCanAgree] = useState(false);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    const checkScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setCanAgree(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    const content = contentRef.current;

    const setupScrollListener = () => {
      if (content) {
        content.addEventListener("scroll", checkScroll);
      }
    };

    const timeoutId = setTimeout(setupScrollListener, 50);

    return () => {
      if (content) {
        content.removeEventListener("scroll", checkScroll);
      }
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleAgree = () => {
    onAgree();
    onClose();
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      />
      <div className="fixed inset-0 flex items-center justify-center z-50 md:p-4">
        <div className="bg-white w-full md:max-w-4xl h-full md:max-h-[90vh] rounded-[10px] shadow-lg overflow-hidden flex flex-col">
          <h1 className="p-8 pb-6 text-[#112532] text-[24px] lg:text-[33px] font-semibold">
            Terms and Conditions
          </h1>
          <hr />
          <div ref={contentRef} className="overflow-y-auto flex-grow p-8">
            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                1. Acceptance of Terms
              </h2>
              <p className="text-gray-700 leading-relaxed">
                By using the Spark Solar website (“Site”) or services provided
                through Spark Solar (“Services”), you agree to these Terms &
                Conditions (“Agreement”) and our Privacy Policy. If you do not
                agree, you must not use the Site or Services. You will be
                required to accept these terms before proceeding with obtaining
                solar installation quotes or offers.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                2. Services Provided
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar provides an AI-powered platform designed to connect
                homeowners with solar installers. Our Services allow homeowners
                to submit information related to potential solar installations,
                which we will share with our network of solar installers to
                generate offers for solar installation services.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                3. User Information and Consent
              </h2>
              <p className="text-gray-700 leading-relaxed">
                By submitting your personal information, including but not
                limited to your name, email address, phone number, home address,
                electricity bill details, and any other relevant information,
                you agree to the following:
              </p>
              <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
                <li>
                  Your data will be shared with third-party solar installers who
                  may use it to contact you with offers and quotes for solar
                  installation services.
                </li>
                <li>
                  You consent to receive communication from these third-party
                  installers via email, phone calls, and other means for the
                  purpose of discussing solar installation opportunities.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                4. Data Collection and Use
              </h2>
              <p className="text-gray-700 leading-relaxed">
                We collect user data to facilitate connections between
                homeowners and solar installers. By using our Site and Services,
                you authorize us to:
              </p>
              <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
                <li>Collect and store your personal information.</li>
                <li>
                  Sell, transfer, or disclose this information to third-party
                  solar installers.
                </li>
                <li>
                  Use your information to enhance the services provided by Spark
                  Solar, including improving our platform and marketing efforts.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                5. No Guarantees or Endorsements
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar does not guarantee the performance, quality, or
                timeliness of services provided by any solar installer. We act
                solely as a platform to facilitate introductions between
                homeowners and installers, and we do not endorse, vet, or
                provide warranties for any specific installer.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                6. User Responsibilities
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Users are responsible for providing accurate and truthful
                information when using the Spark Solar platform. Any false or
                misleading information may result in the termination of access
                to the Services.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                7. Limitation of Liability
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar will not be held liable for any damages, direct or
                indirect, arising out of your use of the Site or Services. This
                includes but is not limited to damages resulting from the
                interaction or transaction with third-party installers.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                8. Intellectual Property
              </h2>
              <p className="text-gray-700 leading-relaxed">
                All content on the Spark Solar website, including but not
                limited to text, design, graphics, and logos, is the property of
                Spark Solar or its content suppliers and is protected by
                intellectual property laws. You may not reproduce, distribute,
                or create derivative works from any content on this site without
                prior written consent from Spark Solar.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">9. Indemnification</h2>
              <p className="text-gray-700 leading-relaxed">
                Users agree to indemnify and hold Spark Solar, its affiliates,
                and partners harmless from any claims, liabilities, damages, or
                costs, including legal fees, arising from their use of the Site
                or Services, or from their violation of these Terms &
                Conditions.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                10. Arbitration Clause
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Any disputes arising out of or relating to this Agreement shall
                be resolved through binding arbitration in accordance with the
                rules of the American Arbitration Association. The decision of
                the arbitrator shall be final and binding on the parties.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">11. Governing Law</h2>
              <p className="text-gray-700 leading-relaxed">
                This Agreement is governed by the laws of the State of South
                Carolina, without regard to its conflict of laws principles. Any
                disputes arising from these terms will be subject to the
                jurisdiction of the courts located in Greenville, South
                Carolina.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-semibold mb-4">
                12. Changes to Terms
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar reserves the right to update or modify these Terms &
                Conditions at any time. Users will be notified of significant
                changes, and continued use of the Site or Services constitutes
                acceptance of the updated terms.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">
                13. Termination of Use
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Spark Solar reserves the right to terminate or suspend access to
                the Site and Services for any user who violates these Terms &
                Conditions or engages in unlawful activities.
              </p>
            </section>
          </div>
          <hr />
          <section className="py-6 px-8 flex justify-end gap-x-3">
            <button
              onClick={onClose}
              className="px-10 py-3 rounded-[5px] border border-[#112532] font-bold hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleAgree}
              disabled={!canAgree}
              className={`px-10 py-3 rounded-[5px] text-[#fffefb] font-bold transition-colors ${
                canAgree
                  ? "bg-[#112532] hover:bg-[#1f3d4e]"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              I Agree
            </button>
          </section>
        </div>
      </div>
    </>
  );
};

export default HomeOwnerTermsAndConditions;
