import React, { useState } from "react";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import { DELETEofferById } from "services/installerServices";

const Tier = ({ toggleDrawer, data, tierName, setTrigger }) => {
  const offerId = data?.id || "";
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteOfferHandler = async () => {
    try {
      setIsLoading(true);
      const res = await DELETEofferById(offerId);
    } catch (error) {
    } finally {
      setIsLoading(false);
      setTrigger((state) => !state);
    }
  };

  return (
    <>
      {" "}
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          deleteOfferHandler();
          setOpen(false);
        }}
        title={`Delete ${tierName}?`}
        subTitle="Do you want to confirm the deletion of this system tier?"
        confirmText="Confirm Deletion"
      />
      <div className="px-8 py-6 border-1 border-[#D9D3CF] rounded bg-white">
        {data ? (
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <img loading="lazy" src="/first-tier.svg" alt="" />
                <h1 className="text-[20px] font-semibold text-[#112532]">
                  {tierName}
                </h1>
              </div>
              <div className="flex gap-x-2">
                <img
                  loading="lazy"
                  onClick={toggleDrawer("right", true, "UPDATE", offerId)}
                  className="w-6 cursor-pointer"
                  src="/edit.svg"
                  alt=""
                />
                <img
                  loading="lazy"
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="w-6 cursor-pointer"
                  src="/trash.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="md:pl-[76px] flex justify-between md:justify-normal md:gap-x-36">
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">System Type</h1>
                  <p className="text-[#112532] font-semibold">
                    {data.system_type}
                  </p>
                </div>
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">Solar Panel</h1>
                  <p className="text-[#112532] font-semibold">
                    {data.solar_panels}
                  </p>
                </div>
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">Inverter</h1>
                  <p className="text-[#112532] font-semibold">
                    {data.inverter}
                  </p>
                </div>
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">Racking</h1>
                  <p className="text-[#112532] font-semibold">{data.racking}</p>
                </div>
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">Battery</h1>
                  <p className="text-[#112532] font-semibold">{data.battery}</p>
                </div>
              </div>
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-3">
                  <h1 className="text-[#244255] text-[14px]">Pricing Method</h1>
                  <p className="text-[#112532] font-semibold">
                    {data.pricing_method === "material"
                      ? "Bill of Material"
                      : "$/Watt"}
                  </p>
                </div>
                {data.pricing_method === "watt" && (
                  <div className="flex flex-col gap-y-3">
                    <h1 className="text-[#244255] text-[14px]">Watt Price</h1>
                    <p className="text-[#112532] font-semibold">
                      {data.watt_price}
                    </p>
                  </div>
                )}

                {data.pricing_method === "material" && (
                  <>
                    <div className="flex flex-col gap-y-3">
                      <h1 className="text-[#244255] text-[14px]">
                        Solar Panel Price
                      </h1>
                      <p className="text-[#112532] font-semibold">
                        {data.solar_panel_price}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-3">
                      <h1 className="text-[#244255] text-[14px]">
                        Inverter Price
                      </h1>
                      <p className="text-[#112532] font-semibold">
                        {data.inverter_price}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-3">
                      <h1 className="text-[#244255] text-[14px]">
                        Racking Price
                      </h1>
                      <p className="text-[#112532] font-semibold">
                        {data.racking_price}
                      </p>
                    </div>
                  </>
                )}
                <div className="flex flex-col gap-y-3 mt-auto">
                  <h1 className="text-[#244255] text-[14px]">Battery Price</h1>
                  <p className="text-[#112532] font-semibold">
                    {data.battery_price}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <img loading="lazy" src="/first-tier.svg" alt="" />
                <h1 className="text-[20px] font-semibold text-[#112532]">
                  {tierName}
                </h1>
              </div>
              <div className="flex justify-center items-center flex-col gap-y-5">
                <div className="flex flex-col items-center gap-y-4">
                  <h1 className="text-[#112532] text-[19px] font-semibold">
                    Start customizing your tier!
                  </h1>
                  <p className="text-[#112532] text-sm md:text-[18px]">
                    You need to fill all the details of the tier.
                  </p>
                </div>
                <button
                  onClick={toggleDrawer("right", true, offerId)}
                  className="flex justify-center items-center gap-x-2 px-6 py-3 bg-[#112532] text-[#FFFEFB]"
                >
                  <img loading="lazy" src="/plus.svg" alt="plus icon" /> Add
                  System Tier
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Tier;
