import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSurveyQrCode } from "services/homeOwnerServices";
import IndoorAndOutDoorTabs from "./IndoorAndOutDoorTabs";

const SurveyQrCode = ({ setShouldRenderSteps }) => {
  const currentSurveyType = useSelector(
    (state) => state.siteSurvey.currentSurveyType
  );
  const [isLoading, setIsLoading] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const genrateQrCode = async () => {
    try {
      setIsLoading(true);
      const res = await getSurveyQrCode();
      setQrCode(res.qrCodeDataURL);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    genrateQrCode();
  }, []);

  return (
    <>
      <div className="flex flex-col md:items-center gap-y-2 py-6 md:py-10">
        <h1 className="font-semibold text-[28px]">Site Survey</h1>
        <p className=" md:text-center">
          Save up to $250 by completing this site survey
        </p>
      </div>
      <IndoorAndOutDoorTabs />
      <div className="flex flex-col items-center mt-8">
        <h1 className="text-xl font-semibold hidden md:block">
          Scan this QR Code to do the Survey on your Phone
        </h1>
        {isLoading ? (
          <div>loading...</div>
        ) : (
          <>
            <div className="hidden md:block">
              <img loading="lazy" src={qrCode} />
            </div>
          </>
        )}

        <div className=" flex flex-col items-center gap-y-7 pb-8">
          <p className="hidden md:block">OR</p>
          <button
            disabled={currentSurveyType === null}
            onClick={() => setShouldRenderSteps(true)}
            className={` py-3 px-10 rounded mt-28 lg:mt-0 hidden md:flex ${
              currentSurveyType === null
                ? "bg-[#D1D4D8] text-[#767F89] font-bold"
                : "bg-[#112532] text-white font-semibold"
            }`}
          >
            Continue on the Web
            <img loading="lazy" src="/right-pag.svg" alt="right icon" />
          </button>
          <button
            disabled={currentSurveyType === null}
            onClick={() => setShouldRenderSteps(true)}
            className={`flex items-center gap-x-2 py-3 px-10 rounded mt-28 lg:mt-0 md:hidden ${
              currentSurveyType === null
                ? "bg-[#D1D4D8] text-[#767F89] font-bold"
                : "bg-[#112532] text-white font-semibold"
            }`}
          >
            Continue
            {currentSurveyType === null ? (
              <img loading="lazy" src="/right-pag.svg" alt="right icon" />
            ) : (
              <img
                loading="lazy"
                src="/chevron_right-WHITE.svg"
                alt="right icon"
              />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SurveyQrCode;
