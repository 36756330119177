import React from "react";
import { Skeleton } from "@mui/material";

const DealOverviewSkeleton = () => {
  return (
    <div className="flex justify-between ml-6 gap-x-4">
      <div className="flex-1 flex flex-col gap-y-4">
        {/* Breadcrumb Skeleton */}
        <div className="breadcrumb-section">
          <Skeleton variant="text" width={300} height={30} />
        </div>

        {/* Profile Header Section */}
        <div className="px-8 py-6 flex flex-col gap-y-4 rounded-lg shadow border border-[#d9d3cf] bg-white">
          <div className="flex items-center">
            <Skeleton
              variant="circular"
              width={64}
              height={64}
              className="mr-3"
            />
            <Skeleton variant="text" width={300} height={50} />
          </div>
          <div className="flex justify-between items-center">
            <Skeleton variant="text" width={200} height={24} />
            <Skeleton variant="rounded" width={150} height={40} />
          </div>
          <hr />
          <div className="flex flex-col gap-y-3">
            {[1, 2, 3].map((item) => (
              <div key={item} className="flex gap-x-2 py-2">
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton variant="text" width={250} height={24} />
              </div>
            ))}
          </div>
        </div>

        {/* Deal Details Header */}
        <div className="flex justify-between py-4">
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="rounded" width={120} height={40} />
        </div>

        {/* Solar Design Section */}
        <div className="px-8 py-6 flex flex-col gap-y-5 rounded-lg shadow border border-[#d9d3cf] bg-white">
          <Skeleton variant="text" width={150} height={30} />
          <div className="flex gap-x-3 px-4 py-3 bg-[#F8FEFF] border-l-[5px] border-[#287192] rounded">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width="90%" height={24} />
          </div>
          <Skeleton variant="rectangular" height={200} />
          <div className="px-4 py-4">
            <div className="flex items-center gap-x-2 mb-2">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="text" width={100} height={24} />
            </div>
            <div className="flex justify-between">
              {[1, 2, 3].map((item) => (
                <div key={item} className="flex flex-col gap-y-2">
                  <Skeleton variant="text" width={100} height={40} />
                  <Skeleton variant="text" width={80} height={24} />
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="px-4 py-2">
            <div className="flex items-center gap-x-2 mb-2">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="text" width={100} height={24} />
            </div>
            <div className="flex justify-between">
              {[1, 2, 3].map((item) => (
                <div key={item} className="flex flex-col gap-y-2">
                  <Skeleton variant="text" width={100} height={40} />
                  <Skeleton variant="text" width={80} height={24} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* System Tier Section */}
        <div className="flex flex-col gap-y-5 px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
          <Skeleton variant="text" width={150} height={30} />
          <div className="flex gap-x-4">
            <Skeleton variant="circular" width={56} height={56} />
            <div className="flex flex-col gap-y-2 flex-1">
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width="80%" height={24} />
              <div className="flex gap-x-3">
                <Skeleton variant="text" width={150} height={24} />
                <Skeleton variant="text" width={150} height={24} />
              </div>
            </div>
          </div>
        </div>

        {/* Site Survey Section */}
        <div className="flex flex-col gap-y-5 px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
          <div className="flex justify-between">
            <Skeleton variant="text" width={150} height={30} />
          </div>
          <div className="grid grid-cols-4 gap-4">
            {[...Array(8)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={132}
                height={132}
                sx={{ borderRadius: "8px" }}
              />
            ))}
          </div>
        </div>

        {/* Additional Information Section */}
        <div className="flex flex-col gap-y-4 px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
          <Skeleton variant="text" width={200} height={30} />
          <div className="flex flex-col gap-y-3">
            <Skeleton variant="text" width={100} height={24} />
            <div className="flex items-center gap-x-3">
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={150} height={24} />
            </div>
          </div>
          <div className="flex gap-x-6">
            {[1, 2].map((item) => (
              <div key={item} className="flex flex-col gap-y-3 w-1/3">
                <Skeleton variant="text" width={150} height={24} />
                <Skeleton variant="text" width={100} height={24} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Sidebar */}
      <div className="w-1/3 h-screen flex flex-col gap-y-4 border-1 px-8 py-6 rounded-lg shadow border border-[#d9d3cf]">
        <Skeleton variant="text" width={150} height={30} />
        <div className="flex gap-x-2 mb-4">
          {[1, 2, 3].map((tab) => (
            <Skeleton key={tab} variant="rounded" width={80} height={40} />
          ))}
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-4 bg-[#f7f6f5] rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex gap-x-2">
                <Skeleton variant="circular" width={24} height={24} />
                <div className="flex flex-col gap-y-2">
                  <Skeleton variant="text" width={150} height={24} />
                  <Skeleton variant="text" width={200} height={20} />
                </div>
              </div>
              <Skeleton variant="circular" width={24} height={24} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealOverviewSkeleton;
