import io from "socket.io-client";

let socket;

export const initializeSocket = (token, role) => {
  if (!socket) {
    socket = io("https://sparksolar.io:3060", {
      query: { token, role },
      reconnection: true,
    });

    // Example event listeners
    socket.on("connect", () => console.log("Socket connected"));
    socket.on("disconnect", () => console.log("Socket disconnected"));
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
