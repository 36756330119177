// CustomizeTabs.js
import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { display, padding, styled } from "@mui/system";

// Custom StyledTabs
const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .MuiTabs-indicator`]: {
    backgroundColor: "#F59E0B", // Custom indicator color
    height: 4, // Thicker indicator
  },
  [`& .MuiTabs-flexContainer`]: {
    justifyContent: "start", // Center the tabs
  },
  [`& .MuiTabs-scrollButtons`]: {
    color: "#FF6347", // Scroll button color
  },
  [`& .MuiTabs-scrollButtons.Mui-disabled`]: {
    opacity: 0.3, // Disabled scroll button style
    padding: "0",
  },
  [`& .MuiTabs-root`]: {
    minHeight: 48, // Adjust tab height
  },
  [`& .MuiTabs-scroller`]: {
    // padding: theme.spacing(0.5), // Tab scroller padding
  },
  padding: "0",
}));

// Custom StyledTab
const StyledTab = styled(Tab)(({ theme, minWidth }) => ({
  "&.MuiTab-root": {
    // minWidth: minWidth || "calc(100%/3)", // Minimum tab width
    padding: theme.spacing(1),
    textTransform: "none",
    fontWeight: "100", // Tab font weight
    "&.Mui-selected": {
      color: "#112532", // Selected tab text color
      fontWeight: "600",
    },
  },
  "&.MuiTab-labelIcon": {
    minHeight: 48, // Minimum height for icon tabs
  },
  "&.MuiTab-textColorPrimary": {
    color: "#112532",
  },
  "&.MuiTab-textColorSecondary": {
    color: "#888", // Secondary text color
  },
  "&.Mui-disabled": {
    color: "#ccc", // Disabled tab color
  },
  fontSize: 16, // Font size of tabs
}));

// Custom StyledTabPanel
const StyledTabPanel = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  [`& .MuiBox-root`]: {
    width: "100%", // Full width for content
    marginTop: theme.spacing(2), // Top margin
  },
}));

// Main CustomizeTabs component
const CustomizeTabs = ({
  value,
  onChange,
  tabs,
  tabPanels,
  tabsWidth,
  ...props
}) => {
  return (
    <Box>
      {/* Styled Tabs */}
      <StyledTabs
        sx={{ width: tabsWidth }}
        value={value}
        onChange={onChange}
        {...props}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            label={tab.label}
            icon={tab.icon} // Add icon here
            iconPosition="start" // Move icon to the left of the label
            disabled={tab.disabled}
            onClick={tab.onClick}
            sx={tab.sx}
            {...tab.props}
          />
        ))}
      </StyledTabs>
      <hr className=" mb-4 border-[#D9D3CF]" />

      {/* Tab Panels */}

      {tabPanels?.map((panel, index) => (
        <StyledTabPanel
          key={index}
          role="tabpanel"
          hidden={value !== index}
          {...panel.props}
        >
          {value === index && panel.content}
        </StyledTabPanel>
      ))}
    </Box>
  );
};

// Export the reusable components
export { CustomizeTabs, StyledTabs, StyledTab, StyledTabPanel };
