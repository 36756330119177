import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import {
  GETavailableSystemTypes,
  POSTnewOffer,
  putOfferUpdate,
} from "services/installerServices";
import { systemTierSchema } from "validation-schema/installer";

// Options data
const solarPanelsOptions = [
  {
    id: 1,
    description:
      "SOLAR CLAM-P - SOLAR PANEL MOUNTING KIT 6 panels MILL MICRO J-BOX SKIRT\n",
  },
];

const invertersOptions = [
  {
    id: 4,
    description:
      "10.2KW 48V Solar Hybrid Inverter Off Grid 180A MPPT Sine Wave Solar Controller\n",
  },
];

const batteryOptions = [
  {
    id: 895,
    description: "Hulk 12V Lithium Battery Lifepo4 - 200Ah\n",
  },
];

const rackingOptions = [
  {
    id: 9,
    description:
      "Solar Rail Alu Mounting Rail Solar Panel PV Profile Solar System Accessories\n",
  },
];

const EquipmentListForm = ({
  toggleDrawer,
  formType,
  formData,
  offerId,
  setTrigger,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availableSystemTypes, setAvailableSystemTypes] = useState();
  const navigate = useNavigate();

  const currentData = formData.filter((data) => data.id === offerId)[0];

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(systemTierSchema),
    defaultValues: {
      pricing_method: "watt",
      system_type: "",
      solar_panels: "",
      inverter: "",
      racking: "",
      battery: "",
      solar_panel_price: "",
      inverter_price: "",
      racking_price: "",
      battery_price: "",
      watt_price: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (formType === "UPDATE") {
      setValue("pricing_method", currentData.pricing_method);
      setValue("system_type", currentData.system_type);
      setValue("solar_panels", currentData.solar_panels);
      setValue("inverter", currentData.inverter);
      setValue("racking", currentData.racking);
      setValue("battery", currentData.battery);
      setValue("solar_panel_price", currentData.solar_panel_price);
      setValue("inverter_price", currentData.inverter_price);
      setValue("racking_price", currentData.racking_price);
      setValue("battery_price", currentData.battery_price);
      setValue("watt_price", currentData.watt_price);
    }
  }, []);

  useEffect(() => {
    fetchAvailableSystemTypes();
  }, []);

  const fetchAvailableSystemTypes = async () => {
    try {
      setIsLoading(true);
      const res = await GETavailableSystemTypes();
      setAvailableSystemTypes(res.availableSystemTypes);
    } catch (error) {
      console.error("Error fetching system types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    if (formType === "UPDATE") {
      try {
        setIsLoading(true);
        const res = await putOfferUpdate(data, offerId);
      } catch (error) {
        console.error("Error updating offer:", error);
      } finally {
        setIsLoading(false);
        setTrigger((state) => !state);
      }
    } else {
      try {
        setIsLoading(true);
        const res = await POSTnewOffer(data);
      } catch (error) {
        console.error("Error creating new offer:", error);
      } finally {
        setIsLoading(false);
        setTrigger((state) => !state);
      }
    }
  };

  const list = () => (
    <Box
      sx={{
        width: { xs: "100%", md: 500 },
        height: "100%",
      }}
    >
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={(event) => {
          setOpen(false);
          toggleDrawer("right", false)(event);
        }}
        title={`Discard Changes?`}
        subTitle="Do you want to Discard Changes this system tier?"
        confirmText="Confirm"
      />
      <div className="px-4 md:px-10 py-6 flex flex-col gap-y-5  lg:h-full">
        <h1 className="mb-3 text-[23px] text-[#112532] font-semibold">
          {formType === "UPDATE" ? "Edit Tier" : "Add System Tier"}
        </h1>
        <FormControl fullWidth>
          <div className="flex flex-col gap-y-4 md:gap-y-6">
            {/* System Type Select */}
            <div className="w-full">
              <InputLabel id="demo-simple-select-label">System type</InputLabel>
              <Controller
                name="system_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="System Type"
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  >
                    <MenuItem
                      value={"Basic"}
                      disabled={!availableSystemTypes?.includes("Basic")}
                    >
                      Basic
                    </MenuItem>
                    <MenuItem
                      value={"Standard"}
                      disabled={!availableSystemTypes?.includes("Standard")}
                    >
                      Standard
                    </MenuItem>
                    <MenuItem
                      value={"Premium"}
                      disabled={!availableSystemTypes?.includes("Premium")}
                    >
                      Premium
                    </MenuItem>
                  </Select>
                )}
              />
            </div>

            {/* Pricing Method Radio Group */}
            <div className="w-full">
              <h1>Pricing Method</h1>
              <Controller
                name="pricing_method"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e.target.value);
                      if (formType !== "UPDATE") {
                        reset({
                          ...watch(),
                          pricing_method: e.target.value,
                          solar_panels: "",
                          inverter: "",
                          racking: "",
                          battery: "",
                          solar_panel_price: "",
                          inverter_price: "",
                          racking_price: "",
                          battery_price: "",
                          watt_price: "",
                        });
                      }
                    }}
                  >
                    <div className="flex gap-2 sm:gap-4">
                      <FormControlLabel
                        value="watt"
                        control={<Radio />}
                        label="$/Watt"
                      />
                      <FormControlLabel
                        value="material"
                        control={<Radio />}
                        label="Bill of Material"
                      />
                    </div>
                  </RadioGroup>
                )}
              />
            </div>
            {/* Watt Pricing Fields */}
            {watch().pricing_method === "watt" ? (
              <div className="flex flex-col gap-y-4 md:gap-y-6">
                <Controller
                  name="watt_price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={errors.watt_price && true}
                      helperText={
                        errors.watt_price && errors.watt_price.message
                      }
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (
                          [
                            "-",
                            "+",
                            "e",
                            "E",
                            ".",
                            "ArrowUp",
                            "ArrowDown",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      sx={{
                        "& label.Mui-focused": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#66a1c7",
                          },
                        },
                      }}
                      className="w-full"
                      label="Price/Watt"
                      type="number"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              src="/bi_currency-dollar.svg"
                              alt="money icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <div className="space-y-4 md:space-y-6">
                  {/* Solar Panels */}
                  <Controller
                    name="solar_panels"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={solarPanelsOptions}
                        getOptionLabel={(option) => option.description || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        value={
                          value
                            ? {
                                id: value,
                                description: solarPanelsOptions.find(
                                  (opt) => opt.id === value
                                )?.description,
                              }
                            : null
                        }
                        onChange={(e, newValue) => {
                          onChange(newValue ? newValue.id : null);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            error={errors.solar_panels && true}
                            helperText={
                              errors.solar_panels && errors.solar_panels.message
                            }
                            {...params}
                            label="Solar Panel"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    loading="lazy"
                                    src="/search.svg"
                                    alt="search icon"
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* Racking */}
                  <Controller
                    name="racking"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={rackingOptions}
                        getOptionLabel={(option) => option.description || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        value={
                          value
                            ? {
                                id: value,
                                description: rackingOptions.find(
                                  (opt) => opt.id === value
                                )?.description,
                              }
                            : null
                        }
                        onChange={(e, newValue) => {
                          onChange(newValue ? newValue.id : null);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            error={errors.racking && true}
                            helperText={
                              errors.racking && errors.racking.message
                            }
                            {...params}
                            label="Racking"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    loading="lazy"
                                    src="/search.svg"
                                    alt="search icon"
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* Inverter */}
                  <Controller
                    name="inverter"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={invertersOptions}
                        getOptionLabel={(option) => option.description || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        value={
                          value
                            ? {
                                id: value,
                                description: invertersOptions.find(
                                  (opt) => opt.id === value
                                )?.description,
                              }
                            : null
                        }
                        onChange={(e, newValue) => {
                          onChange(newValue ? newValue.id : null);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            error={errors.inverter && true}
                            helperText={
                              errors.inverter && errors.inverter.message
                            }
                            {...params}
                            label="Inverter"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    loading="lazy"
                                    src="/search.svg"
                                    alt="search icon"
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* Battery */}
                  <Controller
                    name="battery"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={batteryOptions}
                        getOptionLabel={(option) => option.description || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        value={
                          value
                            ? {
                                id: value,
                                description: batteryOptions.find(
                                  (opt) => opt.id === value
                                )?.description,
                              }
                            : null
                        }
                        onChange={(e, newValue) => {
                          onChange(newValue ? newValue.id : null);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            error={errors.battery && true}
                            helperText={
                              errors.battery && errors.battery.message
                            }
                            {...params}
                            label="Battery"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    loading="lazy"
                                    src="/search.svg"
                                    alt="search icon"
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* Battery Price */}
                  <Controller
                    name="battery_price"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        error={errors.battery_price && true}
                        helperText={
                          errors.battery_price && errors.battery_price.message
                        }
                        value={value || ""}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (
                            [
                              "-",
                              "+",
                              "e",
                              "E",
                              ".",
                              "ArrowUp",
                              "ArrowDown",
                            ].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        sx={{
                          "& label.Mui-focused": {
                            color: "black",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#66a1c7",
                            },
                          },
                        }}
                        className="w-full"
                        label="Battery Price"
                        type="number"
                        inputProps={{
                          min: 0,
                          step: "0.01",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                loading="lazy"
                                src="/bi_currency-dollar.svg"
                                alt="money icon"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              // Material Pricing Fields (continuing from the else statement)
              <div className="flex flex-col gap-y-4 md:gap-y-6">
                <div className="space-y-4 md:space-y-6">
                  {/* Solar Panel Group */}
                  <div className="flex flex-col md:flex-row gap-4 md:gap-x-3">
                    <div className="w-full md:w-2/3">
                      <Controller
                        name="solar_panels"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            fullWidth
                            options={solarPanelsOptions}
                            getOptionLabel={(option) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value?.id
                            }
                            value={
                              value
                                ? {
                                    id: value,
                                    description: solarPanelsOptions.find(
                                      (opt) => opt.id === value
                                    )?.description,
                                  }
                                : null
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue ? newValue.id : null);
                            }}
                            blurOnSelect
                            renderInput={(params) => (
                              <TextField
                                error={errors.solar_panels && true}
                                helperText={
                                  errors.solar_panels &&
                                  errors.solar_panels.message
                                }
                                {...params}
                                label="Solar Panel"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        loading="lazy"
                                        src="/search.svg"
                                        alt="search icon"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <Controller
                        name="solar_panel_price"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={errors.solar_panel_price && true}
                            helperText={
                              errors.solar_panel_price &&
                              errors.solar_panel_price.message
                            }
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                [
                                  "-",
                                  "+",
                                  "e",
                                  "E",
                                  ".",
                                  "ArrowUp",
                                  "ArrowDown",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            sx={{
                              "& label.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#66a1c7",
                                },
                              },
                            }}
                            className="w-full"
                            label="Solar Panel Price"
                            type="number"
                            inputProps={{
                              min: 0,
                              step: "0.01",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    loading="lazy"
                                    src="/bi_currency-dollar.svg"
                                    alt="money icon"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* Inverter Group */}
                  <div className="flex flex-col md:flex-row gap-4 md:gap-x-3">
                    <div className="w-full md:w-2/3">
                      <Controller
                        name="inverter"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            fullWidth
                            options={invertersOptions}
                            getOptionLabel={(option) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value?.id
                            }
                            value={
                              value
                                ? {
                                    id: value,
                                    description: invertersOptions.find(
                                      (opt) => opt.id === value
                                    )?.description,
                                  }
                                : null
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue ? newValue.id : null);
                            }}
                            blurOnSelect
                            renderInput={(params) => (
                              <TextField
                                error={errors.inverter && true}
                                helperText={
                                  errors.inverter && errors.inverter.message
                                }
                                {...params}
                                label="Inverter"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        loading="lazy"
                                        src="/search.svg"
                                        alt="search icon"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <Controller
                        name="inverter_price"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={errors.inverter_price && true}
                            helperText={
                              errors.inverter_price &&
                              errors.inverter_price.message
                            }
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                [
                                  "-",
                                  "+",
                                  "e",
                                  "E",
                                  ".",
                                  "ArrowUp",
                                  "ArrowDown",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            sx={{
                              "& label.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#66a1c7",
                                },
                              },
                            }}
                            className="w-full"
                            label="Inverter Price"
                            type="number"
                            inputProps={{
                              min: 0,
                              step: "0.01",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    loading="lazy"
                                    src="/bi_currency-dollar.svg"
                                    alt="money icon"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* Racking Group */}
                  <div className="flex flex-col md:flex-row gap-4 md:gap-x-3">
                    <div className="w-full md:w-2/3">
                      <Controller
                        name="racking"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            fullWidth
                            options={rackingOptions}
                            getOptionLabel={(option) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value?.id
                            }
                            value={
                              value
                                ? {
                                    id: value,
                                    description: rackingOptions.find(
                                      (opt) => opt.id === value
                                    )?.description,
                                  }
                                : null
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue ? newValue.id : null);
                            }}
                            blurOnSelect
                            renderInput={(params) => (
                              <TextField
                                error={errors.racking && true}
                                helperText={
                                  errors.racking && errors.racking.message
                                }
                                {...params}
                                label="Racking"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        loading="lazy"
                                        src="/search.svg"
                                        alt="search icon"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <Controller
                        name="racking_price"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={errors.racking_price && true}
                            helperText={
                              errors.racking_price &&
                              errors.racking_price.message
                            }
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                [
                                  "-",
                                  "+",
                                  "e",
                                  "E",
                                  ".",
                                  "ArrowUp",
                                  "ArrowDown",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            sx={{
                              "& label.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#66a1c7",
                                },
                              },
                            }}
                            className="w-full"
                            label="Racking Price"
                            type="number"
                            inputProps={{
                              min: 0,
                              step: "0.01",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    loading="lazy"
                                    src="/bi_currency-dollar.svg"
                                    alt="money icon"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* Battery Group */}
                  <div className="flex flex-col md:flex-row gap-4 md:gap-x-3">
                    <div className="w-full md:w-2/3">
                      <Controller
                        name="battery"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            fullWidth
                            options={batteryOptions}
                            getOptionLabel={(option) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value?.id
                            }
                            value={
                              value
                                ? {
                                    id: value,
                                    description: batteryOptions.find(
                                      (opt) => opt.id === value
                                    )?.description,
                                  }
                                : null
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue ? newValue.id : null);
                            }}
                            blurOnSelect
                            renderInput={(params) => (
                              <TextField
                                error={errors.battery && true}
                                helperText={
                                  errors.battery && errors.battery.message
                                }
                                {...params}
                                label="Battery"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        loading="lazy"
                                        src="/search.svg"
                                        alt="search icon"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <Controller
                        name="battery_price"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={errors.battery_price && true}
                            helperText={
                              errors.battery_price &&
                              errors.battery_price.message
                            }
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                [
                                  "-",
                                  "+",
                                  "e",
                                  "E",
                                  ".",
                                  "ArrowUp",
                                  "ArrowDown",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            sx={{
                              "& label.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#66a1c7",
                                },
                              },
                            }}
                            className="w-full"
                            label="Battery Price"
                            type="number"
                            inputProps={{
                              min: 0,
                              step: "0.01",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    loading="lazy"
                                    src="/bi_currency-dollar.svg"
                                    alt="money icon"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </FormControl>
        {/* Form Buttons */}
        <div className="flex items-end gap-4 md:gap-x-3 mt-6 flex-end h-full">
          <button
            onClick={(event) => {
              setOpen(isValid);
              if (!isValid) toggleDrawer("right", false)(event);
            }}
            className="py-3 px-6 md:px-10 border border-[#112532] rounded w-full md:w-auto h-fit"
          >
            {`${formType === "UPDATE" ? "Discard" : "Cancel"}`}
          </button>
          <button
            disabled={!isValid}
            onClick={(event) => {
              handleSubmit(onSubmit(watch()));
              toggleDrawer("right", false)(event);
            }}
            className={`py-3 px-6 md:px-10 text-[white] bg-[#112532] rounded w-full md:w-auto h-fit ${
              isValid ? "" : "opacity-50"
            }`}
          >
            {`${formType === "UPDATE" ? "Save Changes " : "Add"}`}
          </button>
        </div>
      </div>
    </Box>
  );

  return <div className="w-full">{list()}</div>;
};

export default EquipmentListForm;
