import dayjs from "dayjs";

const MessageBar = ({ message, setChatInfo }) => {
  const useRole = localStorage.getItem("USER");

  // Helper variables to avoid repetition
  const isInstaller = useRole === "installer";
  const isHomeOwner = useRole === "homeOwner";

  const userName = isInstaller
    ? `${message?.receiver?.first_name} ${message?.receiver?.last_name}`
    : isHomeOwner
      ? `${message?.receiver?.business_name}`
      : "Unknown User";

  const receiverId = message?.receiver?.id;

  const userImage = isInstaller
    ? message?.receiver?.profile_img || "/default-profile.svg"
    : isHomeOwner
      ? message?.receiver?.logo || "/defaultLogo.svg"
      : "/defaultLogo.svg";

  const messageDate = dayjs(message?.updatedAt).fromNow();
  const time = dayjs(message?.createdAt).format("hh:mm A");
  const handleClick = () => {
    setChatInfo({
      userName,
      chatId: message?.id,
      receiverId,
      seen: !message?.messageCount,
      userData: {
        image: userImage,
      },
    });
  };

  return (
    <div className="w-full flex flex-col gap-y-2" onClick={handleClick}>
      <h1 className="px-4 text-[12px] text-[#244255]">{messageDate}</h1>
      <div className="flex gap-x-2 cursor-pointer hover:bg-[#F7F6F5] px-5 py-4 relative">
        <img
          loading="lazy"
          className="rounded-full w-8 h-8"
          src={userImage}
          alt="User Avatar"
        />

        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-[#112532] font-semibold">{userName}</h1>
            <p
              className="text-[14px] text-[#112532] overflow-hidden text-ellipsis whitespace-nowrap"
              style={{ maxWidth: "200px" }}
            >
              {message?.lastMessage || "No message available"}
            </p>
          </div>
          <div className="flex flex-col justify-between">
            <p className="text-[10px] text-[#94847B] self-start">{time}</p>
            {/* {message?.ChatsMessages[0]?.unreadCount > 0 && (
              <div className="w-6 h-6 px-0.5 py-1 bg-amber-600 rounded-[15px] flex justify-center items-center text-white text-sm font-semibold self-center">
                {message?.ChatsMessages[0]?.unreadCount}
              </div>
            )} */}
          </div>
        </div>

        {/* Pseudo-element for the border */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90%] border-b border-[#ECECEE]"></div>
      </div>
    </div>
  );
};

export default MessageBar;
