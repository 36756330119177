import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TableSortLabel,
  TextField,
} from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { formatPrice } from "utils/formatNumber";
import { IosSwitch } from "components/MUI/IosSwitch";
import { classOptions } from "utils/constant";

const StyledTableCell = styled(TableCell)(
  ({ theme, "data-frozen": frozen, index }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "500",
    },
    ...(frozen && {
      position: "sticky",
      left: index === 0 ? "-2px" : "100.08px",
      zIndex: 2,
      backgroundColor: "white",
      filter: "drop-shadow(8px 0 6px rgba(203, 197, 180, 0.21))",
      borderRight: "1px solid rgba(203, 197, 180, 0.15)",
    }),
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    "& table": {
      minWidth: "100%",
    },
    "& th, & td": {
      width: { sm: "75px", md: "150px" },
    },
    "& th:first-of-type, & td:first-of-type, & th:nth-of-type(2), & td:nth-of-type(2)":
      {
        position: "sticky",
        backgroundColor: "white",
        // zIndex: 1,
      },
  },
}));

const AllInstallersTable = () => {
  const [sortedRows, setSortedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = () => (
    <Box
      sx={{
        width: { xs: "100vw", sm: "400px", md: "480px" },
        // height: { xs: "80%", md: "100%" },
      }}
      role="presentation"
    >
      <div className="h-full p-4 md:p-10 flex flex-col gap-y-5 pb-[120px] md:pb-10">
        <div className="sticky top-0 bg-white z-10 py-3 border-b border-[#C7CACE]">
          <div className="flex justify-between items-center ">
            <h1 className="text-[23px] text-[#112532] font-semibold py-2 md:py-4">
              Apply Filters
            </h1>
            <CloseIcon
              className="cursor-pointer md:hidden"
              onClick={toggleDrawer("right", false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-6 h-full">
          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">
              Class Filter
            </h2>

            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Unclassified"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Silver"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Gold"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Platinum"
            />
          </FormGroup>
          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">
              Business Operation
            </h2>

            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Local"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Regional"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="National"
            />
          </FormGroup>
          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">Status</h2>

            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Active"
            />
            <FormControlLabel
              control={<Checkbox checked={false} onChange={(e) => {}} />}
              label="Inactive"
            />
          </FormGroup>
          <div className="flex gap-x-3 items-center">
            <TextField
              fullWidth
              label="Min"
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                "& .MuiFormHelperText-root": {
                  backgroundColor: "white",
                  padding: "3px 14px 0px",
                  margin: 0,
                },
              }}
            />
            -
            <TextField
              fullWidth
              label="Max"
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                "& .MuiFormHelperText-root": {
                  backgroundColor: "white",
                  padding: "3px 14px 0px",
                  margin: 0,
                },
              }}
            />
          </div>
          <div className="h-full flex justify-end items-end gap-x-3">
            <button
              onClick={(event) => {
                toggleDrawer("right", false)(event);
              }}
              className="py-3 px-10 border border-[#112532] rounded text-sm md:text-base w-full lg:w-auto"
            >
              Cancel
            </button>
            <button
              onClick={(event) => {
                toggleDrawer("right", false)(event);
              }}
              className="py-3 px-10 text-white bg-[#112532] rounded text-sm md:text-base w-full lg:w-auto"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Box>
  );
  return (
    <div className="p-6 flex flex-col gap-y-4">
      <div className="flex justify-between">
        <h1 className="text-black text-[28px] font-semibold">All Installers</h1>
        <p>Export</p>
      </div>
      <div className="flex flex-col gap-y-1">
        <TextField
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              border: "none",
              "& fieldset": {
                border: "none",
              },
            },
          }}
          fullWidth
          onChange={() => {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  loading="lazy"
                  src={false ? "/search-active.svg" : "/search.svg"}
                  alt="search icon"
                  className="w-5 md:w-auto"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <img
                  loading="lazy"
                  onClick={toggleDrawer("right", true)}
                  className="cursor-pointer w-auto"
                  src={false ? "/Filter-active.svg" : "/Filter.svg"}
                  alt="filter icon"
                />
              </InputAdornment>
            ),
          }}
          placeholder="Search ID, name or state..."
        />
        <Drawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          sx={{
            "& .MuiBackdrop-root": {
              background: "rgba(17, 37, 50, 0.5)",
            },
          }}
          PaperProps={{
            sx: {
              width: { xs: "100%", sm: "400px", md: 500 },
              height: { xs: "85%", md: "100%" },
              bottom: 0,
              top: "auto",
              "& > div:first-of-type": {
                height: "100%",
              },
            },
          }}
        >
          {list()}
        </Drawer>
        <CustomizedTables
          recordsArray={rows}
          setSortedRows={setSortedRows}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      </div>
    </div>
  );
};

export default AllInstallersTable;

const rows = Array.from({ length: 15 }, (_, i) => ({
  id: i + 1,
  User: {
    first_name: `installer`,
    last_name: `${i + 1}`,
    class: ["Platinum", "Gold", "Silver", undefined][
      Math.floor(Math.random() * 4)
    ],
  },
  businessOperation: Math.random() > 0.5,
  state: "South Carolina",
  system_type: ["Premium", "Standard", "Basic"][Math.floor(Math.random() * 3)],
  sparkFees: Math.floor(Math.random() * 20 + 1),
  active: Math.random() > 0.5,
}));

const CustomizedTables = ({
  recordsArray,
  setSelectedRows,
  selectedRows,
  setSortedRows,
}) => {
  const { isSm } = useResponsive();

  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("myDealsPageNumber");
    return savedPage ? parseInt(savedPage) : 0;
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // Set initial orderBy to empty string

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(sortedRows.slice(page * 10, page * 10 + 10));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRequestSort = (event, property) => {
    let newOrder;
    if (orderBy !== property) {
      newOrder = "asc";
      setOrder("asc");
      setOrderBy(property);
    } else {
      if (order === "asc") {
        newOrder = "desc";
        setOrder("desc");
      } else if (order === "desc") {
        newOrder = null;
        setOrder(null);
      } else {
        newOrder = "asc";
        setOrder("asc");
      }
    }

    // Sort the entire array
    const newSortedRows = recordsArray
      .slice()
      .sort(getComparator(newOrder, property));

    // Set the entire sorted array as sorted rows
    setSortedRows(newSortedRows);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    const valueA = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], a)
      : a[orderBy];
    const valueB = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], b)
      : b[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    if (order === null) {
      return (a, b) => 0; // Return default, unsorted rows
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const rows = recordsArray;
  const emptyRows = Math.max(0, (page + 1) * 10 - rows.length);
  const sortedRows = rows.slice().sort(getComparator(order, orderBy));

  useEffect(() => {
    sessionStorage.setItem("myDealsPageNumber", page.toString());
  }, [page]);

  // useEffect(() => {
  //   setPage(0);
  //   sessionStorage.setItem("myDealsPageNumber", "0");
  // }, [recordsArray]);

  return (
    <TableWrapper>
      <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={0}
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
                align="left"
              >
                <div className="flex">
                  <Checkbox
                    size="small"
                    checked={
                      sortedRows.length > 0 &&
                      selectedRows.length ===
                        sortedRows.slice(page * 10, page * 10 + 10).length
                    }
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <
                        sortedRows.slice(page * 10, page * 10 + 10).length
                    }
                    sx={{
                      visibility:
                        selectedRows.length > 0 ? "visible" : "hidden", // Show when selected
                      "&:hover": {
                        visibility: "visible", // Show on hover
                      },
                      ".MuiTableRow-root:hover &": {
                        visibility: "visible", // Show when row is hovered
                      },
                    }}
                    onChange={handleSelectAllClick}
                  />

                  <TableSortLabel
                    className="text-[13px]"
                    active={orderBy === "id"}
                    direction={order !== null ? order : undefined}
                    onClick={(event) => handleRequestSort(event, "id")}
                    sx={{
                      "&:hover .MuiTableSortLabel-icon": {
                        visibility: "visible !important",
                      },
                      ".MuiTableSortLabel-icon": {
                        visibility: order !== null ? "visible" : "hidden",
                      },
                    }}
                  >
                    ID
                  </TableSortLabel>
                </div>
              </StyledTableCell>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={1}
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "User.first_name"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "User.first_name")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "state"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "state")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  State&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "businessOperation"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "businessOperation")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Business Operation&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              {/* <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "system_type"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "system_type")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Tiers&nbsp;
                </TableSortLabel>
              </StyledTableCell> */}
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "User.class"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "User.class")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Class&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "sparkFees"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "sparkFees")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Spark Fees ($/Watt)&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  className="text-[13px]"
                  active={orderBy === "active"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "active")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Activation&nbsp;
                </TableSortLabel>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.slice(page * 10, page * 10 + 10).map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{
                  bgcolor: "white",
                  border: "0px solid #F9F8F8",
                  borderWidth: "4px 2px",
                }}
              >
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={0}
                  component="th"
                  scope="row"
                >
                  <div className=" flex items-center">
                    <Checkbox
                      size="small"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      sx={{
                        visibility: selectedRows.some(
                          (selectedRow) => selectedRow.id === row.id
                        )
                          ? "visible"
                          : "hidden",
                        "&:hover": {
                          visibility: "visible",
                        },
                        ".MuiTableRow-root:hover &": {
                          visibility: "visible",
                        },
                      }}
                      onChange={(event) => handleSelectRow(event, row)}
                    />

                    {row.id}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={1}
                  align="left"
                  sx={{ color: "#D0680F" }}
                >
                  <Link to={`${row.id}`}>
                    {`${row.User.first_name} ${row.User.last_name}`}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="left">{row.state}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.businessOperation
                    ? "Local"
                    : row.businessOperation
                      ? "Regional"
                      : "National"}
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ paddingLeft: 0 }}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className="flex items-center gap-x-2"
                      // shrink={true}
                    >
                      <img className="h-7 w-7" src="/none-class.svg" alt="" />
                      Select
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Select"
                      value={row.User.class || null}
                      renderValue={(selected) => {
                        const selectedItem = classOptions.find(
                          (item) => item.value === selected
                        );
                        return (
                          <div className="flex items-center gap-x-2">
                            {selectedItem?.icon}
                            <span className="text-[13px] font-medium">
                              {selectedItem?.label}
                            </span>
                          </div>
                        );
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {classOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
                <StyledTableCell align="left">{row.sparkFees}</StyledTableCell>
                <StyledTableCell align="left">
                  <FormControlLabel
                    control={<IosSwitch sx={{ m: 1 }} checked={row.active} />}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        {rows.length > 10 && (
          <div className="flex justify-center mt-3 items-center">
            <img
              loading="lazy"
              className="cursor-pointer mr-2"
              src={page ? "/chevron_left.svg" : "/left.svg"}
              alt=""
              onClick={() => {
                if (page) setPage((page) => page - 1);
              }}
            />
            <div className="flex justify-between gap-x-3">
              {Array(Math.ceil(rows.length / 10))
                .fill()
                .map((el, i) => (
                  <div
                    onClick={(e) => {
                      setPage(+e.target.innerHTML - 1);
                    }}
                    key={i}
                    className={
                      page === i
                        ? "bg-[#112532] text-white px-2 py-1 rounded cursor-pointer"
                        : "px-2 py-1 cursor-pointer"
                    }
                  >
                    {i + 1}
                  </div>
                ))}
            </div>
            <img
              loading="lazy"
              className="cursor-pointer ml-2"
              src={
                Math.ceil(rows.length / 10) > page + 1
                  ? "/chevron_right.svg"
                  : "/no-right.svg"
              }
              alt=""
              onClick={() => {
                if (Math.ceil(rows.length / 10) > page + 1)
                  setPage((page) => page + 1);
              }}
            />
          </div>
        )}
      </TableContainer>
    </TableWrapper>
  );
};
