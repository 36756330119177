import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import InstallerTermsAndConditions from "../InstallerTermsAndConditions";
import InstallerPrivacyPolicy from "../InstallerPrivacyPolicy";
import { useState } from "react";

const solarPanelsOptions = [
  {
    id: 1,
    description:
      "SOLAR CLAM-P - SOLAR PANEL MOUNTING KIT 6 panels MILL MICRO J-BOX SKIRT\n",
  },
];
const invertersOptions = [
  {
    id: 4,
    description:
      "10.2KW 48V Solar Hybrid Inverter Off Grid 180A MPPT Sine Wave Solar Controller\n",
  },
];
const batteryOptions = [
  {
    id: 895,
    description: "Hulk 12V Lithium Battery Lifepo4 - 200Ah\n",
  },
];
const rackingOptions = [
  {
    id: 9,
    description:
      "Solar Rail Alu Mounting Rail Solar Panel PV Profile Solar System Accessories\n",
  },
];

const EquipmentListRegisterForm = ({
  errors,
  control,
  watch,
  reset,
  setIsTermsAgreed,
  setIsPrivacyAgreed,
  isPrivacyAgreed,
  isTermsAgreed,
}) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  return (
    <div className=" flex flex-col gap-y-6">
      <InstallerTermsAndConditions
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onAgree={() => setIsTermsAgreed(true)}
      />
      <InstallerPrivacyPolicy
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
        onAgree={() => setIsPrivacyAgreed(true)}
      />
      <h2 className="text-[#112532] text-lg">
        Select the equipment that you use for residential solar projects
      </h2>
      <Controller
        name="system_type"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">System Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value || ""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              label="System Type" // Ensure label is set in Select component
            >
              <MenuItem value={"Basic"}>Basic</MenuItem>
              <MenuItem value={"Standard"}>Standard</MenuItem>
              <MenuItem value={"Premium"}>Premium</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <div className="">
        <h1>Pricing Method</h1>
        <Controller
          name="pricing_method"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value || ""}
              onChange={(e) => {
                onChange(e.target.value);

                reset({
                  ...watch(),
                  pricing_method: e.target.value,
                  solar_panels: "",
                  inverter: "",
                  racking: "",
                  battery: "",
                  solar_panel_price: "",
                  inverter_price: "",
                  racking_price: "",
                  battery_price: "",
                  watt_price: "",
                });
              }}
            >
              <div className="flex">
                <FormControlLabel
                  value="watt"
                  control={<Radio />}
                  label="$/Watt"
                />
                <FormControlLabel
                  value="material"
                  control={<Radio />}
                  label=" Bill of Material"
                />
              </div>
            </RadioGroup>
          )}
        />
        {watch().pricing_method === "watt" ? (
          <div className="flex flex-col gap-y-8 pt-6">
            <Controller
              name="watt_price"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={errors.watt_price && true}
                  helperText={errors.watt_price && errors.watt_price.message}
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "+") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                  className="w-full"
                  label="Price/Watt"
                  id="outlined-start-adornment"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: "0.01",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          loading="lazy"
                          src="/bi_currency-dollar.svg"
                          alt="money icon"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="solar_panels"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={solarPanelsOptions}
                  getOptionLabel={(option) => option.description || ""}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={
                    value
                      ? {
                          id: value,
                          description: solarPanelsOptions.find(
                            (opt) => opt.id === value
                          )?.description,
                        }
                      : null
                  }
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      error={errors.solar_panels && true}
                      helperText={
                        errors.solar_panels && errors.solar_panels.message
                      }
                      {...params}
                      label="Solar Panel"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              loading="lazy"
                              src="/search.svg"
                              alt="search icon"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="racking"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={rackingOptions}
                  getOptionLabel={(option) => option.description || ""}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={
                    value
                      ? {
                          id: value,
                          description: rackingOptions.find(
                            (opt) => opt.id === value
                          )?.description,
                        }
                      : null
                  }
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      error={errors.racking && true}
                      helperText={errors.racking && errors.racking.message}
                      {...params}
                      label="Racking"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              loading="lazy"
                              src="/search.svg"
                              alt="money icon"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="inverter"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={invertersOptions}
                  getOptionLabel={(option) => option.description || ""}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={
                    value
                      ? {
                          id: value,
                          description: invertersOptions.find(
                            (opt) => opt.id === value
                          )?.description,
                        }
                      : null
                  }
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      error={errors.inverter && true}
                      helperText={errors.inverter && errors.inverter.message}
                      {...params}
                      label="Inverter"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              loading="lazy"
                              src="/search.svg"
                              alt="money icon"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="battery"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={batteryOptions}
                  getOptionLabel={(option) => option.description || ""}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={
                    value
                      ? {
                          id: value,
                          description: batteryOptions.find(
                            (opt) => opt.id === value
                          )?.description,
                        }
                      : null
                  }
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      error={errors.battery && true}
                      helperText={errors.battery && errors.battery.message}
                      {...params}
                      label="Battery"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              loading="lazy"
                              src="/search.svg"
                              alt="money icon"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="battery_price"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={errors.battery_price && true}
                  helperText={
                    errors.battery_price && errors.battery_price.message
                  }
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "+") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                  className="w-full"
                  label="Battery Price"
                  id="outlined-start-adornment"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: "0.01",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          loading="lazy"
                          src="/bi_currency-dollar.svg"
                          alt="money icon"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-y-6 md:gap-y-8 pt-6">
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 md:gap-x-3">
                <Controller
                  name="solar_panels"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={solarPanelsOptions}
                      getOptionLabel={(option) => option.description || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      value={
                        value
                          ? {
                              id: value,
                              description: solarPanelsOptions.find(
                                (opt) => opt.id === value
                              )?.description,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.id : null);
                      }}
                      blurOnSelect
                      renderInput={(params) => (
                        <TextField
                          error={errors.solar_panels && true}
                          helperText={
                            errors.solar_panels && errors.solar_panels.message
                          }
                          {...params}
                          label="Solar Panel"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/search.svg"
                                  alt="money icon"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="solar_panel_price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={errors.solar_panel_price && true}
                      helperText={
                        errors.solar_panel_price &&
                        errors.solar_panel_price.message
                      }
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      sx={{
                        "& label.Mui-focused": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#66a1c7",
                          },
                        },
                      }}
                      className="w-full"
                      label="Solar Panel Price"
                      id="outlined-start-adornment"
                      type="number"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              src="/bi_currency-dollar.svg"
                              alt="money icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 md:gap-x-3">
                <Controller
                  name="inverter"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={invertersOptions}
                      getOptionLabel={(option) => option.description || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      value={
                        value
                          ? {
                              id: value,
                              description: invertersOptions.find(
                                (opt) => opt.id === value
                              )?.description,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.id : null);
                      }}
                      blurOnSelect
                      renderInput={(params) => (
                        <TextField
                          error={errors.inverter && true}
                          helperText={
                            errors.inverter && errors.inverter.message
                          }
                          {...params}
                          label="Inverter"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/search.svg"
                                  alt="money icon"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="inverter_price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={errors.inverter_price && true}
                      helperText={
                        errors.inverter_price && errors.inverter_price.message
                      }
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      sx={{
                        "& label.Mui-focused": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#66a1c7",
                          },
                        },
                      }}
                      className="w-full"
                      label="Inverter Price"
                      id="outlined-start-adornment"
                      type="number"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              src="/bi_currency-dollar.svg"
                              alt="money icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 md:gap-x-3">
                <Controller
                  name="racking"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={rackingOptions}
                      getOptionLabel={(option) => option.description || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      value={
                        value
                          ? {
                              id: value,
                              description: rackingOptions.find(
                                (opt) => opt.id === value
                              )?.description,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.id : null);
                      }}
                      blurOnSelect
                      renderInput={(params) => (
                        <TextField
                          error={errors.racking && true}
                          helperText={errors.racking && errors.racking.message}
                          {...params}
                          label="Racking"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/search.svg"
                                  alt="money icon"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="racking_price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={errors.racking_price && true}
                      helperText={
                        errors.racking_price && errors.racking_price.message
                      }
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      sx={{
                        "& label.Mui-focused": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#66a1c7",
                          },
                        },
                      }}
                      className="w-full"
                      label="Racking Price"
                      id="outlined-start-adornment"
                      type="number"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              src="/bi_currency-dollar.svg"
                              alt="money icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 md:gap-x-3">
                <Controller
                  name="battery"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={batteryOptions}
                      getOptionLabel={(option) => option.description || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      value={
                        value
                          ? {
                              id: value,
                              description: batteryOptions.find(
                                (opt) => opt.id === value
                              )?.description,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.id : null);
                      }}
                      blurOnSelect
                      renderInput={(params) => (
                        <TextField
                          error={errors.battery && true}
                          helperText={errors.battery && errors.battery.message}
                          {...params}
                          label="Battery"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/search.svg"
                                  alt="money icon"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="battery_price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={errors.battery_price && true}
                      helperText={
                        errors.battery_price && errors.battery_price.message
                      }
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      sx={{
                        "& label.Mui-focused": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#66a1c7",
                          },
                        },
                      }}
                      className="w-full"
                      label="Battery Price"
                      id="outlined-start-adornment"
                      type="number"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              src="/bi_currency-dollar.svg"
                              alt="money icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
        <FormGroup>
          <div className="flex items-center mt-6">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onClick={(e) => {
                    e.preventDefault();
                    setIsTermsModalOpen(true);
                  }}
                  checked={isTermsAgreed}
                />
              }
              label="I Agree to"
            />
            &nbsp;
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsTermsModalOpen(true)}
            >
              Terms and Conditions
            </p>
          </div>
          <div className="flex items-center">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPrivacyModalOpen(true);
                  }}
                  checked={isPrivacyAgreed}
                />
              }
              label="I Agree to"
            />
            &nbsp;
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsPrivacyModalOpen(true)}
            >
              Privacy Policy
            </p>
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default EquipmentListRegisterForm;
