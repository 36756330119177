// src/redux/reducers/surveyReducer.js
const initialState = {
  indoorActiveStep: 0,
  outdoorActiveStep: 0,
  isSurveyCompleted: {
    indoor: false,
    outdoor: false,
  },
  currentSurveyType: null,
};

const siteSurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INDOOR_STEP":
      return { ...state, indoorActiveStep: action.payload };
    case "SET_OUTDOOR_STEP":
      return { ...state, outdoorActiveStep: action.payload };
    case "SET_SURVEY_COMPLETED":
      return {
        ...state,
        isSurveyCompleted: {
          ...state.isSurveyCompleted,
          [action.payload.surveyType]: action.payload.completed,
        },
      };
    case "SET_SURVEY_TYPE":
      return { ...state, currentSurveyType: action.payload };
    default:
      return state;
  }
};

export default siteSurveyReducer;
