import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { installerLogIn } from "services/installerServices";
import { ACCESS_TOKEN } from "utils/constant";
import { toast } from "react-toastify";
import { loginSchema } from "validation-schema/homeOwner";
import { updateGeneralInformation } from "../../../redux/actions";
import { useDispatch } from "react-redux";

const InstallerLoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const res = await installerLogIn(data.email, data.password);
      localStorage.setItem(ACCESS_TOKEN, res.token);
      if (res.token) {
        dispatch(updateGeneralInformation(res));
        navigate("/dashboard-installer");
        localStorage.setItem("USER", "installer");
      }
    } catch (err) {
      if (err.status === 401) toast.error("incorrect password or email");
      toast.error(
        err?.response?.data?.message ||
          "Something is going Wrong , Try again later"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  return (
    <div className="flex-1 px-6 py-14 md:py-5 md:px-[120px] bg-white w-full absolute top-[300px] md:static md:rounded-none rounded-tl-[20px] rounded-tr-[20px]">
      <h1 className="mb-8 md:mb-12 text-[28px] text-[#244255] font-semibold">
        Sign In
      </h1>
      <div className="flex flex-col gap-y-4 md:gap-y-8">
        <div className="">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                error={errors.email && true}
                {...field}
                autoFocus
                helperText={errors.email && errors.email.message}
                className="w-full"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{
                  "& label.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#66a1c7",
                    },
                  },
                }}
              />
            )}
          />
        </div>
        <div className="">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                error={errors.password && true}
                {...field}
                helperText={errors.password && errors.password.message}
                sx={{
                  "& label.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#66a1c7",
                    },
                  },
                }}
                className="w-full"
                label="Password"
                id="outlined-start-adornment"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={handleClickShowPassword}
                        />
                      ) : (
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={handleClickShowPassword}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Link
          to={"/auth/installer-forget-password"}
          className="text-end text-[#287192]"
        >
          Forgot Password?
        </Link>
        <div className="">
          <FormGroup>
            <Controller
              name="rememberMe"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{
                        color: "#CED1D5",
                        "&.Mui-checked": {
                          color: "#2D8DAD",
                        },
                      }}
                    />
                  }
                  label="Remember me"
                />
              )}
            />
          </FormGroup>
        </div>
        <button
          className={`px-[18px] py-3 font-bold rounded-[5px] 
    ${
      !isValid || isSubmitting
        ? "bg-gray-400 text-gray-200 "
        : "bg-[#112532] text-white hover:bg-[#D97706]"
    }`}
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Sign in
        </button>
        <div className="">
          <div className="flex items-center justify-center">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-gray-500">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
        </div>
        <button className="flex w-full gap-x-3 items-center justify-center border p-2">
          <img loading="lazy" src="/google.svg" />
          Sign in with Google
        </button>
        <p className="text-center">
          New Installer?
          <Link className="text-[#287192] ml-1" to={"/auth/installer-register"}>
            Join Spark
          </Link>
        </p>
      </div>
    </div>
  );
};

export default InstallerLoginForm;
