import ApiService from "./api";

const baseURL = "/admin";

export const adminLogin = async (email: string, password: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-managment/login-admin`,
    method: "POST",
    data: {
      email,
      password,
    },
  });
  return response;
};

export const GETadminPersonalDetails = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/admin-info`,
    method: "GET",
  });
  return response;
};

export const POSTadminChangeAuthenticatedPassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/change-password`,
    method: "POST",
    data: { currentPassword, newPassword },
  });
  return response;
};

export const PATCHadminPersonalDetailsUpdate = async (formData: {
  first_name: string;
  last_name: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/update-admin-field`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const PATCHadminProfileImageUpdate = async (formData: {
  first_name: string;
  last_name: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/update-profile-img`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const DELETEadminProfilePic = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/delete-profile-img`,
    method: "DELETE",
  });
  return response;
};
