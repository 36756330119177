import LoginForm from "components/home-owner/auth/LoginForm";
import AuthLayout from "layout/AuthLayout";
import Header from "components/shared/Header";

const Login = () => {
  return (
    <>
      <Header />
      <AuthLayout>
        <LoginForm />
      </AuthLayout>
    </>
  );
};

export default Login;
