import AdminLoginForm from "components/Admin/auth/AdminLoginForm";
import Header from "components/shared/Header";
import AuthLayout from "layout/AuthLayout";

const AdminLogin = () => {
  return (
    <>
      <Header />
      <AuthLayout>
        <AdminLoginForm />
      </AuthLayout>
    </>
  );
};

export default AdminLogin;
