import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  GETavailableStates,
  GETbusinessDetails,
  PUTbusinessDetailsUpdate,
} from "services/installerServices";
import { businessDetailsSchema } from "validation-schema/installer";
import ReactDropZone from "./ReactDropZone";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateGeneralInformation } from "../../redux/actions";

const InstallerBusinessDetails = () => {
  const { setDialogData, setIsBackDialogOpen } = useOutletContext();
  const [imagesArray, setImagesArray] = useState([]);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const userRole = localStorage.getItem("USER");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const generalData = useSelector((state) => state.generalInformation);

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(businessDetailsSchema),
    defaultValues: {
      business_name: "",
      mobile: "",
      country: "",
      state: "",
      city: "",
      post_code: "",
      building_number: "",
      street: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setIsBackDialogOpen(isValid);
  }, [isValid]);

  const fetchData = async () => {
    try {
      const res = await GETavailableStates();
      setAvailableStates(res.states);
    } catch (error) {}
    try {
      const res = await GETbusinessDetails();
      setValue("business_name", res.business_name.trim());
      setValue("mobile", res.mobile);
      setValue("country", res.country);
      setValue("state", res.state);
      setValue("city", res.city);
      setValue("post_code", res.post_code);
      setValue("building_number", res.building_number || "");
      setValue("street", res.street || "");

      if (res.logo) {
        setImagesArray([{ preview: res.logo }]);
      }

      setBusinessDetails(res);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (imagesArray.length > 0) formData.append("img", imagesArray[0]?.file);
    formData.append("business_name", data.business_name.trim());
    formData.append("mobile", data.mobile);
    formData.append("country", data.country);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("post_code", data.post_code);
    formData.append("building_number", data.building_number);
    formData.append("street", data.street);

    try {
      const res = await PUTbusinessDetailsUpdate(formData);
      dispatch(updateGeneralInformation(res.user));
      toast.success(res.message);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="h-full">
      <div className="flex flex-col md:flex-row bg-white p-4 md:p-8 rounded-[10px] md:border border-[#D9D3CF]">
        <div className="flex flex-col gap-y-6 w-full md:w-1/2">
          <h1 className="text-[19px] text-[#112532] font-semibold">
            Business Details
          </h1>

          {/* Logo Upload Section */}
          <div className="flex max-h-32 gap-3">
            <img
              loading="lazy"
              className="w-32 h-32 object-cover rounded-lg"
              src={imagesArray[0]?.preview || generalData?.logo}
              alt="Business Logo"
            />
            <div className="flex-1 bg-[#F9F9F9] rounded-lg">
              <ReactDropZone
                setImagesArray={setImagesArray}
                trigger={trigger}
                variant="profile"
              />
            </div>
          </div>

          {/* Business Name */}
          <Controller
            name="business_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.business_name}
                helperText={
                  errors.business_name && errors.business_name.message
                }
                id="outlined-basic"
                label="Business Name"
                variant="outlined"
              />
            )}
          />

          {/* Phone Number */}
          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.mobile}
                helperText={errors.mobile && errors.mobile.message}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "+" ||
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "." ||
                    e.key === "ArrowUp" ||
                    e.key === "ArrowDown"
                  ) {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                type="number"
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                      +1
                    </div>
                  ),
                }}
              />
            )}
          />

          {/* Country Field */}
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                disabled
                {...field}
                fullWidth
                error={!!errors.country}
                helperText={errors.country && errors.country.message}
                id="outlined-basic"
                label="Country"
                variant="outlined"
                value={"United States of America (USA)"}
                sx={{ backgroundColor: "#E0E4EA", borderRadius: "4px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img loading="lazy" src="/search.svg" alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/* State Selector */}
          <Controller
            name="state"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                fullWidth
                value={availableStates.find((state) => state === value) || null}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                id="controllable-states-demo"
                options={availableStates}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                    ],
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    error={!!errors.state}
                    helperText={errors.state && errors.state.message}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img loading="lazy" src="/search.svg" alt="" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          />

          {/* City Field */}
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!errors.city}
                helperText={errors.city && errors.city.message}
                id="outlined-basic"
                label="City"
                variant="outlined"
              />
            )}
          />

          {/* Street Address */}
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="outlined-basic"
                label="Street"
                variant="outlined"
              />
            )}
          />

          {/* Building Number and Post Code */}
          <div className="flex flex-col md:flex-row gap-3">
            <div className="w-full md:w-1/2">
              <Controller
                name="building_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "+") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    id="outlined-basic"
                    label="Building No."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2">
              <Controller
                name="post_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors.post_code}
                    helperText={errors.post_code && errors.post_code.message}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "Delete" &&
                        e.key !== "Tab"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 9,
                    }}
                    type="text"
                    id="outlined-basic"
                    label="Post Code"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/* Spacer div for desktop layout */}
        <div className="hidden md:block w-1/2"></div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col-reverse md:flex-row justify-end gap-3 md:gap-x-3 mt-4">
        <button
          className="w-full md:w-auto py-3 px-10 border border-[#112532] rounded"
          onClick={() => {
            if (!isValid)
              navigate(
                userRole === "installer"
                  ? "/dashboard-installer"
                  : userRole === "homeOwner"
                    ? "/dashboard"
                    : null
              );
            setDialogData({
              isDialogOpen: true,
              title: "Discard Changes?",
              subTitle:
                "Changes will not be saved, are you sure you want to continue?",
              onConfirm: () =>
                navigate(
                  userRole === "installer"
                    ? "/dashboard-installer"
                    : userRole === "homeOwner"
                      ? "/dashboard"
                      : null
                ),
            });
          }}
        >
          Discard
        </button>
        <button
          className={`w-full md:w-auto py-3 px-10 text-white bg-[#112532] rounded ${
            !isValid ? "opacity-50" : ""
          }`}
          onClick={(e) => {
            handleSubmit(onSubmit)(e);
            setIsBackDialogOpen(false);
          }}
          disabled={!isValid}
        >
          Save Changes
        </button>
      </div>
      <div className="h-[50px]"></div>
    </div>
  );
};

export default InstallerBusinessDetails;
