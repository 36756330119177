import { InputAdornment, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import ReactDropZone from "./ReactDropZone";
import useResponsive from "hooks/useResponsive";
import { setChatList } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const MAX_SIZE_MB = 100; // 100 MB limit
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const Chat = ({ chatInfo, setChatInfo }) => {
  const [filesArray, setFilesArray] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [message, setMessage] = useState(""); // To hold text message
  const [errorMessage, setErrorMessage] = useState(""); // To display error if size exceeds limit
  const [messages, setMessages] = useState([]); // Chat message history
  const [socket, setSocket] = useState(null); // Socket state
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const { isSm } = useResponsive();

  const generalData = useSelector((state) => state.generalInformation);

  const dispatch = useDispatch();

  const userRole = localStorage.getItem("USER");

  const typingTimeout = useRef(null);

  // Create a ref to access the chat div
  const chatRef = useRef(null);
  const isValid = message.length > 0 || filesArray.length > 0;

  useEffect(() => {
    const container = document.getElementById("scrollable-container");

    const updateArrowsVisibility = () => {
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollWidth > container.clientWidth &&
            container.scrollLeft + container.clientWidth < container.scrollWidth
        );
      }
    };

    // Initial check for arrows visibility
    updateArrowsVisibility();

    // Listen for scroll events
    if (container) {
      container.addEventListener("scroll", updateArrowsVisibility);
    }

    // Cleanup event listener
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrowsVisibility);
      }
    };
  }, [filesArray]);

  console.log(chatInfo);

  useEffect(() => {
    console.log(process.env.REACT_APP_SOCKET_URL);
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("USER");
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      query: { token, role },
      reconnection: true,
    });
    setSocket(newSocket);

    newSocket.on("typing", ({ sender, typing }) => {
      setIsTyping(typing);
    });

    newSocket.emit("openChat", chatInfo.chatId, chatInfo.receiverId, {
      limit: 50,
      offset: 0,
    });
    console.log("chat opened");

    newSocket.on("existingMessages", (existingMessages) => {
      setMessages(existingMessages);
    });

    newSocket.on("receiveMessage", (message) => {
      if (message.chat_id === chatInfo.chatId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
      newSocket.emit("updateChatList", generalData.id, userRole);
    });

    newSocket.on("chatListUpdate", (chatList) => {
      dispatch(setChatList(chatList));
      console.log("Received chat list update: chat", chatList);
    });

    return () => {
      newSocket.disconnect();
      console.log("socket disconnected");
    };
  }, [chatInfo]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  // Scroll to the bottom every time messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  // Function to remove a file by index
  const handleRemoveFile = (index) => {
    setFilesArray((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (e) => {
    const message = e.target.value;

    socket.emit("typing", chatInfo.chatId, chatInfo.receiverId);

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    typingTimeout.current = setTimeout(() => {
      socket.emit("stopTyping", chatInfo.chatId, chatInfo.receiverId);
    }, 300);

    setMessage(message);
  };

  // Function to calculate the total message size (text + files)
  const calculateMessageSize = () => {
    const textSize = new Blob([message]).size; // Calculate text size in bytes

    // Sum all file sizes
    const filesSize = filesArray.reduce((total, file) => total + file.size, 0);

    // Total size = text size + files size
    return textSize + filesSize;
  };

  // Function to send the message with files
  const handleSend = () => {
    const totalSize = calculateMessageSize();

    // Check if the total size exceeds the 100 MB limit
    if (totalSize > MAX_SIZE_BYTES) {
      setErrorMessage(
        `Message exceeds the 100MB limit. Current size: ${(totalSize / (1024 * 1024)).toFixed(2)} MB`
      );
      return;
    }

    // Clear error message and proceed with sending
    setErrorMessage("");

    // Send message to the server
    if (socket) {
      const newMessage = {
        sender: localStorage.getItem("USER"),
        message: message,
        createdAt: dayjs().toISOString(),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);

      socket.emit("sendMessage", {
        message,
        chatId: chatInfo.chatId,
        receiverId: chatInfo.receiverId,
      });

      socket.emit("updateChatList", generalData.id, userRole);
      // Clear the message and files after sending
      setMessage("");
      setFilesArray([]);

      // After sending the message, fetch the latest existing messages
      // socket.emit("openChat", chatInfo.chatId, chatInfo.receiverId);
    }
  };

  const renderFileDesign = (file) => {
    const renderDocumentDesign = (iconSrc, fileName, fileSizeKB) => (
      <div className="w-72 max-h-16 flex items-center gap-x-3 bg-white px-4 py-3 rounded-lg">
        <img loading="lazy" src={iconSrc} alt="" />
        <div>
          <p className="text-[#112532] text-[14px]">{fileName}</p>
          <p className="text-[#64748B] text-[12px]">{fileSizeKB} KB</p>
        </div>
      </div>
    );

    const fileSizeKB = (file.size / 1024).toFixed(0);

    if (file.type.startsWith("image/")) {
      return (
        <div className="w-16 h-16">
          <img
            loading="lazy"
            className="w-full h-full object-cover rounded-lg"
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        </div>
      );
    } else if (file.type.startsWith("video/")) {
      return (
        <div className="w-16 h-16 relative">
          <video
            className="w-full h-full object-cover rounded-lg"
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
          <img
            loading="lazy"
            className=" absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"
            src="/videoPlay.svg"
            alt=""
          />
        </div>
      );
    } else if (file.type === "application/pdf") {
      return renderDocumentDesign("/PDF.svg", file.name, fileSizeKB);
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword"
    ) {
      return renderDocumentDesign("/Doc.svg", file.name, fileSizeKB);
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    ) {
      return renderDocumentDesign("/XLS.svg", file.name, fileSizeKB);
    }
  };

  return (
    <div className="flex flex-col gap-y-8 h-dvh md:h-full md:border border-[#D9D3CF] p-5 md:rounded-[10px] md:shadow">
      <div
        className={`flex items-center gap-x-2 ${isSm ? "fixed top-0 bg-white w-full py-4 h-[70px]" : "relative pb-4"}`}
      >
        {isSm && (
          <img
            className=" cursor-pointer"
            src="/arrow_back.svg"
            alt=""
            onClick={() => setChatInfo(null)}
          />
        )}
        <img
          loading="lazy"
          className="w-10 h-10 rounded-full"
          src={chatInfo.userData.image}
          alt=""
        />
        <h1 className="text-[23px] font-bold">{chatInfo.userName}</h1>
        <hr className="w-[calc(100%+40px)] absolute bottom-[0px] left-[-20px] border border-[#D9D3CF]" />
      </div>

      {/* Chat Container */}
      <div
        className="flex flex-col gap-y-10 h-full overflow-y-auto w-full p-5 scrollbar-hide"
        id="chat"
        ref={chatRef}
      >
        {messages.map((msg, index) => {
          // Check if this is the last message in the conversation
          const isLastMessage = index === messages.length - 1;

          return (
            <div
              key={index}
              className={`flex flex-col gap-y-2 ${
                msg.sender === localStorage.getItem("USER")
                  ? "items-end"
                  : "items-start"
              } w-full`}
            >
              <p className="text-[10px] text-[#94847B]">
                {dayjs(msg?.createdAt).format("h:mm A")}
              </p>
              <div
                className={`flex items-center gap-x-4 max-w-[95%] md:max-w-[50%]`}
              >
                {msg.sender === localStorage.getItem("USER") ? null : (
                  <img
                    loading="lazy"
                    className="w-8 h-8 rounded-full"
                    src={chatInfo.userData.image}
                    alt=""
                  />
                )}
                <div
                  className={`p-2 rounded-md px-4 py-[10px] text-[#112532] break-words ${
                    msg.sender === localStorage.getItem("USER")
                      ? "bg-[#FCF4E2]"
                      : "bg-[#F7F6F5]"
                  }`}
                  style={{ wordBreak: "break-word" }}
                >
                  {msg.message}
                </div>
              </div>
              {isLastMessage &&
              msg.sender === localStorage.getItem("USER") &&
              msg.seenby_homeOwner ? (
                <p className="text-[10px] text-[#94847B]">seen</p>
              ) : null}
            </div>
          );
        })}
      </div>
      {isTyping && (
        <div className="flex items-center gap-x-2 ml-12 my-2">
          <div className="flex space-x-1">
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
          </div>
          {/* <p className="text-xs text-gray-500">Typing...</p> */}
        </div>
      )}
      {/* DropZone for Uploading Files */}
      <div className="hidden">
        <ReactDropZone
          isInputOpen={isInputOpen}
          setFilesArray={setFilesArray}
          setIsInputOpen={setIsInputOpen}
          variant="chat"
        />
      </div>

      <div className="mt-auto w-full p-3 bg-[#F7F6F5] rounded-lg">
        {filesArray.length > 0 && (
          <div className="relative w-full mt-auto">
            {canScrollLeft && (
              <button
                className="absolute left-0 top-1/2 translate-y-[-50%] z-10"
                onClick={() => {
                  const container = document.getElementById(
                    "scrollable-container"
                  );
                  container.scrollBy({ left: -150, behavior: "smooth" });
                }}
              >
                <img
                  loading="lazy"
                  className="rotate-180"
                  src="/rightCarousel.svg"
                  alt=""
                />
              </button>
            )}
            {canScrollRight && (
              <button
                className="absolute right-0 top-1/2 translate-y-[-50%] z-10"
                onClick={() => {
                  const container = document.getElementById(
                    "scrollable-container"
                  );
                  container.scrollBy({ left: 150, behavior: "smooth" });
                }}
              >
                <img loading="lazy" src="/rightCarousel.svg" alt="" />
              </button>
            )}

            <div
              id="scrollable-container"
              className="flex gap-4 overflow-x-auto py-2 scrollbar-hide"
            >
              {filesArray.map((file, index) => (
                <div
                  key={index}
                  className="relative flex-shrink-0 group max-h-16 rounded-lg mb-2"
                >
                  {renderFileDesign(file)}

                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="opacity-0 group-hover:opacity-100"
                  >
                    <img
                      loading="lazy"
                      src="/xIcon.svg"
                      className="w-5 h-5 absolute top-[-5px] right-[-5px]"
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Error Message if size exceeds limit */}
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}

        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Write something..."
          value={message}
          onChange={handleChange} // Capture text input
          onKeyDown={(e) => {
            if (e.key === "Enter" && isValid) {
              handleSend();
            }
          }} // Handle Enter key press
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <img
                  loading="lazy"
                  className="cursor-pointer mr-5"
                  src={"/attach_file.svg"}
                  alt="attach file icon"
                  onClick={() => setIsInputOpen((state) => !state)} // Open file dropzone
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents={!isValid}>
                <img
                  loading="lazy"
                  className="cursor-pointer"
                  src={isValid ? "/ActiveSend.svg" : "/send.svg"}
                  alt="send icon"
                  onClick={handleSend} // Send message and files
                />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "none", // Remove the outer border
              "& fieldset": {
                border: "none", // Remove the border from the fieldset (default in outlined variant)
              },
              "&:hover fieldset": {
                border: "none", // Remove border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // Remove border on focus
              },
            },
            "& .MuiOutlinedInput-input": {
              outline: "none", // Remove focus outline
              boxShadow: "none", // Remove any box-shadow effect
            },
          }}
        />
      </div>
    </div>
  );
};

export default Chat;
